@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: translateY(0);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: translateY(0);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveDownOut {
  0% {
    transform: translateY(0);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: translateX(0);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: translateX(0);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveLeftOut {
  0% {
    transform: translateX(0);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: translateX(0);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: translateX(0);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveRightOut {
  0% {
    transform: translateX(0);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: translateY(0);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: translateY(0);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveUpOut {
  0% {
    transform: translateY(0);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes loadingCircle {
  to {
    transform: rotate(360deg);
  }
}
@keyframes loadingCircle {
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes waveEffect {
  to {
    box-shadow: 0 0 0 #3e79f7;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes waveEffect {
  to {
    box-shadow: 0 0 0 #3e79f7;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@-webkit-keyframes fadeEffect {
  to {
    opacity: 0;
  }
}
@keyframes fadeEffect {
  to {
    opacity: 0;
  }
}
@-webkit-keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: scaleY(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: scaleY(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  to {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  to {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: scaleX(1);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: scaleX(1);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: scaleX(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: scaleX(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0;
    opacity: 0;
  }
  to {
    transform: scaleX(1);
    transform-origin: 100% 0;
    opacity: 1;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0;
    opacity: 0;
  }
  to {
    transform: scaleX(1);
    transform-origin: 100% 0;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0;
    opacity: 1;
  }
  to {
    transform: scaleX(0.8);
    transform-origin: 100% 0;
    opacity: 0;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0;
    opacity: 1;
  }
  to {
    transform: scaleX(0.8);
    transform-origin: 100% 0;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(0.2);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(0.8);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0;
    opacity: 0;
  }
  to {
    transform: scale(1);
    transform-origin: 50% 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0;
    opacity: 0;
  }
  to {
    transform: scale(1);
    transform-origin: 50% 0;
  }
}
@-webkit-keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0;
  }
  to {
    transform: scale(0.8);
    transform-origin: 50% 0;
    opacity: 0;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0;
  }
  to {
    transform: scale(0.8);
    transform-origin: 50% 0;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0 50%;
    opacity: 0;
  }
  to {
    transform: scale(1);
    transform-origin: 0 50%;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0 50%;
    opacity: 0;
  }
  to {
    transform: scale(1);
    transform-origin: 0 50%;
  }
}
@-webkit-keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0 50%;
  }
  to {
    transform: scale(0.8);
    transform-origin: 0 50%;
    opacity: 0;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0 50%;
  }
  to {
    transform: scale(0.8);
    transform-origin: 0 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  to {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  to {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@-webkit-keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  to {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  to {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  to {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  to {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@-webkit-keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  to {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  to {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
@-webkit-keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  to {
    transform: scale(2.4);
    opacity: 0;
  }
}
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  to {
    transform: scale(2.4);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  to {
    transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  to {
    transform: scale(1) translate(50%, -50%);
  }
}
@-webkit-keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  to {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  to {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@-webkit-keyframes antNoWrapperZoomBadgeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
  }
}
@keyframes antNoWrapperZoomBadgeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes antNoWrapperZoomBadgeOut {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes antNoWrapperZoomBadgeOut {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomBadgeInRtl {
  0% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
  to {
    transform: scale(1) translate(-50%, -50%);
  }
}
@keyframes antZoomBadgeInRtl {
  0% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
  to {
    transform: scale(1) translate(-50%, -50%);
  }
}
@-webkit-keyframes antZoomBadgeOutRtl {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  to {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
}
@keyframes antZoomBadgeOutRtl {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  to {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
}
@-webkit-keyframes card-loading {
  0%,
  to {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
@keyframes card-loading {
  0%,
  to {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
@-webkit-keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  to {
    transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  to {
    transform: scale(1.6);
    opacity: 0;
  }
}
@-webkit-keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes antShowHelpIn {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes antShowHelpIn {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes antShowHelpOut {
  to {
    transform: translateY(-5px);
    opacity: 0;
  }
}
@keyframes antShowHelpOut {
  to {
    transform: translateY(-5px);
    opacity: 0;
  }
}
@-webkit-keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  to {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  to {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    opacity: 1;
  }
  to {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    opacity: 1;
  }
  to {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
@-webkit-keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  to {
    width: 100%;
    opacity: 0;
  }
}
@keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  to {
    width: 100%;
    opacity: 0;
  }
}
@-webkit-keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  to {
    transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  to {
    transform: scale(1.6);
    opacity: 0;
  }
}
@-webkit-keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}
@-webkit-keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0 50%;
  }
  to {
    background-position: 100% 50%;
  }
}
@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0 50%;
  }
  to {
    background-position: 100% 50%;
  }
}
@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@-webkit-keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
@-webkit-keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}
@keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}
@-webkit-keyframes uploadAnimateInlineIn {
  0% {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineIn {
  0% {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
[class*="ant-"] input::-ms-clear,
[class*="ant-"] input::-ms-reveal,
[class*="ant-"]::-ms-clear,
[class^="ant-"] input::-ms-clear,
[class^="ant-"] input::-ms-reveal,
[class^="ant-"]::-ms-clear,
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
::after,
::before,
[class*="ant-"],
[class*="ant-"] *,
[class*="ant-"] ::after,
[class*="ant-"] ::before,
[class^="ant-"],
[class^="ant-"] *,
[class^="ant-"] ::after,
[class^="ant-"] ::before {
  box-sizing: border-box;
}
body,
html {
  width: 100%;
  height: 100%;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  --antd-wave-shadow-color: #3e79f7;
  --scroll-bar: 0;
}
@-ms-viewport {
  width: device-width;
}
body {
  margin: 0;
  color: #455560;
  font-size: 14px;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #fafafb;
  font-feature-settings: "tnum";
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 500;
}
dl,
ol,
p,
ul {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[data-original-title],
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type="number"],
input[type="password"],
input[type="text"],
textarea {
  -webkit-appearance: none;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote,
figure {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #3e79f7;
  text-decoration: none;
  background-color: transparent;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #699dff;
}
a:active {
  color: #2a59d1;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: #d0d4d7;
  cursor: not-allowed;
  pointer-events: none;
}
kbd,
pre,
samp {
  font-size: 1em;
}
code,
kbd,
pre,
samp {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
[role="button"],
a,
area,
button,
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: #72849a;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
.anticon svg,
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #3e79f7;
}
.clearfix::before {
  display: table;
  content: "";
}
.clearfix::after {
  display: table;
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball,
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin,
.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}
.fade-appear,
.fade-enter,
.fade-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.fade-appear.fade-appear-active,
.fade-enter.fade-enter-active,
.fade-leave.fade-leave-active {
  -webkit-animation-name: antFadeIn;
  animation-name: antFadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.fade-leave.fade-leave-active {
  -webkit-animation-name: antFadeOut;
  animation-name: antFadeOut;
  pointer-events: none;
}
.fade-appear,
.fade-enter {
  opacity: 0;
}
.fade-appear,
.fade-enter,
.fade-leave {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.move-up-appear,
.move-up-enter,
.move-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.move-up-appear.move-up-appear-active,
.move-up-enter.move-up-enter-active,
.move-up-leave.move-up-leave-active {
  -webkit-animation-name: antMoveUpIn;
  animation-name: antMoveUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.move-up-leave.move-up-leave-active {
  -webkit-animation-name: antMoveUpOut;
  animation-name: antMoveUpOut;
  pointer-events: none;
}
.move-up-appear,
.move-up-enter {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-down-appear,
.move-down-enter,
.move-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.move-down-appear.move-down-appear-active,
.move-down-enter.move-down-enter-active,
.move-down-leave.move-down-leave-active {
  -webkit-animation-name: antMoveDownIn;
  animation-name: antMoveDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.move-down-leave.move-down-leave-active {
  -webkit-animation-name: antMoveDownOut;
  animation-name: antMoveDownOut;
  pointer-events: none;
}
.move-down-appear,
.move-down-enter {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-left-appear,
.move-left-enter,
.move-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.move-left-appear.move-left-appear-active,
.move-left-enter.move-left-enter-active,
.move-left-leave.move-left-leave-active {
  -webkit-animation-name: antMoveLeftIn;
  animation-name: antMoveLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.move-left-leave.move-left-leave-active {
  -webkit-animation-name: antMoveLeftOut;
  animation-name: antMoveLeftOut;
  pointer-events: none;
}
.move-left-appear,
.move-left-enter {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-right-appear,
.move-right-enter,
.move-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.move-right-appear.move-right-appear-active,
.move-right-enter.move-right-enter-active,
.move-right-leave.move-right-leave-active {
  -webkit-animation-name: antMoveRightIn;
  animation-name: antMoveRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.move-right-leave.move-right-leave-active {
  -webkit-animation-name: antMoveRightOut;
  animation-name: antMoveRightOut;
  pointer-events: none;
}
.move-right-appear,
.move-right-enter {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
[ant-click-animating-without-extra-node="true"],
[ant-click-animating="true"] {
  position: relative;
}
.ant-click-animating-node,
[ant-click-animating-without-extra-node="true"]::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #3e79f7;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  content: "";
  pointer-events: none;
}
.slide-up-appear,
.slide-up-enter,
.slide-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.slide-up-appear.slide-up-appear-active,
.slide-up-enter.slide-up-enter-active,
.slide-up-leave.slide-up-leave-active {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.slide-up-leave.slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
  pointer-events: none;
}
.slide-up-appear,
.slide-up-enter {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-down-appear,
.slide-down-enter,
.slide-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.slide-down-appear.slide-down-appear-active,
.slide-down-enter.slide-down-enter-active,
.slide-down-leave.slide-down-leave-active {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.slide-down-leave.slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
  pointer-events: none;
}
.slide-down-appear,
.slide-down-enter {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-left-appear,
.slide-left-enter,
.slide-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.slide-left-appear.slide-left-appear-active,
.slide-left-enter.slide-left-enter-active,
.slide-left-leave.slide-left-leave-active {
  -webkit-animation-name: antSlideLeftIn;
  animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.slide-left-leave.slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
  animation-name: antSlideLeftOut;
  pointer-events: none;
}
.slide-left-appear,
.slide-left-enter {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-right-appear,
.slide-right-enter,
.slide-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.slide-right-appear.slide-right-appear-active,
.slide-right-enter.slide-right-enter-active,
.slide-right-leave.slide-right-leave-active {
  -webkit-animation-name: antSlideRightIn;
  animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.slide-right-leave.slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
  animation-name: antSlideRightOut;
  pointer-events: none;
}
.slide-right-appear,
.slide-right-enter {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.zoom-appear,
.zoom-enter,
.zoom-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.zoom-appear.zoom-appear-active,
.zoom-enter.zoom-enter-active,
.zoom-leave.zoom-leave-active {
  -webkit-animation-name: antZoomIn;
  animation-name: antZoomIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.zoom-leave.zoom-leave-active {
  -webkit-animation-name: antZoomOut;
  animation-name: antZoomOut;
  pointer-events: none;
}
.zoom-appear,
.zoom-enter {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-appear-prepare,
.zoom-enter-prepare {
  transform: none;
}
.zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-appear,
.zoom-big-enter,
.zoom-big-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.zoom-big-appear.zoom-big-appear-active,
.zoom-big-enter.zoom-big-enter-active,
.zoom-big-leave.zoom-big-leave-active {
  -webkit-animation-name: antZoomBigIn;
  animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.zoom-big-leave.zoom-big-leave-active {
  -webkit-animation-name: antZoomBigOut;
  animation-name: antZoomBigOut;
  pointer-events: none;
}
.zoom-big-appear,
.zoom-big-enter {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-appear-prepare,
.zoom-big-enter-prepare {
  transform: none;
}
.zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-fast-appear,
.zoom-big-fast-enter,
.zoom-big-fast-leave {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.zoom-big-fast-appear.zoom-big-fast-appear-active,
.zoom-big-fast-enter.zoom-big-fast-enter-active,
.zoom-big-fast-leave.zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigIn;
  animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.zoom-big-fast-leave.zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
  animation-name: antZoomBigOut;
  pointer-events: none;
}
.zoom-big-fast-appear,
.zoom-big-fast-enter {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-fast-appear-prepare,
.zoom-big-fast-enter-prepare {
  transform: none;
}
.zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-up-appear,
.zoom-up-enter,
.zoom-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.zoom-up-appear.zoom-up-appear-active,
.zoom-up-enter.zoom-up-enter-active,
.zoom-up-leave.zoom-up-leave-active {
  -webkit-animation-name: antZoomUpIn;
  animation-name: antZoomUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.zoom-up-leave.zoom-up-leave-active {
  -webkit-animation-name: antZoomUpOut;
  animation-name: antZoomUpOut;
  pointer-events: none;
}
.zoom-up-appear,
.zoom-up-enter {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-up-appear-prepare,
.zoom-up-enter-prepare {
  transform: none;
}
.zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-down-appear,
.zoom-down-enter,
.zoom-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.zoom-down-appear.zoom-down-appear-active,
.zoom-down-enter.zoom-down-enter-active,
.zoom-down-leave.zoom-down-leave-active {
  -webkit-animation-name: antZoomDownIn;
  animation-name: antZoomDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.zoom-down-leave.zoom-down-leave-active {
  -webkit-animation-name: antZoomDownOut;
  animation-name: antZoomDownOut;
  pointer-events: none;
}
.zoom-down-appear,
.zoom-down-enter {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-down-appear-prepare,
.zoom-down-enter-prepare {
  transform: none;
}
.zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-left-appear,
.zoom-left-enter,
.zoom-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.zoom-left-appear.zoom-left-appear-active,
.zoom-left-enter.zoom-left-enter-active,
.zoom-left-leave.zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftIn;
  animation-name: antZoomLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.zoom-left-leave.zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftOut;
  animation-name: antZoomLeftOut;
  pointer-events: none;
}
.zoom-left-appear,
.zoom-left-enter {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-left-appear-prepare,
.zoom-left-enter-prepare {
  transform: none;
}
.zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-right-appear,
.zoom-right-enter,
.zoom-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.zoom-right-appear.zoom-right-appear-active,
.zoom-right-enter.zoom-right-enter-active,
.zoom-right-leave.zoom-right-leave-active {
  -webkit-animation-name: antZoomRightIn;
  animation-name: antZoomRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.zoom-right-leave.zoom-right-leave-active {
  -webkit-animation-name: antZoomRightOut;
  animation-name: antZoomRightOut;
  pointer-events: none;
}
.zoom-right-appear,
.zoom-right-enter {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-right-appear-prepare,
.zoom-right-enter-prepare {
  transform: none;
}
.zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse,
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
}
.ant-affix {
  position: fixed;
}
.ant-alert {
  box-sizing: border-box;
  margin: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  word-wrap: break-word;
  border-radius: 0.625rem;
}
.ant-alert-content {
  flex: 1;
  min-width: 0;
}
.ant-alert-icon {
  margin-right: 8px;
}
.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.ant-alert-success {
  background-color: #e6fff1;
  border: 1px solid #7cf7bc;
}
.ant-alert-success .ant-alert-icon {
  color: #04d182;
}
.ant-alert-info {
  background-color: #f0f7ff;
  border: 1px solid #bad7ff;
}
.ant-alert-info .ant-alert-icon {
  color: #3e79f7;
}
.ant-alert-warning {
  background-color: #fffdf0;
  border: 1px solid #fff2bd;
}
.ant-alert-warning .ant-alert-icon {
  color: #ffc542;
}
.ant-alert-error {
  background-color: #fff1f0;
  border: 1px solid #ffe7e6;
}
.ant-alert-error .ant-alert-icon {
  color: #ff6b72;
}
.ant-alert-error .ant-alert-description > pre {
  margin: 0;
  padding: 0;
}
.ant-alert-action {
  margin-left: 8px;
}
.ant-alert-close-icon {
  margin-left: 8px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
}
.ant-alert-close-icon .anticon-close,
.ant-alert-close-text {
  color: #72849a;
  transition: color 0.3s;
}
.ant-alert-close-icon .anticon-close:hover,
.ant-alert-close-text:hover {
  color: #455560;
}
.ant-alert-with-description {
  align-items: flex-start;
  padding: 15px 15px 15px 24px;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px;
}
.ant-alert-with-description .ant-alert-icon {
  margin-right: 15px;
  font-size: 24px;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: #1a3353;
  font-size: 16px;
}
.ant-alert-message {
  color: #1a3353;
}
.ant-alert-with-description .ant-alert-description {
  display: block;
}
.ant-alert.ant-alert-motion-leave {
  overflow: hidden;
  opacity: 1;
  transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-alert.ant-alert-motion-leave-active {
  max-height: 0;
  margin-bottom: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}
.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
.ant-alert.ant-alert-rtl {
  direction: rtl;
}
.ant-alert-rtl.ant-alert.ant-alert-no-icon {
  padding: 8px 15px;
}
.ant-alert-rtl .ant-alert-icon {
  margin-right: auto;
  margin-left: 8px;
}
.ant-alert-rtl .ant-alert-action,
.ant-alert-rtl .ant-alert-close-icon {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
  margin-right: auto;
  margin-left: 15px;
}
.ant-anchor {
  box-sizing: border-box;
  margin: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  padding: 0 0 0 2px;
}
.ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: #fff;
}
.ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #edf2f9;
  content: " ";
}
.ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #3e79f7;
  border-radius: 8px;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}
.ant-anchor-ink-ball.visible {
  display: inline-block;
}
.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 6px;
  overflow: hidden;
  color: #455560;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: #3e79f7;
}
.ant-anchor-link .ant-anchor-link {
  padding-top: 5px;
  padding-bottom: 5px;
}
.ant-anchor-rtl {
  direction: rtl;
}
.ant-anchor-rtl.ant-anchor-wrapper {
  margin-right: -4px;
  margin-left: 0;
  padding-right: 4px;
  padding-left: 0;
}
.ant-anchor-rtl .ant-anchor-ink {
  right: 0;
  left: auto;
}
.ant-anchor-rtl .ant-anchor-ink-ball {
  right: 50%;
  left: 0;
  transform: translateX(50%);
}
.ant-anchor-rtl .ant-anchor-link {
  padding: 7px 16px 7px 0;
}
.ant-avatar,
.ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
}
.ant-select-auto-complete .ant-select-clear {
  right: 13px;
}
.ant-avatar {
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ededed;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.ant-avatar-image {
  /* background: 0 0; */
}
.ant-avatar .ant-image-img,
.ant-btn.ant-btn-loading::before,
.ant-carousel .slick-slide img {
  display: block;
}
.ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
.ant-avatar-lg.ant-avatar-icon > .anticon,
.ant-avatar-sm.ant-avatar-icon > .anticon,
.ant-avatar.ant-avatar-icon > .anticon,
.ant-card-loading-content p {
  margin: 0;
}
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 50%;
}
.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-lg.ant-avatar-icon {
  font-size: 28px;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
.ant-avatar-square {
  border-radius: 0.625rem;
}
.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ant-avatar-group {
  display: inline-flex;
}
.ant-avatar-group .ant-avatar {
  border: 1px solid #fff;
}
.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -8px;
}
.ant-avatar-group-popover .ant-avatar + .ant-avatar {
  margin-left: 3px;
}
.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
  margin-right: -8px;
  margin-left: 0;
}
.ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
  margin-right: 3px;
  margin-left: 0;
}
.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-back-top:empty {
  display: none;
}
.ant-back-top-rtl {
  right: auto;
  left: 100px;
  direction: rtl;
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: #72849a;
  border-radius: 20px;
  transition: all 0.3s;
}
.ant-back-top-content:hover {
  background-color: #455560;
  transition: all 0.3s;
}
.ant-back-top-icon {
  font-size: 24px;
  line-height: 40px;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
}
.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  line-height: 1;
}
.ant-badge-count {
  z-index: auto;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ff6b72;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff;
}
.ant-badge-count-sm {
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  border-radius: 7px;
}
.ant-badge-multiple-words {
  padding: 0 8px;
}
.ant-badge-dot {
  z-index: auto;
  width: 7px;
  height: 7px;
  background: #ff6b72;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge .ant-scroll-number-custom-component,
.ant-badge-count,
.ant-badge-dot {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0;
}
.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 7px;
  height: 7px;
  vertical-align: middle;
  border-radius: 50%;
}
.ant-badge-status-success {
  background-color: #04d182;
}
.ant-badge-status-processing {
  position: relative;
  background-color: #3e79f7;
}
.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #3e79f7;
  border-radius: 50%;
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: "";
}
.ant-badge-status-default {
  background-color: #fafafb;
}
.ant-badge-status-error {
  background-color: #ff6b72;
}
.ant-badge-status-warning {
  background-color: #ffc542;
}
.ant-badge-status-magenta,
.ant-badge-status-pink {
  background: #eb2f96;
}
.ant-badge-status-red {
  background: #de4436;
}
.ant-badge-status-volcano {
  background: #ff6b72;
}
.ant-badge-status-orange {
  background: #fa8c16;
}
.ant-badge-status-yellow {
  background: #fadb14;
}
.ant-badge-status-gold {
  background: #ffc542;
}
.ant-badge-status-cyan {
  background: #04d182;
}
.ant-badge-status-lime {
  background: #a0d911;
}
.ant-badge-status-green {
  background: #21b573;
}
.ant-badge-status-blue {
  background: #3e79f7;
}
.ant-badge-status-geekblue {
  background: #17bcff;
}
.ant-badge-status-purple {
  background: #a461d8;
}
.ant-badge-status-text {
  margin-left: 8px;
  color: #455560;
  font-size: 14px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter,
.ant-badge-zoom-leave {
  -webkit-animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.ant-badge-zoom-leave {
  -webkit-animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}
.ant-badge-not-a-wrapper .ant-badge-zoom-appear,
.ant-badge-not-a-wrapper .ant-badge-zoom-enter {
  -webkit-animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.ant-badge-not-a-wrapper .ant-badge-zoom-leave {
  -webkit-animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
  transform-origin: 50% 50%;
}
.ant-scroll-number {
  overflow: hidden;
}
.ant-scroll-number-only {
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0;
}
.ant-scroll-number-symbol {
  vertical-align: top;
}
.ant-ribbon-wrapper {
  position: relative;
}
.ant-ribbon {
  box-sizing: border-box;
  margin: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  top: 8px;
  height: 22px;
  padding: 0 8px;
  color: #fff;
  line-height: 22px;
  white-space: nowrap;
  background-color: #3e79f7;
  border-radius: 0.25rem;
}
.ant-ribbon-text {
  color: #fff;
}
.ant-ribbon-corner {
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px;
  color: currentColor;
  border: 4px solid;
  transform: scaleY(0.75);
  transform-origin: top;
}
.ant-ribbon-corner::after {
  position: absolute;
  top: -4px;
  left: -4px;
  width: inherit;
  height: inherit;
  color: rgba(0, 0, 0, 0.25);
  border: inherit;
  content: "";
}
.ant-ribbon-color-magenta,
.ant-ribbon-color-pink {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-red {
  color: #de4436;
  background: #de4436;
}
.ant-ribbon-color-volcano {
  color: #ff6b72;
  background: #ff6b72;
}
.ant-ribbon-color-orange {
  color: #fa8c16;
  background: #fa8c16;
}
.ant-ribbon-color-yellow {
  color: #fadb14;
  background: #fadb14;
}
.ant-ribbon-color-gold {
  color: #ffc542;
  background: #ffc542;
}
.ant-ribbon-color-cyan {
  color: #04d182;
  background: #04d182;
}
.ant-ribbon-color-lime {
  color: #a0d911;
  background: #a0d911;
}
.ant-ribbon-color-green {
  color: #21b573;
  background: #21b573;
}
.ant-ribbon-color-blue {
  color: #3e79f7;
  background: #3e79f7;
}
.ant-ribbon-color-geekblue {
  color: #17bcff;
  background: #17bcff;
}
.ant-ribbon-color-purple {
  color: #a461d8;
  background: #a461d8;
}
.ant-ribbon.ant-ribbon-placement-end {
  right: -8px;
  border-bottom-right-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
  right: 0;
  border-color: currentColor transparent transparent currentColor;
}
.ant-ribbon.ant-ribbon-placement-start {
  left: -8px;
  border-bottom-left-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
  left: 0;
  border-color: currentColor currentColor transparent transparent;
}
.ant-badge-rtl,
.ant-ribbon-rtl {
  direction: rtl;
}
.ant-badge-rtl .ant-badge .ant-scroll-number-custom-component,
.ant-badge-rtl .ant-badge-count,
.ant-badge-rtl .ant-badge-dot {
  right: auto;
  left: 0;
  direction: ltr;
  transform: translate(-50%, -50%);
  transform-origin: 0 0;
}
.ant-badge-rtl.ant-badge .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  transform: translate(-50%, -50%);
  transform-origin: 0 0;
}
.ant-badge-rtl .ant-badge-status-text {
  margin-right: 8px;
  margin-left: 0;
}
.ant-badge-rtl .ant-badge-zoom-appear,
.ant-badge-rtl .ant-badge-zoom-enter {
  -webkit-animation-name: antZoomBadgeInRtl;
  animation-name: antZoomBadgeInRtl;
}
.ant-badge-rtl .ant-badge-zoom-leave {
  -webkit-animation-name: antZoomBadgeOutRtl;
  animation-name: antZoomBadgeOutRtl;
}
.ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}
.ant-ribbon-rtl.ant-ribbon-placement-end {
  right: unset;
  left: -8px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
  right: unset;
  left: 0;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner,
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
  border-color: currentColor currentColor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-start {
  right: -8px;
  left: unset;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0.25rem;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
  right: 0;
  left: unset;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner,
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
  border-color: currentColor transparent transparent currentColor;
}
.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  font-size: 14px;
}
.ant-breadcrumb .anticon {
  font-size: 14px;
}
.ant-breadcrumb a {
  transition: color 0.3s;
}
.ant-breadcrumb a:hover {
  color: #699dff;
}
.ant-breadcrumb > span:last-child a {
  color: #455560;
}
.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
  display: none;
}
.ant-breadcrumb-separator {
  margin: 0 8px;
  color: #72849a;
}
.ant-breadcrumb-link > .anticon + a,
.ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}
.ant-breadcrumb-rtl {
  direction: rtl;
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: "";
}
.ant-breadcrumb-rtl::after {
  display: table;
  display: block;
  clear: both;
  content: "";
}
.ant-breadcrumb-rtl > span {
  float: right;
}
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + a,
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .anticon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-btn {
  line-height: 1.5;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 40px;
  padding: 8.5px 1.25rem;
  font-size: 14px;
  border-radius: 0.625rem;
  color: #455560;
  background: #fff;
  border-color: #edf2f9;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg,
.ant-btn-sm {
  height: 48px;
  padding: 11px 1.5rem;
  font-size: 16px;
  border-radius: 0.625rem;
}
.ant-btn-sm {
  height: 36px;
  padding: 6.5px 1rem;
  font-size: 14px;
}
.ant-btn > a:only-child {
  color: currentColor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn:focus,
.ant-btn:hover {
  color: #699dff;
  border-color: #699dff;
}
.ant-btn:focus > a:only-child,
.ant-btn:hover > a:only-child {
  color: currentColor;
}
.ant-btn:focus > a:only-child::after,
.ant-btn:hover > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn:active {
  color: #2a59d1;
  background: #fff;
  border-color: #2a59d1;
}
.ant-btn-primary > a:only-child,
.ant-btn:active > a:only-child {
  color: currentColor;
}
.ant-btn-primary > a:only-child::after,
.ant-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn[disabled],
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
  color: #d0d4d7;
  background: #f7f7f8;
  border-color: #edf2f9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary:focus > a:only-child,
.ant-btn-primary:hover > a:only-child,
.ant-btn[disabled]:active > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-primary:focus > a:only-child::after,
.ant-btn-primary:hover > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover {
  text-decoration: none;
  background: #fff;
}
.ant-btn > span,
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  display: inline-block;
}
.ant-btn-primary {
  /* color: #4A2584; */
  color: #3e79f7;
  /* sbackground: #3e79f7; */
  /* border-color: #3e79f7; */
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  color: #3e79f7;
  background: #f5f3f9;
  border-color: #f5f3f9;
}
.ant-btn-primary:active {
  color: #fff;
  background: #2a59d1;
  border-color: #2a59d1;
}
.ant-btn-ghost > a:only-child,
.ant-btn-primary:active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost > a:only-child::after,
.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
  color: #d0d4d7;
  background: #f7f7f8;
  border-color: #edf2f9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost:focus > a:only-child,
.ant-btn-ghost:hover > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:focus > a:only-child::after,
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #699dff;
  border-left-color: #699dff;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #edf2f9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #699dff;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #edf2f9;
}
.ant-btn-group .ant-btn-primary + .ant-btn-primary,
.ant-btn-group .ant-btn-primary:last-child:not(:first-child) {
  border-left-color: #699dff;
}
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled],
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled] {
  border-left-color: #edf2f9;
}
.ant-btn-ghost {
  color: #455560;
  background: 0 0;
  border-color: #edf2f9;
}
.ant-btn-ghost:focus,
.ant-btn-ghost:hover {
  color: #699dff;
  background: 0 0;
  border-color: #699dff;
}
.ant-btn-ghost:active {
  color: #2a59d1;
  background: 0 0;
  border-color: #2a59d1;
}
.ant-btn-dashed > a:only-child,
.ant-btn-ghost:active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed > a:only-child::after,
.ant-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:active,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:hover {
  color: #d0d4d7;
  background: #f7f7f8;
  border-color: #edf2f9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed:focus > a:only-child,
.ant-btn-dashed:hover > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:focus > a:only-child::after,
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dashed {
  color: #455560;
  background: #fff;
  border-color: #edf2f9;
  border-style: dashed;
}
.ant-btn-dashed:focus,
.ant-btn-dashed:hover {
  color: #699dff;
  background: #fff;
  border-color: #699dff;
}
.ant-btn-dashed:active {
  color: #2a59d1;
  background: #fff;
  border-color: #2a59d1;
}
.ant-btn-danger > a:only-child,
.ant-btn-dashed:active > a:only-child {
  color: currentColor;
}
.ant-btn-danger > a:only-child::after,
.ant-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:active,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:hover {
  color: #d0d4d7;
  background: #f7f7f8;
  border-color: #edf2f9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger:focus > a:only-child,
.ant-btn-danger:hover > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-danger:focus > a:only-child::after,
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-danger {
  color: #fff;
  background: #ff6b72;
  border-color: #ff6b72;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger:focus,
.ant-btn-danger:hover {
  color: #fff;
  background: #ff9496;
  border-color: #ff9496;
}
.ant-btn-danger:active {
  color: #fff;
  background: #d9505c;
  border-color: #d9505c;
}
.ant-btn-danger:active > a:only-child,
.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-danger:active > a:only-child::after,
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:active,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:hover {
  color: #d0d4d7;
  background: #f7f7f8;
  border-color: #edf2f9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-link:focus > a:only-child,
.ant-btn-link:hover > a:only-child {
  color: currentColor;
}
.ant-btn-danger[disabled]:active > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-link:focus > a:only-child::after,
.ant-btn-link:hover > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-link {
  color: #3e79f7;
  background: 0 0;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-link:focus,
.ant-btn-link:hover {
  color: #699dff;
  background: 0 0;
}
.ant-btn-link:active {
  color: #2a59d1;
  background: 0 0;
  border-color: #2a59d1;
}
.ant-btn-link:active > a:only-child,
.ant-btn-text > a:only-child {
  color: currentColor;
}
.ant-btn-link:active > a:only-child::after,
.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:active,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:hover {
  background: #f7f7f8;
}
.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-link:hover {
  background: 0 0;
}
.ant-btn-link:active,
.ant-btn-link:focus,
.ant-btn-link:hover {
  border-color: transparent;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:active,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:hover {
  color: #d0d4d7;
  background: 0 0;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text {
  color: #455560;
  background: 0 0;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-text:focus,
.ant-btn-text:hover {
  background: 0 0;
}
.ant-btn-text:focus > a:only-child,
.ant-btn-text:hover > a:only-child {
  color: currentColor;
}
.ant-btn-text:focus > a:only-child::after,
.ant-btn-text:hover > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-text:active {
  background: 0 0;
}
.ant-btn-dangerous > a:only-child,
.ant-btn-text:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous > a:only-child::after,
.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:active,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:hover {
  background: #f7f7f8;
}
.ant-btn-text[disabled]:active > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-text[disabled]:active > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-text:focus,
.ant-btn-text:hover {
  color: #455560;
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.ant-btn-text:active {
  color: #455560;
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:active,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:hover {
  color: #d0d4d7;
  background: 0 0;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous {
  background: #fff;
}
.ant-btn-dangerous:focus,
.ant-btn-dangerous:hover {
  background: #fff;
}
.ant-btn-dangerous:focus > a:only-child,
.ant-btn-dangerous:hover > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous:focus > a:only-child::after,
.ant-btn-dangerous:hover > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous:active {
  background: #fff;
}
.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:active,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:hover {
  color: #d0d4d7;
  background: #f7f7f8;
  border-color: #edf2f9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous[disabled]:active > a:only-child,
.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-dangerous[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous[disabled]:active > a:only-child::after,
.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-dangerous[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous.ant-btn-primary {
  background: #ff6b72;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dangerous.ant-btn-link > a:only-child,
.ant-btn-dangerous.ant-btn-primary:active > a:only-child,
.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:active > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous.ant-btn-primary:focus,
.ant-btn-dangerous.ant-btn-primary:hover {
  background: #ff9496;
}
.ant-btn-dangerous.ant-btn-primary:active {
  background: #d9505c;
}
.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:active,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover {
  color: #d0d4d7;
  background: #f7f7f8;
  border-color: #edf2f9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous.ant-btn-link,
.ant-btn-dangerous.ant-btn-text {
  background: 0 0;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text {
  color: #ff6b72;
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:active,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:hover {
  background: #f7f7f8;
}
.ant-btn-dangerous.ant-btn-link:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link:hover > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous.ant-btn-link:focus,
.ant-btn-dangerous.ant-btn-link:hover {
  background: 0 0;
  border-color: transparent;
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #d9505c;
  background: 0 0;
  border-color: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child,
.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after,
.ant-btn-dangerous.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:active,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:hover {
  color: #d0d4d7;
  background: 0 0;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous.ant-btn-text:focus,
.ant-btn-dangerous.ant-btn-text:hover {
  background: 0 0;
}
.ant-btn-dangerous.ant-btn-text:active {
  background: 0 0;
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:active,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:hover {
  background: #f7f7f8;
}
.ant-btn-dangerous.ant-btn-text:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text:hover > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous.ant-btn-text:focus,
.ant-btn-dangerous.ant-btn-text:hover {
  color: #ff9496;
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #d9505c;
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child,
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:active,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:hover {
  color: #d0d4d7;
  background: 0 0;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-icon-only {
  width: 40px;
  height: 40px;
  padding: 7px 0;
  font-size: 16px;
  border-radius: 0.625rem;
  vertical-align: -1px;
}
.ant-btn-icon-only > * {
  font-size: 16px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 48px;
  height: 48px;
  padding: 9.5px 0;
  font-size: 18px;
  border-radius: 0.625rem;
}
.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 36px;
  height: 36px;
  padding: 6.5px 0;
  font-size: 14px;
  border-radius: 0.625rem;
}
.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}
.ant-btn-round {
  height: 40px;
  padding: 8.5px 20px;
  font-size: 14px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-lg {
  height: 48px;
  padding: 11px 24px;
  font-size: 16px;
  border-radius: 48px;
}
.ant-btn-round.ant-btn-sm {
  height: 36px;
  padding: 6.5px 18px;
  font-size: 14px;
  border-radius: 36px;
}
.ant-btn-round.ant-btn-icon-only {
  width: auto;
}
.ant-btn-circle {
  min-width: 40px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg {
  min-width: 48px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm {
  min-width: 36px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: "";
  pointer-events: none;
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-minus > svg,
.ant-btn .anticon.anticon-plus > svg {
  shape-rendering: optimizeSpeed;
}
.ant-btn.ant-btn-loading {
  position: relative;
}
.ant-btn.ant-btn-loading:not([disabled]) {
  pointer-events: none;
}
.ant-btn > .ant-btn-loading-icon {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 8px;
  -webkit-animation: none;
  animation: none;
}
.ant-btn > .ant-btn-loading-icon .anticon svg {
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}
.ant-btn-group {
  display: inline-flex;
}
.ant-btn-group,
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:active,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:active,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:hover {
  z-index: 2;
}
.ant-btn-group > .ant-btn[disabled],
.ant-btn-group > span > .ant-btn[disabled] {
  z-index: 0;
}
.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  height: 48px;
  padding: 11px 1.5rem;
  font-size: 16px;
  border-radius: 0;
}
.ant-btn-group-lg .ant-btn.ant-btn-icon-only {
  width: 48px;
  height: 48px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
  height: 36px;
  padding: 6.5px 1rem;
  font-size: 14px;
  border-radius: 0;
}
.ant-btn-group-sm > .ant-btn > .anticon,
.ant-btn-group-sm > span > .ant-btn > .anticon,
.ant-form label {
  font-size: 14px;
}
.ant-btn-group-sm .ant-btn.ant-btn-icon-only {
  width: 36px;
  height: 36px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn + .ant-btn-group,
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group span + .ant-btn,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group,
.ant-btn-group > span + span {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group-sm > .ant-btn:only-child,
.ant-btn-group-sm > span:only-child > .ant-btn,
.ant-btn-group > .ant-btn:only-child,
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 0.625rem;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-rtl.ant-btn-group > span + span {
  margin-right: -1px;
  margin-left: auto;
}
.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0.625rem;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0.625rem;
}
.ant-btn:active > span,
.ant-btn:focus > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn-background-ghost {
  color: #fff;
  background: 0 0 !important;
  border-color: #fff;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #3e79f7;
  background: 0 0;
  border-color: #3e79f7;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary:focus,
.ant-btn-background-ghost.ant-btn-primary:hover {
  color: #699dff;
  background: 0 0;
  border-color: #699dff;
}
.ant-btn-background-ghost.ant-btn-primary:active {
  color: #2a59d1;
  background: 0 0;
  border-color: #2a59d1;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:active,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover {
  color: #d0d4d7;
  background: #f7f7f8;
  border-color: #edf2f9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-background-ghost.ant-btn-danger,
.ant-btn-background-ghost.ant-btn-dangerous {
  color: #ff6b72;
  background: 0 0;
  border-color: #ff6b72;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger:focus,
.ant-btn-background-ghost.ant-btn-danger:hover {
  color: #ff9496;
  background: 0 0;
  border-color: #ff9496;
}
.ant-btn-background-ghost.ant-btn-danger:active {
  color: #d9505c;
  background: 0 0;
  border-color: #d9505c;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:active,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover {
  color: #d0d4d7;
  background: #f7f7f8;
  border-color: #edf2f9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous:focus,
.ant-btn-background-ghost.ant-btn-dangerous:hover {
  color: #ff9496;
  background: 0 0;
  border-color: #ff9496;
}
.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #d9505c;
  background: 0 0;
  border-color: #d9505c;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover {
  color: #d0d4d7;
  background: #f7f7f8;
  border-color: #edf2f9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #ff6b72;
  background: 0 0;
  border-color: transparent;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover {
  color: #ff9496;
  background: 0 0;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #d9505c;
  background: 0 0;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover {
  color: #d0d4d7;
  background: #f7f7f8;
  border-color: #edf2f9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > :not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: "\a0";
}
a.ant-btn {
  padding-top: 0.1px;
  line-height: 38px;
}
a.ant-btn-lg {
  line-height: 46px;
}
a.ant-btn-sm {
  line-height: 34px;
}
.ant-btn-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary,
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child) {
  border-right-color: #699dff;
  border-left-color: #edf2f9;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled] {
  border-right-color: #edf2f9;
  border-left-color: #699dff;
}
.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon,
.ant-comment-rtl .ant-comment-content-author > a,
.ant-comment-rtl .ant-comment-content-author > span {
  padding-right: 0;
  padding-left: 8px;
}
.ant-btn > .ant-btn-loading-icon:only-child .anticon {
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-rtl.ant-btn > .anticon + span,
.ant-btn-rtl.ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  background: #fff;
}
.ant-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}
.ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 80px;
}
.ant-picker-calendar-header .ant-picker-calendar-month-select {
  min-width: 70px;
  margin-left: 8px;
}
.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 8px;
}
.ant-picker-calendar .ant-picker-panel {
  background: #fff;
  border: 0;
  border-top: 1px solid #edf2f9;
  border-radius: 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-date-panel,
.ant-picker-calendar .ant-picker-panel .ant-picker-month-panel {
  width: auto;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-content {
  width: 100%;
}
.ant-picker-calendar-mini {
  border-radius: 0.625rem;
}
.ant-picker-calendar-mini .ant-picker-calendar-header {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 0 0 0.625rem 0.625rem;
}
.ant-picker-calendar-mini .ant-picker-content {
  height: 256px;
}
.ant-picker-calendar-mini .ant-picker-content th {
  height: auto;
  padding: 0;
  line-height: 18px;
}
.ant-picker-calendar-full .ant-picker-panel {
  display: block;
  width: 100%;
  text-align: right;
  background: #fff;
  border: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
  padding: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
}
.ant-carousel .slick-slide.slick-loading img,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today::before,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
  background: #f5f5f5;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #f0f7ff;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  display: block;
  width: auto;
  height: auto;
  border: 0;
  border-radius: 0;
  transition: background 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  transition: color 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  overflow-y: auto;
  color: #455560;
  line-height: 1.5;
  text-align: left;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: #3e79f7;
}
.ant-picker-header > button:hover {
  color: #455560;
}
@media only screen and (max-width: 480px) {
  .ant-picker-calendar-header {
    display: block;
  }
  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    width: 50%;
  }
  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    width: calc(50% - 8px);
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
    width: 50%;
    text-align: center;
  }
}
.ant-picker-calendar-rtl {
  direction: rtl;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch,
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
  text-align: left;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  padding: 0 0 5px 12px;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  text-align: right;
}
.ant-card {
  box-sizing: border-box;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  background: #fff;
  border-radius: 0.625rem;
}
.ant-card-rtl {
  direction: rtl;
}
.ant-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-head {
  color: #1a3353;
  font-weight: 500;
  background: 0 0;
  border-radius: 0.625rem 0.625rem 0 0;
}
.ant-card-head::before {
  display: table;
  content: "";
}
.ant-card-head::after {
  display: table;
  display: block;
  clear: both;
  content: "";
}
.ant-card-head-wrapper {
  display: flex;
  align-items: center;
}
.ant-card-head-title {
  display: inline-block;
  flex: 1;
  padding: 20px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-head-title > .ant-typography,
.ant-card-head-title > .ant-typography-edit-content {
  left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.ant-card-extra,
.ant-card-head .ant-tabs {
  color: #455560;
  font-weight: 400;
  font-size: 14px;
}
.ant-card-head .ant-tabs {
  clear: both;
}
.ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 20px 0;
}
.ant-card-rtl .ant-card-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-card-body {
  padding: 20px;
}
.ant-card-body::before {
  display: table;
  content: "";
}
.ant-card-body::after {
  display: table;
  display: block;
  clear: both;
  content: "";
}
.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.ant-card-grid {
  float: left;
  width: 33.33%;
  padding: 20px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #edf2f9, 0 1px 0 0 #edf2f9, 1px 1px 0 0 #edf2f9, 1px 0 0 0 #edf2f9 inset, 0 1px 0 0 #edf2f9 inset;
  transition: all 0.3s;
}
.ant-card-rtl .ant-card-actions > li,
.ant-card-rtl .ant-card-grid {
  float: right;
}
.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 15px;
  padding-bottom: 0;
}
.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0;
}
.ant-card-bordered .ant-card-cover {
  margin-right: -1px;
  margin-left: -1px;
  transform: translateY(-1px);
}
.ant-card-cover > * {
  display: block;
  width: 100%;
}
.ant-card-cover img {
  border-radius: 0.625rem 0.625rem 0 0;
}
.ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-top: 1px solid #edf2f9;
}
.ant-card-actions::before {
  display: table;
  content: "";
}
.ant-card-actions::after {
  display: table;
  display: block;
  clear: both;
  content: "";
}
.ant-card-actions > li {
  float: left;
  margin: 12px 0;
  color: #72849a;
  text-align: center;
}
.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 1.5;
  cursor: pointer;
}
.ant-card-actions > li > span:hover {
  color: #3e79f7;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: #72849a;
  line-height: 22px;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: #3e79f7;
}
.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #edf2f9;
}
.ant-card-type-inner .ant-card-head {
  padding: 0 20px;
  background: #fafafa;
}
.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.ant-card-type-inner .ant-card-body {
  padding: 16px 20px;
}
.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
.ant-card-meta {
  margin: -4px 0;
}
.ant-card-meta::before,
.ant-carousel .slick-track::after,
.ant-carousel .slick-track::before {
  display: table;
  content: "";
}
.ant-card-meta::after {
  display: table;
  display: block;
  clear: both;
  content: "";
}
.ant-card-meta-avatar {
  float: left;
  padding-right: 16px;
}
.ant-card-rtl .ant-card-meta-avatar {
  float: right;
  padding-right: 0;
  padding-left: 16px;
}
.ant-card-meta-detail {
  overflow: hidden;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.ant-card-meta-title {
  overflow: hidden;
  color: #1a3353;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-meta-description {
  color: #72849a;
}
.ant-card-loading {
  overflow: hidden;
}
.ant-card-loading .ant-card-body {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 0.625rem;
  -webkit-animation: card-loading 1.4s ease infinite;
  animation: card-loading 1.4s ease infinite;
}
.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 10px;
  font-size: 14px;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 10px 0;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra,
.ant-carousel {
  padding: 10px 0;
  font-size: 14px;
}
.ant-card-small > .ant-card-body {
  padding: 10px;
}
.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: 0;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide,
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input {
  visibility: visible;
}
.ant-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom;
}
.ant-carousel .slick-slider .slick-list,
.ant-carousel .slick-slider .slick-track {
  transform: translate3d(0, 0, 0);
  touch-action: pan-y;
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.ant-carousel .slick-track::after {
  clear: both;
}
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input,
.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-next,
.ant-carousel .slick-prev {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: 0 0;
  border: 0;
  outline: 0;
  cursor: pointer;
}
.ant-carousel .slick-next:focus,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-prev:hover {
  color: transparent;
  background: 0 0;
  outline: 0;
}
.ant-carousel .slick-dots li.slick-active:focus,
.ant-carousel .slick-dots li.slick-active:hover,
.ant-carousel .slick-next:focus::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-prev:hover::before {
  opacity: 1;
}
.ant-carousel .slick-next.slick-disabled::before,
.ant-carousel .slick-prev.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: "←";
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before,
.ant-carousel-rtl .ant-carousel .slick-prev::before {
  content: "→";
}
.ant-carousel .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex !important;
  justify-content: center;
  margin-right: 15%;
  margin-left: 15%;
  padding-left: 0;
  list-style: none;
}
.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
.ant-carousel .slick-dots-top {
  top: 12px;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  margin: 0 3px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button {
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: 0;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button:focus,
.ant-carousel .slick-dots li button:hover {
  opacity: 0.75;
}
.ant-carousel .slick-dots li.slick-active {
  width: 24px;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
  right: auto;
  left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
  right: 12px;
  left: auto;
}
.ant-carousel-vertical .slick-dots li {
  width: 3px;
  height: 16px;
  margin: 4px 2px;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active,
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.ant-carousel-rtl {
  direction: rtl;
}
.ant-carousel-rtl .ant-carousel .slick-track {
  right: 0;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev {
  right: -25px;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-next {
  right: auto;
  left: -25px;
}
.ant-carousel-rtl .ant-carousel .slick-next::before {
  content: "←";
}
.ant-carousel-rtl.ant-carousel .slick-dots {
  flex-direction: row-reverse;
}
.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
  flex-direction: column;
}
.ant-cascader,
.ant-cascader-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
}
.ant-cascader-input.ant-input {
  position: static;
  width: 100%;
  padding-right: 24px;
  background-color: transparent !important;
  cursor: pointer;
}
.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
  position: relative;
}
.ant-cascader-picker {
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 0.625rem;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-cascader-picker-with-value .ant-cascader-picker-label {
  color: transparent;
}
.ant-cascader-picker-disabled {
  color: #d0d4d7;
  background: #f7f7f8;
  cursor: not-allowed;
}
.ant-cascader-picker-disabled .ant-cascader-input {
  cursor: not-allowed;
}
.ant-cascader-picker:focus .ant-cascader-input {
  border-color: #699dff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(62, 121, 247, 0.2);
}
.ant-input-rtl .ant-cascader-picker:focus .ant-cascader-input {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-cascader-picker-borderless .ant-cascader-input {
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-cascader-picker-show-search.ant-cascader-picker-focused {
  color: #d0d4d7;
}
.ant-cascader-picker-label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  margin-top: -10px;
  padding: 0 20px 0 12px;
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-cascader-picker-arrow,
.ant-cascader-picker-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: #d0d4d7;
  font-size: 12px;
  line-height: 12px;
}
.ant-cascader-picker-clear {
  z-index: 2;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
}
.ant-cascader-picker-clear:hover {
  color: #72849a;
}
.ant-cascader-picker:hover .ant-cascader-picker-clear {
  opacity: 1;
}
.ant-cascader-picker-arrow {
  z-index: 1;
}
.ant-cascader-picker-label:hover + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
  border-color: #699dff;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-cascader-picker-label:hover + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-cascader-picker-small .ant-cascader-picker-arrow,
.ant-cascader-picker-small .ant-cascader-picker-clear {
  right: 8px;
}
.ant-cascader-menus {
  position: absolute;
  z-index: 1050;
  font-size: 14px;
  white-space: nowrap;
  background: #fff;
  border-radius: 0.625rem;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-cascader-menus ol,
.ant-cascader-menus ul {
  margin: 0;
  list-style: none;
}
.ant-cascader-menus-empty,
.ant-cascader-menus-hidden,
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow::before {
  display: none;
}
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft,
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
}
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft,
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
}
.ant-cascader-menu {
  display: inline-block;
  min-width: 111px;
  height: 180px;
  margin: 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #edf2f9;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu:first-child {
  border-radius: 0.625rem 0 0 0.625rem;
}
.ant-cascader-menu:last-child {
  margin-right: -1px;
  border-right-color: transparent;
  border-radius: 0 0.625rem 0.625rem 0;
}
.ant-cascader-menu:only-child {
  border-radius: 0.625rem;
}
.ant-cascader-menu-item {
  padding: 8px 12px;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-cascader-menu-item:hover,
.ant-picker-week-panel-row:hover td {
  background: #f5f5f5;
}
.ant-cascader-menu-item-disabled,
.ant-checkbox-disabled + span {
  color: #d0d4d7;
  cursor: not-allowed;
}
.ant-cascader-menu-item-disabled:hover {
  background: 0 0;
}
.ant-cascader-menu-empty .ant-cascader-menu-item {
  color: #d0d4d7;
  cursor: default;
  pointer-events: none;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #f5f5f5;
}
.ant-cascader-menu-item-expand {
  position: relative;
  padding-right: 24px;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  position: absolute;
  right: 12px;
  color: #72849a;
  font-size: 10px;
}
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
  color: #d0d4d7;
}
.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: #ff6b72;
}
.ant-cascader-picker-rtl .ant-cascader-input.ant-input {
  padding-right: 11px;
  padding-left: 24px;
  text-align: right;
}
.ant-cascader-picker-rtl {
  direction: rtl;
}
.ant-cascader-picker-rtl .ant-cascader-picker-label {
  padding: 0 12px 0 20px;
  text-align: right;
}
.ant-cascader-picker-rtl .ant-cascader-picker-arrow,
.ant-cascader-picker-rtl .ant-cascader-picker-clear {
  right: auto;
  left: 12px;
}
.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-arrow,
.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-clear {
  right: auto;
  left: 8px;
}
.ant-cascader-menu-rtl .ant-cascader-menu {
  direction: rtl;
  border-right: none;
  border-left: 1px solid #edf2f9;
}
.ant-cascader-menu-rtl .ant-cascader-menu:first-child {
  border-radius: 0 0.625rem 0.625rem 0;
}
.ant-cascader-menu-rtl .ant-cascader-menu:last-child {
  margin-right: 0;
  margin-left: -1px;
  border-left-color: transparent;
  border-radius: 0.625rem 0 0 0.625rem;
}
.ant-cascader-menu-rtl .ant-cascader-menu:only-child {
  border-radius: 0.625rem;
}
.ant-cascader-menu-rtl .ant-cascader-menu-item-expand {
  padding-right: 12px;
  padding-left: 24px;
}
.ant-cascader-menu-rtl .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
  right: auto;
  left: 12px;
}
.ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
  transform: scaleY(-1);
}
.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #3e79f7;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #3e79f7;
  border-radius: 0.625rem;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: "";
}
.ant-checkbox-wrapper:hover .ant-checkbox::after,
.ant-checkbox:hover::after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #edf2f9;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-checkbox-checked .ant-checkbox-inner::after,
.ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: " ";
}
.ant-checkbox-inner::after {
  top: 50%;
  left: 22%;
  width: 7.14285714px;
  height: 11.42857143px;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
}
.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3e79f7;
  border-color: #3e79f7;
}
.ant-checkbox-disabled,
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #d0d4d7;
  -webkit-animation-name: none;
  animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f7f7f8;
  border-color: #edf2f9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f7f7f8;
  border-collapse: separate;
  -webkit-animation-name: none;
  animation-name: none;
}
.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}
.ant-checkbox-group,
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
}
.ant-checkbox-wrapper {
  line-height: unset;
  cursor: pointer;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-checkbox-group {
  line-height: 1.5;
}
.ant-checkbox-group-item {
  display: inline-block;
  margin-right: 10px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #edf2f9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  background-color: #3e79f7;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: " ";
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: #d0d4d7;
  border-color: #d0d4d7;
}
.ant-checkbox-rtl {
  direction: rtl;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item {
  margin-right: 0;
  margin-left: 10px;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 10px;
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  background-color: #fafafa;
  border: 1px solid #edf2f9;
  border-bottom: 0;
  border-radius: 0.625rem;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #edf2f9;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 0.625rem 0.625rem;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  color: #1a3353;
  line-height: 1.5;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header::before {
  display: table;
  content: "";
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
  display: table;
  display: block;
  clear: both;
  content: "";
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 16px;
  left: 16px;
  display: inline-block;
  font-size: 12px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow > * {
  line-height: 1;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow-icon {
  display: block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: right;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus,
.ant-rate-star > div:focus {
  outline: 0;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 40px 12px 16px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 16px;
  left: auto;
}
.ant-collapse-content {
  color: #455560;
  background-color: #fff;
  border-top: 1px solid #edf2f9;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-hidden {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 0.625rem 0.625rem;
}
.ant-collapse-borderless {
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #edf2f9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content,
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse-ghost {
  background-color: transparent;
  border: 0;
}
.ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: #d0d4d7;
  cursor: not-allowed;
}
.ant-collapse-rtl {
  direction: rtl;
}
.ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 40px 12px 16px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(180deg);
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: left;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-right: 12px;
  padding-left: 0;
}
.ant-comment {
  position: relative;
  background-color: inherit;
}
.ant-comment-inner {
  display: flex;
  padding: 16px 0;
}
.ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.ant-comment-content-author {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
.ant-comment-content-author > a,
.ant-comment-content-author > span {
  padding-right: 8px;
  line-height: 18px;
}
.ant-comment-content-author-name {
  font-size: 14px;
  transition: color 0.3s;
}
.ant-comment-content-author-time {
  color: rgba(26, 51, 83, 0.6);
  white-space: nowrap;
  cursor: auto;
}
.ant-comment-content-detail p {
  margin-bottom: inherit;
  white-space: pre-wrap;
}
.ant-comment-actions {
  margin-top: 12px;
  margin-bottom: inherit;
  padding-left: 0;
}
.ant-comment-actions > li {
  display: inline-block;
  color: #72849a;
}
.ant-comment-actions > li > span {
  margin-right: 10px;
  color: #72849a;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-comment-actions > li > span:hover {
  color: #595959;
}
.ant-comment-nested {
  margin-left: 44px;
}
.ant-comment-rtl,
.ant-picker-input > input-rtl {
  direction: rtl;
}
.ant-comment-rtl .ant-comment-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-comment-rtl .ant-comment-actions {
  padding-right: 0;
}
.ant-comment-rtl .ant-comment-actions > li > span {
  margin-right: 0;
  margin-left: 10px;
}
.ant-comment-rtl .ant-comment-nested {
  margin-right: 44px;
  margin-left: 0;
}
.ant-picker,
.ant-picker-input > input {
  position: relative;
  color: #455560;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.625rem;
}
.ant-picker {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  padding: 8.5px 11px;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #edf2f9;
  transition: border 0.3s, box-shadow 0.3s;
}
.ant-picker-focused,
.ant-picker:hover {
  border-color: #699dff;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-picker-focused,
.ant-input-rtl .ant-picker-input > input:hover,
.ant-input-rtl .ant-picker:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-focused {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(62, 121, 247, 0.2);
}
.ant-picker.ant-picker-disabled {
  background: #f7f7f8;
  border-color: #edf2f9;
  cursor: not-allowed;
}
.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: #d0d4d7;
}
.ant-picker.ant-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-picker-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.ant-picker-input > input {
  display: inline-block;
  width: 100%;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: 0 0;
  border: 0;
}
.ant-picker-input > input::-webkit-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-picker-input > input:-ms-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
  text-overflow: ellipsis;
}
.ant-picker-input > input::-ms-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-picker-input > input::placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-picker-input > input:hover {
  border-color: #699dff;
  border-right-width: 1px !important;
}
.ant-picker-input > input-focused,
.ant-picker-input > input:focus {
  border-color: #699dff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(62, 121, 247, 0.2);
}
.ant-input-rtl .ant-picker-input > input-focused,
.ant-input-rtl .ant-picker-input > input:focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-input > input-disabled,
.ant-picker-input > input[disabled] {
  color: #d0d4d7;
  background-color: #f7f7f8;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input-disabled:hover,
.ant-picker-input > input[disabled]:hover {
  border-color: #edf2f9;
  border-right-width: 1px !important;
}
.ant-picker-input > input-borderless,
.ant-picker-input > input-borderless-disabled,
.ant-picker-input > input-borderless-focused,
.ant-picker-input > input-borderless:focus,
.ant-picker-input > input-borderless:hover,
.ant-picker-input > input-borderless[disabled] {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}
textarea.ant-input,
textarea.ant-input-affix-wrapper,
textarea.ant-input-number,
textarea.ant-mentions,
textarea.ant-picker-input > input {
  max-width: 100%;
  height: auto;
  min-height: 40px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-picker-input > input-lg {
  padding: 11px;
  font-size: 16px;
}
.ant-picker-input > input-sm {
  padding: 6.5px 7px;
}
.ant-picker-input > input:focus {
  box-shadow: none;
}
.ant-picker-input > input[disabled] {
  background: 0 0;
}
.ant-picker-input:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-input-placeholder > input {
  color: rgba(114, 132, 154, 0.4);
}
.ant-picker-large {
  padding: 11px;
}
.ant-picker-large .ant-picker-input > input {
  font-size: 16px;
}
.ant-picker-small {
  padding: 6.5px 7px;
}
.ant-picker-suffix {
  align-self: center;
  margin-left: 4px;
  color: #d0d4d7;
  line-height: 1;
  pointer-events: none;
}
.ant-picker-suffix > * {
  vertical-align: top;
}
.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: #d0d4d7;
  line-height: 1;
  background: #fff;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
}
.ant-picker-clear > * {
  vertical-align: top;
}
.ant-picker-clear:hover,
.ant-picker-focused .ant-picker-separator {
  color: #72849a;
}
.ant-picker-separator {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 16px;
  color: #d0d4d7;
  font-size: 16px;
  vertical-align: top;
  cursor: default;
}
.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  cursor: not-allowed;
}
.ant-picker-range {
  position: relative;
  display: inline-flex;
}
.ant-picker-range .ant-picker-clear {
  right: 11px;
}
.ant-picker-range.ant-picker-focused .ant-picker-active-bar,
.ant-picker-range:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #3e79f7;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}
.ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
}
.ant-picker-range.ant-picker-small .ant-picker-clear {
  right: 7px;
}
.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-left: 7px;
}
.ant-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  z-index: 1050;
}
.ant-picker-dropdown-hidden {
  display: none;
}
.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  top: 1.66666667px;
  display: block;
  transform: rotate(-45deg);
}
.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
  bottom: 1.66666667px;
  display: block;
  transform: rotate(135deg);
}
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-topRight,
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
}
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-bottomRight,
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
}
.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
}
.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
}
.ant-picker-dropdown-range {
  padding: 6.66666667px 0;
}
.ant-picker-dropdown-range-hidden {
  display: none;
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 4px;
}
.ant-picker-ranges {
  margin-bottom: 0;
  padding: 4px 12px;
  overflow: hidden;
  line-height: 34px;
  text-align: left;
  list-style: none;
}
.ant-picker-ranges > li {
  display: inline-block;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #3e79f7;
  background: #f0f7ff;
  border-color: #bad7ff;
  cursor: pointer;
}
.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px;
}
.ant-picker-range-wrapper {
  display: flex;
}
.ant-picker-range-arrow {
  position: absolute;
  z-index: 1;
  display: none;
  width: 10px;
  height: 10px;
  margin-left: 16.5px;
  box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
  transition: left 0.3s ease-out;
}
.ant-picker-range-arrow::after {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 10px;
  height: 10px;
  border: 5px solid #edf2f9;
  border-color: #fff #fff transparent transparent;
  content: "";
}
.ant-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
  border-radius: 0.625rem;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s;
}
.ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  flex-wrap: nowrap;
  direction: ltr;
}
.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: 0 0;
  border-width: 0 0 1px;
  border-radius: 0;
}
.ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #edf2f9;
}
.ant-picker-panel {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
  outline: 0;
}
.ant-picker-panel-focused {
  border-color: #3e79f7;
}
.ant-picker-date-panel,
.ant-picker-decade-panel,
.ant-picker-month-panel,
.ant-picker-quarter-panel,
.ant-picker-week-panel,
.ant-picker-year-panel {
  display: flex;
  flex-direction: column;
  width: 280px;
}
.ant-picker-time-panel {
  display: flex;
  flex-direction: column;
}
.ant-picker-header {
  display: flex;
  padding: 0 8px;
  color: #1a3353;
  border-bottom: 1px solid #edf2f9;
}
.ant-picker-header > * {
  flex: none;
}
.ant-picker-header button {
  padding: 0;
  color: #d0d4d7;
  line-height: 40px;
  background: 0 0;
  border: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
}
.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}
.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}
.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}
.ant-picker-header-view button:hover {
  color: #3e79f7;
}
.ant-picker-next-icon,
.ant-picker-prev-icon,
.ant-picker-super-next-icon,
.ant-picker-super-prev-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
}
.ant-picker-next-icon::before,
.ant-picker-prev-icon::before,
.ant-picker-super-next-icon::after,
.ant-picker-super-next-icon::before,
.ant-picker-super-prev-icon::after,
.ant-picker-super-prev-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentColor;
  border-width: 1.5px 0 0 1.5px;
  content: "";
}
.ant-picker-super-next-icon::after,
.ant-picker-super-prev-icon::after {
  top: 4px;
  left: 4px;
}
.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
  transform: rotate(-45deg);
}
.ant-picker-next-icon,
.ant-picker-super-next-icon {
  transform: rotate(135deg);
}
.ant-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.ant-picker-content td,
.ant-picker-content th {
  position: relative;
  min-width: 24px;
  font-weight: 400;
}
.ant-picker-content th {
  height: 30px;
  color: #455560;
  line-height: 30px;
}
.ant-picker-cell {
  padding: 3px 0;
  color: #d0d4d7;
  cursor: pointer;
}
.ant-dropdown-menu-item > .anticon + span > a:hover,
.ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-submenu-title > .anticon + span > a:hover,
.ant-dropdown-menu-submenu-title > a:hover,
.ant-picker-cell-in-view {
  color: #455560;
}
.ant-picker-cell-disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  transform: translateY(-50%);
  content: "";
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
  .ant-picker-cell-inner {
  background: #f5f5f5;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #3e79f7;
  border-radius: 0.625rem;
  content: "";
}
.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: rgba(62, 121, 247, 0.1);
}
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  color: #fff;
  background: #3e79f7;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
  background: rgba(62, 121, 247, 0.1);
}
.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end::before,
.ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  border-top: 1px dashed #a0bdfb;
  border-bottom: 1px dashed #a0bdfb;
  transform: translateY(-50%);
  content: "";
}
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-range-hover::after {
  right: 0;
  left: 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before {
  background: #e9f0fe;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 0 0.625rem 0.625rem 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #e9f0fe;
  content: "";
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  left: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after {
  left: 6px;
  border-left: 1px dashed #a0bdfb;
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after {
  right: 6px;
  border-right: 1px dashed #a0bdfb;
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}
.ant-picker-cell-disabled .ant-picker-cell-inner {
  color: #d0d4d7;
  background: 0 0;
}
.ant-picker-cell-disabled::before,
.ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
  background: #f7f7f8;
}
.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #d0d4d7;
}
.ant-picker-decade-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content,
.ant-picker-year-panel .ant-picker-content {
  height: 264px;
}
.ant-picker-month-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner {
  padding: 0 8px;
}
.ant-picker-quarter-panel .ant-picker-content {
  height: 56px;
}
.ant-picker-footer {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent;
}
.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #edf2f9;
}
.ant-picker-footer-extra {
  padding: 0 12px;
  line-height: 38px;
  text-align: left;
}
.ant-picker-footer-extra:not(:last-child),
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #edf2f9;
}
.ant-picker-now {
  text-align: left;
}
.ant-picker-today-btn {
  color: #3e79f7;
}
.ant-picker-today-btn:hover {
  color: #699dff;
}
.ant-picker-today-btn:active {
  color: #2a59d1;
}
.ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: #d0d4d7;
  cursor: not-allowed;
}
.ant-picker-decade-panel .ant-picker-cell-inner {
  padding: 0 4px;
}
.ant-picker-decade-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-month-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-year-panel .ant-picker-body {
  padding: 0 8px;
}
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-year-panel .ant-picker-cell-range-hover-start::after {
  left: 14px;
  border-left: 1px dashed #a0bdfb;
  border-radius: 0.625rem 0 0 0.625rem;
}
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-year-panel .ant-picker-cell-range-hover-end::after {
  right: 14px;
  border-right: 1px dashed #a0bdfb;
  border-radius: 0 0.625rem 0.625rem 0;
}
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after {
  left: 14px;
  border-left: 1px dashed #a0bdfb;
  border-radius: 0.625rem 0 0 0.625rem;
}
.ant-picker-date-panel .ant-picker-body,
.ant-picker-week-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner {
  background: 0 0 !important;
}
.ant-picker-week-panel-row td {
  transition: background 0.3s;
}
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #3e79f7;
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: rgba(255, 255, 255, 0.5);
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #fff;
}
.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff;
}
.ant-picker-date-panel .ant-picker-content {
  width: 252px;
}
.ant-picker-date-panel .ant-picker-content th {
  width: 36px;
}
.ant-picker-datetime-panel {
  display: flex;
}
.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #edf2f9;
}
.ant-picker-datetime-panel .ant-picker-date-panel,
.ant-picker-datetime-panel .ant-picker-time-panel {
  transition: opacity 0.3s;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel,
.ant-picker-datetime-panel-active .ant-picker-time-panel {
  opacity: 0.3;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel-active,
.ant-picker-datetime-panel-active .ant-picker-time-panel-active {
  opacity: 1;
}
.ant-picker-time-panel {
  width: auto;
  min-width: auto;
}
.ant-picker-time-panel .ant-picker-content {
  display: flex;
  flex: auto;
  height: 224px;
}
.ant-picker-time-panel-column {
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
  list-style: none;
  transition: background 0.3s;
}
.ant-picker-time-panel-column::after {
  display: block;
  height: 196px;
  content: "";
}
.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
  height: 198px;
}
.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #edf2f9;
}
.ant-picker-time-panel-column-active {
  background: rgba(240, 247, 255, 0.2);
}
.ant-picker-time-panel-column:hover {
  overflow-y: auto;
}
.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  color: #455560;
  line-height: 28px;
  border-radius: 0;
  cursor: pointer;
  transition: background 0.3s;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #f0f7ff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: #d0d4d7;
  background: 0 0;
  cursor: not-allowed;
}
:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0;
}
.ant-picker-panel-rtl,
.ant-picker-rtl {
  direction: rtl;
}
.ant-picker-rtl .ant-picker-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-picker-rtl .ant-picker-clear {
  right: auto;
  left: 0;
}
.ant-picker-rtl .ant-picker-separator {
  transform: rotate(180deg);
}
.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range .ant-picker-clear {
  right: auto;
  left: 11px;
}
.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
  margin-right: 11px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-right: 7px;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-picker-dropdown-rtl .ant-picker-ranges {
  text-align: right;
}
.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-panel-rtl .ant-picker-prev-icon,
.ant-picker-panel-rtl .ant-picker-super-prev-icon {
  transform: rotate(135deg);
}
.ant-picker-panel-rtl .ant-picker-next-icon,
.ant-picker-panel-rtl .ant-picker-super-next-icon {
  transform: rotate(-45deg);
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before,
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
  right: 50%;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  right: 50%;
  left: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0.625rem 0 0 0.625rem;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed #a0bdfb;
  border-left: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed #a0bdfb;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0.625rem;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after {
  right: 6px;
  left: 6px;
  border-right: 1px dashed #a0bdfb;
  border-left: 1px dashed #a0bdfb;
  border-radius: 0.625rem;
}
.ant-picker-dropdown-rtl .ant-picker-footer-extra {
  direction: rtl;
  text-align: right;
}
.ant-picker-panel-rtl .ant-picker-time-panel {
  direction: ltr;
}
.ant-descriptions-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.ant-descriptions-title {
  flex: auto;
  overflow: hidden;
  color: #1a3353;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-descriptions-extra {
  margin-left: auto;
  color: #455560;
  font-size: 14px;
}
.ant-descriptions-view {
  width: 100%;
  overflow: hidden;
  border-radius: 0.625rem;
}
.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}
.ant-descriptions-row > td,
.ant-descriptions-row > th {
  padding-bottom: 16px;
}
.ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-item-label {
  color: #1a3353;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  text-align: start;
}
.ant-descriptions-item-label::after,
.ant-form-item-label > label::after {
  content: ":";
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-descriptions-item-label.ant-descriptions-item-no-colon::after {
  content: " ";
}
.ant-descriptions-item-no-label::after {
  margin: 0;
  content: "";
}
.ant-descriptions-item-content {
  display: table-cell;
  flex: 1;
  color: #455560;
  font-size: 14px;
  line-height: 1.5;
  word-break: break-word;
  overflow-wrap: break-word;
}
.ant-descriptions-item {
  padding-bottom: 0;
  vertical-align: top;
}
.ant-descriptions-item-container {
  display: flex;
}
.ant-descriptions-item-container .ant-descriptions-item-content,
.ant-descriptions-item-container .ant-descriptions-item-label {
  display: inline-flex;
  align-items: baseline;
}
.ant-descriptions-middle .ant-descriptions-row > td,
.ant-descriptions-middle .ant-descriptions-row > th {
  padding-bottom: 12px;
}
.ant-descriptions-small .ant-descriptions-row > td,
.ant-descriptions-small .ant-descriptions-row > th {
  padding-bottom: 8px;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #edf2f9;
}
.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
}
.ant-descriptions-bordered .ant-descriptions-item-content,
.ant-descriptions-bordered .ant-descriptions-item-label {
  padding: 16px 24px;
  border-right: 1px solid #edf2f9;
}
.ant-descriptions-bordered .ant-descriptions-item-content:last-child,
.ant-descriptions-bordered .ant-descriptions-item-label:last-child {
  border-right: none;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #f7f7f8;
}
.ant-descriptions-bordered .ant-descriptions-item-label::after {
  display: none;
}
.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #edf2f9;
}
.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label {
  padding: 12px 24px;
}
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
  padding: 8px 16px;
}
.ant-descriptions-rtl {
  direction: rtl;
}
.ant-descriptions-rtl .ant-descriptions-item-label::after {
  margin: 0 2px 0 8px;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label {
  border-right: none;
  border-left: 1px solid #edf2f9;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child {
  border-left: none;
}
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  border-top: 1px solid #edf2f9;
}
.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid #edf2f9;
}
.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
}
.ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  margin: 16px 0;
  color: #1a3353;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: #edf2f9;
}
.ant-divider-horizontal.ant-divider-with-text::after,
.ant-divider-horizontal.ant-divider-with-text::before {
  position: relative;
  top: 50%;
  width: 50%;
  border-top: 1px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  transform: translateY(50%);
  content: "";
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}
.ant-divider-horizontal.ant-divider-with-text-left::after,
.ant-divider-horizontal.ant-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
}
.ant-divider-dashed {
  background: 0 0;
  border-color: #edf2f9;
  border-style: dashed;
  border-width: 1px 0 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed {
  border-top: 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before {
  border-style: dashed none none;
}
.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}
.ant-divider-plain.ant-divider-with-text {
  color: #455560;
  font-weight: 400;
  font-size: 14px;
}
.ant-divider-rtl {
  direction: rtl;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 95%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::after,
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::before {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::after {
  width: 95%;
}
.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
}
.ant-drawer > * {
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper,
.ant-empty-image img {
  height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-left,
.ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right,
.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.ant-drawer-bottom,
.ant-drawer-top {
  left: 0;
  width: 100%;
  height: 0%;
}
.ant-drawer-bottom .ant-drawer-content-wrapper,
.ant-drawer-top .ant-drawer-content-wrapper {
  width: 100%;
}
.ant-drawer-bottom.ant-drawer-open,
.ant-drawer-top.ant-drawer-open {
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-top {
  top: 0;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  transition: none;
  -webkit-animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  pointer-events: auto;
}
.ant-drawer-title {
  margin: 0;
  color: #1a3353;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  padding: 20px;
  color: #72849a;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: 0 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: #455560;
  text-decoration: none;
}
.ant-drawer-header-no-title .ant-drawer-close {
  margin-right: var(--scroll-bar);
  padding-right: calc(20px - var(--scroll-bar));
}
.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  border-bottom: 1px solid #edf2f9;
  border-radius: 0.625rem 0.625rem 0 0;
}
.ant-drawer-header,
.ant-drawer-header-no-title {
  color: #455560;
  background: #fff;
}
.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}
.ant-drawer-body {
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
.ant-drawer-footer {
  flex-shrink: 0;
  padding: 10px;
  border-top: 1px solid #edf2f9;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  filter: alpha(opacity=45);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none;
}
.ant-drawer-open-content {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-drawer .ant-picker-clear {
  background: #fff;
}
.ant-drawer-rtl {
  direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
  right: auto;
  left: 0;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #ff6b72;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #ff6b72;
}
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: -4px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: " ";
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 10px;
}
.ant-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after,
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden,
.ant-form-vertical .ant-form-item-label > label::after {
  display: none;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-topCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 10px;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 10px;
}
.ant-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: 0 0;
  border-style: solid;
  border-width: 4.24264069px;
  transform: rotate(45deg);
}
.ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 6.2px;
  border-color: transparent #fff #fff transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-dropdown-placement-topCenter > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 6px;
  border-color: #fff transparent transparent #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.625rem;
  outline: 0;
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: #72849a;
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: 0 0;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup li,
.ant-dropdown-menu-submenu-popup ul {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  color: #455560;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-item > a > .anticon:first-child,
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-menu-submenu-title > a > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
  vertical-align: -0.1em;
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  display: block;
  color: #455560;
  transition: all 0.3s;
}
.ant-dropdown-menu-item > .anticon + span > a,
.ant-dropdown-menu-submenu-title > .anticon + span > a {
  color: #455560;
  transition: all 0.3s;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #3e79f7;
  background-color: #f0f7ff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #f5f5f5;
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: #d0d4d7;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: #d0d4d7;
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #edf2f9;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: #72849a;
  font-size: 10px;
  font-style: normal;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: #d0d4d7;
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #3e79f7;
}
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
}
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
}
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
}
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
}
.ant-dropdown-button > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-trigger > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #283142;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after {
  color: #99abb4;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover {
  color: #fff;
  background: 0 0;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #3e79f7;
}
.ant-dropdown-rtl {
  direction: rtl;
}
.ant-dropdown-rtl.ant-dropdown::before {
  right: -7px;
  left: 0;
}
.ant-dropdown-menu.ant-dropdown-menu-rtl,
.ant-dropdown-rtl .ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
  transform-origin: 100% 0;
}
.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  right: auto;
  left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  transform: scaleX(-1);
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
}
.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.ant-empty-image svg {
  height: 100%;
  margin: auto;
}
.ant-empty-description {
  margin: 0;
}
.ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-normal {
  margin: 32px 0;
  color: #d0d4d7;
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}
.ant-empty-small {
  margin: 8px 0;
  color: #d0d4d7;
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}
.ant-empty-img-default-ellipse {
  fill-opacity: 0.8;
  fill: #f5f5f5;
}
.ant-empty-img-default-path-1 {
  fill: #aeb8c2;
}
.ant-empty-img-default-path-2 {
  fill: url(#linearGradient-1);
}
.ant-empty-img-default-path-3 {
  fill: #f5f5f7;
}
.ant-empty-img-default-path-4,
.ant-empty-img-default-path-5 {
  fill: #dce0e6;
}
.ant-empty-img-default-g {
  fill: #fff;
}
.ant-empty-img-simple-ellipse {
  fill: #f5f5f5;
}
.ant-empty-img-simple-g {
  stroke: #d9d9d9;
}
.ant-empty-img-simple-path {
  fill: #fafafa;
}
.ant-empty-rtl {
  direction: rtl;
}
.ant-form select[multiple],
.ant-form select[size],
.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input,
.ant-form-vertical .ant-form-item-label > label {
  height: auto;
}
.ant-form-item .ant-upload {
  background: 0 0;
}
.ant-form-item .ant-upload.ant-upload-drag {
  background: #fafafa;
}
.ant-form-item input[type="checkbox"],
.ant-form-item input[type="radio"] {
  width: 14px;
  height: 14px;
}
.ant-form-item .ant-checkbox-inline,
.ant-form-item .ant-radio-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
}
.ant-form-item .ant-checkbox-inline:first-child,
.ant-form-item .ant-radio-inline:first-child {
  margin-left: 0;
}
.ant-form-item .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical {
  display: block;
}
.ant-form-item .ant-checkbox-vertical + .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
}
.ant-form-item .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
.ant-form-item .ant-input-number-handler-wrap {
  z-index: 2;
}
.ant-form-item .ant-cascader-picker,
.ant-form-item .ant-select {
  width: 100%;
}
.ant-form-item .ant-input-group .ant-cascader-picker,
.ant-form-item .ant-input-group .ant-select,
tr.ant-table-expanded-row .ant-descriptions-view table {
  width: auto;
}
.ant-form-inline {
  display: flex;
  flex-wrap: wrap;
}
.ant-form-inline .ant-form-item {
  flex: none;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > .ant-form-item-control,
.ant-form-inline .ant-form-item > .ant-form-item-label {
  display: inline-block;
  vertical-align: top;
}
.ant-form-inline .ant-form-item > .ant-form-item-label {
  flex: none;
}
.ant-form-inline .ant-form-item .ant-form-item-has-feedback,
.ant-form-inline .ant-form-item .ant-form-text,
.ant-input-number-handler-down-inner svg,
.ant-input-number-handler-up-inner svg {
  display: inline-block;
}
.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
}
.ant-form-horizontal .ant-form-item-control {
  flex: 1 1 0;
}
.ant-form-vertical .ant-form-item {
  flex-direction: column;
}
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label,
.ant-form-vertical .ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5;
  white-space: initial;
  text-align: left;
}
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label,
.ant-form-vertical .ant-form-item-label > label {
  margin: 0;
}
.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label,
.ant-form-rtl.ant-form-vertical .ant-form-item-label {
  text-align: right;
}
@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label > label,
  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xs-24.ant-form-item-label > label::after,
  .ant-form-item .ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-form-item .ant-form-item-label {
    text-align: right;
  }
  .ant-form .ant-form-item {
    flex-wrap: wrap;
  }
  .ant-form .ant-form-item .ant-form-item-control,
  .ant-form .ant-form-item .ant-form-item-label {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-sm-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-col-md-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-md-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1399px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-lg-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xl-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right;
  }
}
.ant-form-item-explain.ant-form-item-explain-error {
  color: #ff6b72;
}
.ant-form-item-explain.ant-form-item-explain-warning {
  color: #ffc542;
}
.ant-form-item-has-feedback .ant-input {
  padding-right: 24px;
}
.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 18px;
}
.ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: 28px;
}
.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear,
.ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback > .ant-select .ant-select-clear {
  right: 32px;
}
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value,
.ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
}
.ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 19px;
}
.ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: 32px;
}
.ant-form-item-has-feedback .ant-picker,
.ant-form-item-has-feedback .ant-picker-large {
  padding-right: 29.2px;
}
.ant-form-item-has-feedback .ant-picker-small {
  padding-right: 25.2px;
}
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 40px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #04d182;
  -webkit-animation-name: diffZoomIn1 !important;
  animation-name: diffZoomIn1 !important;
}
.ant-form-item-has-warning .ant-form-item-split {
  color: #ffc542;
}
.ant-form-item-has-warning .ant-input,
.ant-form-item-has-warning .ant-input-affix-wrapper,
.ant-form-item-has-warning .ant-input-affix-wrapper:hover,
.ant-form-item-has-warning .ant-input:hover {
  background-color: #fff;
  border-color: #ffc542;
}
.ant-form-item-has-warning .ant-input-affix-wrapper-focused,
.ant-form-item-has-warning .ant-input-affix-wrapper:focus,
.ant-form-item-has-warning .ant-input-focused,
.ant-form-item-has-warning .ant-input:focus {
  border-color: #ffd86b;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 197, 66, 0.2);
}
.ant-form-item-has-warning .ant-input-affix-wrapper-disabled,
.ant-form-item-has-warning .ant-input-disabled {
  background-color: #f7f7f8;
  border-color: #edf2f9;
}
.ant-form-item-has-error .ant-input-affix-wrapper-disabled input:focus,
.ant-form-item-has-warning .ant-input-affix-wrapper-disabled input:focus {
  box-shadow: none !important;
}
.ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ffd86b;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 197, 66, 0.2);
}
.ant-form-item-has-warning .ant-input-prefix {
  color: #ffc542;
}
.ant-form-item-has-warning .ant-input-group-addon {
  color: #ffc542;
  border-color: #ffc542;
}
.ant-form-item-has-warning .has-feedback {
  color: #ffc542;
}
.ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #ffc542;
  -webkit-animation-name: diffZoomIn3 !important;
  animation-name: diffZoomIn3 !important;
}
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #ffc542 !important;
}
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector,
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector {
  border-color: #ffd86b;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 197, 66, 0.2);
}
.ant-form-item-has-warning .ant-input-number,
.ant-form-item-has-warning .ant-picker {
  background-color: #fff;
  border-color: #ffc542;
}
.ant-form-item-has-warning .ant-input-number-focused,
.ant-form-item-has-warning .ant-input-number:focus,
.ant-form-item-has-warning .ant-picker-focused,
.ant-form-item-has-warning .ant-picker:focus {
  border-color: #ffd86b;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 197, 66, 0.2);
}
.ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
.ant-form-item-has-warning .ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #ffc542;
}
.ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ffd86b;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 197, 66, 0.2);
}
.ant-form-item-has-error .ant-form-item-split {
  color: #ff6b72;
}
.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input-affix-wrapper:hover,
.ant-form-item-has-error .ant-input:hover {
  background-color: #fff;
  border-color: #ff6b72;
}
.ant-form-item-has-error .ant-input-affix-wrapper-focused,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input:focus {
  border-color: #ff9496;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 107, 114, 0.2);
}
.ant-form-item-has-error .ant-input-affix-wrapper-disabled,
.ant-form-item-has-error .ant-input-disabled {
  background-color: #f7f7f8;
  border-color: #edf2f9;
}
.ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ff9496;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 107, 114, 0.2);
}
.ant-form-item-has-error .ant-input-prefix {
  color: #ff6b72;
}
.ant-form-item-has-error .ant-input-group-addon {
  color: #ff6b72;
  border-color: #ff6b72;
}
.ant-form-item-has-error .has-feedback {
  color: #ff6b72;
}
.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #ff6b72;
  -webkit-animation-name: diffZoomIn2 !important;
  animation-name: diffZoomIn2 !important;
}
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #ff6b72 !important;
}
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector,
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector {
  border-color: #ff9496;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 107, 114, 0.2);
}
.ant-form-item-has-error .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
}
.ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #ff6b72;
}
.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
  background-color: #fff;
  border-color: #ff6b72;
}
.ant-form-item-has-error .ant-input-number-focused,
.ant-form-item-has-error .ant-input-number:focus,
.ant-form-item-has-error .ant-picker-focused,
.ant-form-item-has-error .ant-picker:focus {
  border-color: #ff9496;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 107, 114, 0.2);
}
.ant-form-item-has-error .ant-input-number:not([disabled]):hover,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover,
.ant-form-item-has-error .ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #ff6b72;
}
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus,
.ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor {
  border-color: #ff9496;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 107, 114, 0.2);
}
.ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
  background-color: #fff;
  border-color: #ff9496;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 107, 114, 0.2);
}
.ant-form-item-has-error .ant-transfer-list {
  border-color: #ff6b72;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
  border-color: #edf2f9;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #699dff;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #699dff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(62, 121, 247, 0.2);
}
.ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-form-item-has-error .ant-radio-button-wrapper {
  border-color: #ff6b72 !important;
}
.ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #ff6b72;
}
.ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color: #3e79f7;
}
.ant-form {
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
}
.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: #72849a;
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #edf2f9;
}
.ant-form,
.ant-form input[type="search"] {
  box-sizing: border-box;
}
.ant-form input[type="checkbox"],
.ant-form input[type="radio"] {
  line-height: normal;
}
.ant-form input[type="file"] {
  display: block;
}
.ant-form input[type="range"] {
  display: block;
  width: 100%;
}
.ant-form input[type="checkbox"]:focus,
.ant-form input[type="file"]:focus,
.ant-form input[type="radio"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output,
.ant-form-item {
  color: #455560;
  font-size: 14px;
  line-height: 1.5;
}
.ant-form output {
  display: block;
  padding-top: 15px;
}
.ant-form .ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-small .ant-form-item-label > label {
  height: 36px;
}
.ant-form-small .ant-form-item-control-input {
  min-height: 36px;
}
.ant-form-large .ant-form-item-label > label {
  height: 48px;
}
.ant-form-large .ant-form-item-control-input {
  min-height: 48px;
}
.ant-form-item {
  box-sizing: border-box;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  margin: 0 0 24px;
  vertical-align: top;
}
.ant-form-item-with-help {
  margin-bottom: 0;
}
.ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional,
.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
  display: none;
}
.ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.ant-form-item-label-left {
  text-align: left;
}
.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 40px;
  font-size: 14px;
}
.ant-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff6b72;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.ant-form-item-label > label .ant-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: #72849a;
}
.ant-form-item-label > label .ant-form-item-tooltip {
  -webkit-writing-mode: horizontal-tb;
  -ms-writing-mode: lr-tb;
  writing-mode: horizontal-tb;
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
  color: #72849a;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: " ";
}
.ant-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.ant-form-item-control:first-child:not([class^="ant-col-"]):not([class*=" ant-col-"]) {
  width: 100%;
}
.ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 40px;
}
.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}
.ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  min-height: 24px;
  padding-top: 1px;
  color: #72849a;
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-form-item .ant-input-textarea-show-count::after {
  position: absolute;
  bottom: -22px;
  width: 100%;
}
.show-help-appear,
.show-help-enter,
.show-help-leave {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.show-help-appear.show-help-appear-active,
.show-help-enter.show-help-enter-active,
.show-help-leave.show-help-leave-active {
  -webkit-animation-name: antShowHelpIn;
  animation-name: antShowHelpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.show-help-leave.show-help-leave-active {
  -webkit-animation-name: antShowHelpOut;
  animation-name: antShowHelpOut;
  pointer-events: none;
}
.show-help-appear,
.show-help-enter {
  opacity: 0;
}
.show-help-appear,
.show-help-enter,
.show-help-leave {
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-form-rtl {
  direction: rtl;
}
.ant-form-rtl .ant-form-item-label {
  text-align: left;
}
.ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  margin-left: 4px;
}
.ant-form-rtl .ant-form-item-label > label::after {
  margin: 0 2px 0 8px;
}
.ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
  margin-right: 4px;
  margin-left: 0;
}
.ant-col-rtl .ant-form-item-control:first-child {
  width: 100%;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input {
  padding-right: 11px;
  padding-left: 24px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 11px;
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: auto;
  left: 28px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value {
  padding-right: 0;
  padding-left: 42px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 19px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker,
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
  padding-right: 7px;
  padding-left: 25.2px;
}
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  right: auto;
  left: 0;
}
.ant-form-rtl.ant-form-inline .ant-form-item {
  margin-right: 0;
  margin-left: 16px;
}
.ant-row {
  flex-flow: row wrap;
}
.ant-row,
.ant-row::after,
.ant-row::before {
  display: flex;
}
.ant-row-no-wrap {
  flex-wrap: nowrap;
}
.ant-row-start {
  justify-content: flex-start;
}
.ant-row-center {
  justify-content: center;
}
.ant-row-end {
  justify-content: flex-end;
}
.ant-row-space-between {
  justify-content: space-between;
}
.ant-row-space-around {
  justify-content: space-around;
}
.ant-row-top {
  align-items: flex-start;
}
.ant-row-middle {
  align-items: center;
}
.ant-row-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}
.ant-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
.ant-col-xs-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-push-0.ant-col-rtl,
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-xs-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-xs-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-xs-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-xs-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-xs-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-xs-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-xs-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-xs-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-xs-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-xs-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-xs-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-xs-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-xs-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-xs-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-xs-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-xs-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-xs-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-xs-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-xs-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-xs-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-xs-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-xs-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-xs-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-xs-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-xs-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-xs-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-xs-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-xs-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-xs-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-xs-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-xs-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-xs-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-xs-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-xs-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-xs-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-xs-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-xs-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-xs-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-xs-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-xs-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-xs-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-xs-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-xs-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-xs-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-xs-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-xs-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-xs-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-xs-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-xs-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-xs-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-xs-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-xs-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-xs-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-xs-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-xs-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-xs-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-xs-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-push-0.ant-col-rtl,
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-sm-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-sm-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-sm-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-sm-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-sm-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-sm-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-sm-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-sm-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-sm-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-sm-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-sm-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-sm-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-sm-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-sm-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-sm-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-sm-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-sm-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-sm-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-sm-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-sm-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-sm-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-sm-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-sm-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-sm-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-sm-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-sm-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-sm-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-sm-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-sm-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-sm-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-sm-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-sm-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-sm-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-sm-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-sm-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-sm-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-sm-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0,
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-md-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-md-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-md-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-md-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-md-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-md-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-md-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-md-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-md-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-md-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-md-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-md-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-md-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-md-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-md-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-md-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-md-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-md-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-md-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-md-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-md-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-md-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-md-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-md-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-md-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-md-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-md-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-md-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-md-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-md-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-md-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-md-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-md-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-md-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-md-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-md-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-md-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-md-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-md-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-md-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-md-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-md-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-md-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-md-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-md-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-md-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-md-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-md-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-md-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-md-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-md-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-md-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-md-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-md-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-md-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-md-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-md-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0,
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-lg-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-lg-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-lg-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-lg-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-lg-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-lg-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-lg-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-lg-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-lg-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-lg-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-lg-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-lg-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-lg-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-lg-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-lg-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-lg-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-lg-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-lg-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-lg-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-lg-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-lg-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-lg-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-lg-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-lg-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-lg-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-lg-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-lg-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-lg-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-lg-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-lg-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-lg-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-lg-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-lg-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-lg-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-lg-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-lg-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-lg-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1400px) {
  .ant-col-xl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-push-0.ant-col-rtl,
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-push-0.ant-col-rtl,
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xxl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xxl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xxl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xxl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xxl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xxl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xxl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xxl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xxl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xxl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xxl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xxl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xxl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xxl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xxl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xxl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xxl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xxl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xxl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xxl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xxl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xxl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xxl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xxl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xxl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xxl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xxl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xxl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xxl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xxl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xxl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xxl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xxl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xxl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xxl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xxl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xxl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
.ant-row-rtl {
  direction: rtl;
}
.ant-image {
  position: relative;
  display: inline-block;
}
.ant-image-img {
  width: 100%;
  height: auto;
}
.ant-image-img-placeholder {
  background-color: #f5f5f5;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30%;
}
.ant-image-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-image-preview {
  pointer-events: none;
  height: 100%;
  text-align: center;
}
.ant-image-preview.zoom-appear,
.ant-image-preview.zoom-enter {
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-image-preview-mask-hidden {
  display: none;
}
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-image-preview-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.ant-image-preview-img,
.ant-image-preview-img-wrapper {
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
.ant-image-preview-img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  transform: scale3d(1, 1, 1);
  cursor: -webkit-grab;
  cursor: grab;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: auto;
}
.ant-image-preview-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-image-preview-img-wrapper::before {
  display: inline-block;
  width: 1px;
  height: 50%;
  margin-right: -1px;
  content: "";
}
.ant-image-preview-moving .ant-image-preview-img {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.ant-image-preview-moving .ant-image-preview-img-wrapper {
  transition-duration: 0s;
}
.ant-image-preview-wrap {
  z-index: 1080;
}
.ant-image-preview-operations {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: "tnum";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  color: #1a3353;
  list-style: none;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: auto;
}
.ant-image-preview-operations-operation {
  margin-left: 12px;
  padding: 12px;
  cursor: pointer;
}
.ant-image-preview-operations-operation-disabled {
  color: rgba(26, 51, 83, 0.25);
  pointer-events: none;
}
.ant-image-preview-operations-operation:last-of-type {
  margin-left: 0;
}
.ant-image-preview-operations-icon {
  font-size: 18px;
}
.ant-image-preview-switch-left {
  right: 10px;
}
.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  position: absolute;
  top: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin-top: -22px;
  color: #1a3353;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
}
.ant-image-preview-switch-left-disabled,
.ant-image-preview-switch-right-disabled {
  color: rgba(26, 51, 83, 0.25);
  cursor: not-allowed;
}
.ant-image-preview-switch-left-disabled > .anticon,
.ant-image-preview-switch-right-disabled > .anticon {
  cursor: not-allowed;
}
.ant-image-preview-switch-left > .anticon,
.ant-image-preview-switch-right > .anticon {
  font-size: 18px;
}
.ant-image-preview-switch-left {
  left: 10px;
}
.ant-image-preview-switch-right {
  right: 10px;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8.5px 11px;
  color: #455560;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
  transition: all 0.3s;
  display: inline-flex;
}
.ant-input-affix-wrapper::-webkit-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-input-affix-wrapper:-ms-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper::-ms-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-input-affix-wrapper::placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  border-color: #699dff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(62, 121, 247, 0.2);
}
.ant-input-rtl .ant-input-affix-wrapper-focused,
.ant-input-rtl .ant-input-affix-wrapper:focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper-disabled:hover,
.ant-input-affix-wrapper[disabled]:hover {
  border-color: #edf2f9;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper-disabled,
.ant-input-affix-wrapper[disabled] {
  color: #d0d4d7;
  background-color: #f7f7f8;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}
.ant-input-affix-wrapper-lg {
  padding: 11px;
  font-size: 16px;
}
.ant-input-affix-wrapper-sm {
  padding: 6.5px 7px;
}
.ant-input-affix-wrapper-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper:hover {
  border-color: #699dff;
  border-right-width: 1px !important;
  z-index: 1;
}
.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-search-with-button .ant-input-affix-wrapper:hover {
  z-index: 0;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: 0 0;
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: 0;
  outline: 0;
}
.ant-input-affix-wrapper > input.ant-input:focus {
  box-shadow: none;
}
.ant-input-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: "\a0";
}
.ant-input-prefix,
.ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}
.ant-input-prefix {
  margin-right: 4px;
}
.ant-input-suffix {
  margin-left: 4px;
}
.ant-input-clear-icon {
  margin: 0 4px;
  color: #d0d4d7;
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-input-clear-icon:hover {
  color: #72849a;
}
.ant-input-clear-icon:active {
  color: #455560;
}
.ant-input-clear-icon-hidden {
  visibility: hidden;
}
.ant-input-clear-icon:last-child {
  margin-right: 0;
}
.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0 !important;
  border: 0 !important;
}
.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8.5px 11px;
  color: #455560;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
  transition: all 0.3s;
}
.ant-input::-webkit-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-input:-ms-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
  text-overflow: ellipsis;
}
.ant-input::-ms-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-input::placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:hover {
  border-color: #699dff;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-focused,
.ant-input:focus {
  border-color: #699dff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(62, 121, 247, 0.2);
}
.ant-input-rtl .ant-input-focused,
.ant-input-rtl .ant-input:focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-disabled:hover,
.ant-input[disabled]:hover {
  border-color: #edf2f9;
  border-right-width: 1px !important;
}
.ant-input-disabled,
.ant-input[disabled] {
  color: #d0d4d7;
  background-color: #f7f7f8;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-borderless,
.ant-input-borderless-disabled,
.ant-input-borderless-focused,
.ant-input-borderless:focus,
.ant-input-borderless:hover,
.ant-input-borderless[disabled] {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}
.ant-input-lg {
  padding: 11px;
  font-size: 16px;
}
.ant-input-sm {
  padding: 6.5px 7px;
}
.ant-input-rtl {
  direction: rtl;
}
.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-group[class*="col-"] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-group > [class*="col-"] {
  padding-right: 8px;
}
.ant-input-group > [class*="col-"]:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button,
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 0;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: #455560;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  background-color: #f7f7f8;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -9.5px -11px;
}
.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-focused .ant-select-selector,
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-list-item-meta-title > a:hover {
  color: #3e79f7;
}
.ant-input-group-addon:first-child,
.ant-input-group-addon:first-child .ant-select .ant-select-selector,
.ant-input-group > .ant-input:first-child,
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group-addon:last-child,
.ant-input-group-addon:last-child .ant-select .ant-select-selector,
.ant-input-group > .ant-input:last-child,
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 11px;
  font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 6.5px 7px;
}
.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 48px;
}
.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 36px;
}
.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}
.ant-input-group.ant-input-group-compact {
  display: block;
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: "";
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  display: block;
  clear: both;
  content: "";
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper,
.ant-input-group.ant-input-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > :not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-select-focused,
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow,
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > :first-child {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > :last-child {
  border-right-width: 1px;
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-group-rtl .ant-input-group-addon:first-child,
.ant-input-group > .ant-input-rtl:first-child {
  border-radius: 0 0.625rem 0.625rem 0;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #edf2f9;
  border-left: 0;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #edf2f9;
}
.ant-input-group-rtl.ant-input-group-addon:last-child,
.ant-input-group-rtl.ant-input-group > .ant-input:last-child {
  border-radius: 0.625rem 0 0 0.625rem;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 0.625rem 0 0 0.625rem;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child),
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 0.625rem 0.625rem 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > :not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > :first-child {
  border-radius: 0 0.625rem 0.625rem 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > :last-child {
  border-left-width: 1px;
  border-radius: 0.625rem 0 0 0.625rem;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.ant-input-password-icon {
  color: #72849a;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-password-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-input-search-button,
.ant-input[type="color"] {
  height: 40px;
}
.ant-input[type="color"].ant-input-lg {
  height: 48px;
}
.ant-input[type="color"].ant-input-sm {
  height: 36px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.ant-input-textarea-show-count::after {
  display: block;
  color: #72849a;
  text-align: right;
  content: attr(data-count);
}
.ant-input-search .ant-input:focus,
.ant-input-search .ant-input:hover {
  border-color: #699dff;
}
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #699dff;
}
.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-search .ant-input-lg {
  line-height: 1.4998;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  left: -1px;
  padding: 0;
  border: 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 0.625rem 0.625rem 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: #72849a;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-input-search-button:focus,
.ant-input-search-button:hover {
  z-index: 1;
}
.ant-input-search-large .ant-input-search-button {
  height: 48px;
}
.ant-input-search-small .ant-input-search-button {
  height: 36px;
}
.ant-input-group-rtl,
.ant-input-group-wrapper-rtl,
.ant-input-search-rtl,
.ant-input-textarea-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
  border: 0;
  outline: 0;
}
.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 0 0 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-suffix {
  margin: 0 4px 0 0;
}
.ant-input-textarea-rtl.ant-input-textarea-show-count::after {
  text-align: left;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon:last-child {
  margin-right: 4px;
  margin-left: 0;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
  right: auto;
  left: 8px;
}
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-right-color: #699dff;
  border-left-color: #edf2f9;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover {
  border-right-color: #699dff;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon {
  right: -1px;
  left: auto;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
  border-radius: 0.625rem 0 0 0.625rem;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-input {
    height: 40px;
  }
  .ant-input-lg {
    height: 48px;
  }
  .ant-input-sm {
    height: 36px;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: auto;
  }
}
.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  min-width: 0;
  color: #455560;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
}
.ant-input-number::-webkit-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-input-number:-ms-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
  text-overflow: ellipsis;
}
.ant-input-number::-ms-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-input-number::placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-rtl .ant-input-number:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-focused,
.ant-input-number:focus {
  border-color: #699dff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(62, 121, 247, 0.2);
}
.ant-input-rtl .ant-input-number-focused,
.ant-input-rtl .ant-input-number:focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number[disabled]:hover {
  border-color: #edf2f9;
  border-right-width: 1px !important;
}
.ant-input-number[disabled] {
  color: #d0d4d7;
  background-color: #f7f7f8;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-borderless,
.ant-input-number-borderless-disabled,
.ant-input-number-borderless-focused,
.ant-input-number-borderless:focus,
.ant-input-number-borderless:hover,
.ant-input-number-borderless[disabled] {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}
.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: #72849a;
  font-weight: 700;
  line-height: 0;
  text-align: center;
  transition: all 0.1s linear;
}
.ant-input-number-handler:active {
  background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-down-inner,
.ant-input-number-handler:hover .ant-input-number-handler-up-inner {
  color: #699dff;
}
.ant-input-number-handler-down-inner,
.ant-input-number-handler-up-inner {
  display: inline-block;
  font-style: normal;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: #72849a;
  line-height: 12px;
  transition: all 0.1s linear;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-input-number-handler-down-inner > *,
.ant-input-number-handler-up-inner > * {
  line-height: 1;
}
.ant-input-number-handler-down-inner::before,
.ant-input-number-handler-up-inner::before {
  display: none;
}
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon {
  display: block;
}
.ant-input-number-focused,
.ant-input-number:hover {
  border-color: #699dff;
  border-right-width: 1px !important;
}
.ant-input-number:hover + .ant-form-item-children-icon {
  opacity: 0;
  transition: opacity 0.24s linear 0.24s;
}
.ant-input-number-focused {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(62, 121, 247, 0.2);
}
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-disabled {
  color: #d0d4d7;
  background-color: #f7f7f8;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #edf2f9;
  border-right-width: 1px !important;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap,
.ant-input-number-readonly .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  height: 38px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0.625rem;
  outline: 0;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important;
}
.ant-input-number-input::-webkit-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-input-number-input:-ms-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
  text-overflow: ellipsis;
}
.ant-input-number-input::-ms-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-input-number-input::placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input[type="number"]::-webkit-inner-spin-button,
.ant-input-number-input[type="number"]::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.ant-input-number-lg input {
  height: 46px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 34px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #edf2f9;
  border-radius: 0 0.625rem 0.625rem 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner {
  min-width: auto;
  margin-right: 0;
  font-size: 7px;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  border-top-right-radius: 0.625rem;
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-down:hover,
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #edf2f9;
  border-bottom-right-radius: 0.625rem;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}
.ant-input-number-handler-down-disabled,
.ant-input-number-handler-up-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner,
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner {
  color: #d0d4d7;
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-rtl .ant-input-number-handler-wrap {
  right: auto;
  left: 0;
  border-right: 1px solid #edf2f9;
  border-left: 0;
  border-radius: 0.625rem 0 0 0.625rem;
}
.ant-input-number-rtl .ant-input-number-input {
  direction: ltr;
  text-align: right;
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: 0 0;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
}
.ant-layout-footer,
.ant-layout-header {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 70px;
  padding: 0 50px;
  color: #455560;
  line-height: 70px;
  background: #283142;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: #455560;
  font-size: 14px;
  background: 0 0;
}
.ant-layout-content {
  flex: auto;
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  min-width: 0;
  background: #283142;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 70px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #283142;
  border-radius: 0 0.625rem 0.625rem 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  transition: all 0.3s;
  content: "";
}
.ant-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 0.625rem 0 0 0.625rem;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger,
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: #455560;
  background: #fff;
}
.ant-layout-rtl {
  direction: rtl;
}
.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
}
.ant-list * {
  outline: 0;
}
.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.ant-list-pagination .ant-pagination-options {
  text-align: left;
}
.ant-list-more {
  margin-top: 12px;
  text-align: center;
}
.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-list-spin {
  min-height: 40px;
  text-align: center;
}
.ant-list-empty-text {
  padding: 16px;
  color: #d0d4d7;
  font-size: 14px;
  text-align: center;
}
.ant-list-items,
.ant-menu ol,
.ant-menu ul,
.ant-pagination ol,
.ant-pagination ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  color: #455560;
}
.ant-list-item-meta {
  display: flex;
  flex: 1;
  align-items: flex-start;
  max-width: 100%;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
}
.ant-list-item-meta-content {
  flex: 1 0;
  width: 0;
  color: #455560;
}
.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: #455560;
  font-size: 14px;
  line-height: 1.5;
}
.ant-list-item-meta-title > a {
  color: #455560;
  transition: all 0.3s;
}
.ant-list-item-action > li,
.ant-list-item-meta-description {
  color: #72849a;
  line-height: 1.5;
}
.ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  font-size: 14px;
  text-align: center;
}
.ant-list-item-action > li:first-child,
.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #edf2f9;
}
.ant-list-footer,
.ant-list-header {
  background: 0 0;
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-empty {
  padding: 16px 0;
  color: #72849a;
  font-size: 12px;
  text-align: center;
}
.ant-list-split .ant-list-header,
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #edf2f9;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
.ant-list-split.ant-list-empty .ant-list-footer {
  border-top: 1px solid #edf2f9;
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child,
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #edf2f9;
}
.ant-list-lg .ant-list-item {
  padding: 16px 10px;
}
.ant-list-sm .ant-list-item {
  padding: 10px;
}
.ant-list-vertical .ant-list-item {
  align-items: initial;
}
.ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1;
}
.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-meta-title {
  color: #1a3353;
  font-size: 16px;
  line-height: 24px;
}
.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-list-item-no-flex {
  display: block;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
.ant-list-bordered {
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
}
.ant-list-bordered .ant-list-footer,
.ant-list-bordered .ant-list-header,
.ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-footer,
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 10px;
}
.ant-list-bordered.ant-list-lg .ant-list-footer,
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-item {
  padding: 16px 10px;
}
@media screen and (max-width: 768px) {
  .ant-list-item-action,
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }
  .ant-list-item-action {
    margin-left: 12px;
  }
  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-list-rtl {
  direction: rtl;
  text-align: right;
}
.ant-list-rtl .ReactVirtualized__List .ant-list-item {
  direction: rtl;
}
.ant-list-rtl .ant-list-pagination {
  text-align: left;
}
.ant-list-rtl .ant-list-item-meta-avatar {
  margin-right: 0;
  margin-left: 16px;
}
.ant-list-rtl .ant-list-item-action {
  margin-right: 48px;
  margin-left: 0;
}
.ant-list-rtl .ant-list-vertical .ant-list-item-action > li:first-child,
.ant-list.ant-list-rtl .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.ant-list-rtl .ant-list-item-action-split {
  right: auto;
  left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
  margin-right: 40px;
  margin-left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-action {
  margin-right: auto;
}
.ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: left;
}
@media screen and (max-width: 768px) {
  .ant-list-rtl .ant-list-item-action,
  .ant-list-rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 24px;
    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 22px;
    margin-left: 0;
  }
  .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-mentions {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  width: 100%;
  min-width: 0;
  color: #455560;
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  padding: 0;
  overflow: hidden;
  line-height: 1.5;
  white-space: pre-wrap;
  vertical-align: bottom;
}
.ant-mentions::-webkit-input-placeholder,
.ant-mentions > textarea::-webkit-input-placeholder,
.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-mentions:-ms-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
  text-overflow: ellipsis;
}
.ant-mentions::-ms-input-placeholder,
.ant-mentions > textarea::-ms-input-placeholder,
.ant-pagination-options-quick-jumper input::-ms-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-mentions::placeholder,
.ant-mentions > textarea::placeholder,
.ant-pagination-options-quick-jumper input::placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.ant-mentions:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions:hover {
  border-color: #699dff;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-mentions:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions-focused,
.ant-mentions:focus {
  border-color: #699dff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(62, 121, 247, 0.2);
}
.ant-input-rtl .ant-mentions-focused,
.ant-input-rtl .ant-mentions:focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions-disabled:hover,
.ant-mentions-disabled > textarea:hover,
.ant-mentions[disabled]:hover,
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #edf2f9;
  border-right-width: 1px !important;
}
.ant-mentions-disabled,
.ant-mentions-disabled > textarea,
.ant-mentions[disabled],
.ant-pagination-options-quick-jumper input-disabled {
  color: #d0d4d7;
  background-color: #f7f7f8;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-borderless,
.ant-mentions-borderless-disabled,
.ant-mentions-borderless-focused,
.ant-mentions-borderless:focus,
.ant-mentions-borderless:hover,
.ant-mentions-borderless[disabled],
.ant-pagination-options-quick-jumper input-borderless,
.ant-pagination-options-quick-jumper input-borderless-disabled,
.ant-pagination-options-quick-jumper input-borderless-focused,
.ant-pagination-options-quick-jumper input-borderless:focus,
.ant-pagination-options-quick-jumper input-borderless:hover,
.ant-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}
.ant-mentions-lg,
.ant-pagination-options-quick-jumper input-lg {
  padding: 11px;
  font-size: 16px;
}
.ant-mentions-sm,
.ant-pagination-options-quick-jumper input-sm {
  padding: 6.5px 7px;
}
.ant-mentions-focused {
  border-color: #699dff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(62, 121, 247, 0.2);
}
.ant-input-rtl .ant-mentions-focused,
.ant-input-rtl .ant-pagination-options-quick-jumper input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions-measure,
.ant-mentions > textarea {
  min-height: 38px;
  margin: 0;
  padding: 8.5px 11px;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  -moz-tab-size: inherit;
  tab-size: inherit;
}
.ant-mentions > textarea {
  width: 100%;
  border: 0;
  outline: 0;
  resize: none;
}
.ant-mentions > textarea:-ms-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
  text-overflow: ellipsis;
}
.ant-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}
.ant-mentions-measure > span {
  display: inline-block;
  min-height: 1em;
}
.ant-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: #455560;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 0.625rem;
  outline: 0;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-mentions-dropdown-hidden {
  display: none;
}
.ant-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: 0;
}
.ant-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: #455560;
  font-weight: 400;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-mentions-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}
.ant-mentions-dropdown-menu-item:first-child {
  border-radius: 0.625rem 0.625rem 0 0;
}
.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 0.625rem 0.625rem;
}
.ant-mentions-dropdown-menu-item-disabled {
  color: #d0d4d7;
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-disabled:hover {
  color: #d0d4d7;
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-selected {
  color: #455560;
  font-weight: 600;
  background-color: #fafafa;
}
.ant-mentions-dropdown-menu-item-active {
  background-color: #f5f5f5;
}
.ant-mentions-rtl {
  direction: rtl;
}
.ant-menu-item-danger.ant-menu-item,
.ant-menu-item-danger.ant-menu-item-active,
.ant-menu-item-danger.ant-menu-item-selected,
.ant-menu-item-danger.ant-menu-item-selected > a,
.ant-menu-item-danger.ant-menu-item-selected > a:hover,
.ant-menu-item-danger.ant-menu-item:hover {
  color: #ff6b72;
}
.ant-menu-item-danger.ant-menu-item:active {
  background: #fff4f0;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #fff4f0;
}
.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: #ff6b72;
}
.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: #ff6b72;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #fff;
  background-color: #ff6b72;
}
.ant-menu {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: 0;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu::before {
  display: table;
  content: "";
}
.ant-menu::after {
  display: table;
  display: block;
  clear: both;
  content: "";
}
.ant-menu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  height: 1.5;
  color: #72849a;
  transition: all 0.3s;
}
.ant-menu-horizontal .ant-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item a:hover,
.ant-menu-item > .ant-badge a:hover,
.ant-menu-submenu-selected {
  color: #3e79f7;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: rgba(62, 121, 247, 0.1);
}
.ant-menu-submenu .ant-menu-sub {
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item a,
.ant-menu-item > .ant-badge a {
  color: #455560;
}
.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: "";
}
.ant-menu-item-divider {
  height: 1px;
  overflow: hidden;
  line-height: 0;
  background-color: #edf2f9;
}
.ant-menu-item-active,
.ant-menu-item:hover,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #3e79f7;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon {
  color: #3e79f7;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(62, 121, 247, 0.1);
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th {
  border-right: 1px solid #edf2f9;
}
.ant-menu-vertical-right {
  border-left: 1px solid #edf2f9;
}
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub,
.ant-menu-vertical.ant-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
}
.ant-menu-vertical-left.ant-menu-sub:not([class*="-active"]),
.ant-menu-vertical-right.ant-menu-sub:not([class*="-active"]),
.ant-menu-vertical.ant-menu-sub:not([class*="-active"]) {
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item,
.ant-menu-vertical.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  transition: border-color 0.3s, background 0.3s;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 17px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}
.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-submenu-title .anticon + span {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}
.ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-submenu-title.ant-menu-item-only-child > .anticon,
table tr td.ant-table-selection-column .ant-radio-wrapper,
table tr th.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
.ant-menu > .ant-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  background-color: #edf2f9;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: 0 0;
  border-radius: 0.625rem;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: " ";
}
.ant-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 0.625rem;
}
.ant-menu-submenu-arrow,
.ant-menu-submenu-expand-icon,
.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}
.ant-menu-submenu-arrow,
.ant-menu-submenu-expand-icon {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: #455560;
  transform: translateY(-50%);
}
.ant-menu-submenu-arrow::after,
.ant-menu-submenu-arrow::before {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentColor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
}
.ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}
.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2.5px);
}
.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2.5px);
}
.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2.5px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2.5px);
}
.ant-menu-horizontal > .ant-menu-item a:hover,
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #3e79f7;
}
.ant-menu-horizontal {
  line-height: 45px;
  border: 0;
  border-bottom: 1px solid #edf2f9;
  box-shadow: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: -1px 20px 0;
  padding: 0;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
  color: #00477e;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
}
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}
.ant-menu-horizontal > .ant-menu-item a {
  color: #455560;
}
.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #3e79f7;
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: "\20";
}
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item {
  position: relative;
}
.ant-menu-inline .ant-menu-item::after,
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #3e79f7;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: "";
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-vertical-right .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu-inline .ant-menu-submenu,
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu {
  padding-bottom: 0.02px;
}
.ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-inline > .ant-menu-item,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-item-selected::after,
.ant-menu-inline .ant-menu-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu-inline-collapsed .anticon {
  display: inline-block;
}
.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu-inline-collapsed-tooltip a {
  color: #1a3353;
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-inline,
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right {
  box-shadow: none;
}
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: #d0d4d7 !important;
  background: 0 0;
  border-color: transparent !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
  color: #d0d4d7 !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: #d0d4d7 !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  background: #d0d4d7 !important;
}
.ant-layout-header .ant-menu {
  line-height: inherit;
}
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: #99abb4;
  background: #283142;
}
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: 0 0;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #283142;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #283142;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: #99abb4;
}
.ant-menu-dark .ant-menu-item-selected::after,
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after,
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > span > a:hover,
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-table-small .ant-table-thead > tr > th {
  background-color: transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.1);
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.1);
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-item-disabled > span > a,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  background: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu.ant-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-right: none;
  border-left: 1px solid #edf2f9;
}
.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none;
}
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu {
  transform-origin: top right;
}
.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 10px;
}
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
  margin-left: 0;
}
.ant-menu-submenu-rtl.ant-menu-submenu-popup {
  transform-origin: 100% 0;
}
.ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
}
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateY(-2px);
}
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateY(2px);
}
.ant-menu-rtl.ant-menu-inline .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after {
  right: auto;
  left: 0;
}
.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}
.ant-menu-sub.ant-menu-inline,
.ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
  border: 0;
}
.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0;
}
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 0.625rem;
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #04d182;
}
.ant-message-error .anticon {
  color: #ff6b72;
}
.ant-message-warning .anticon {
  color: #ffc542;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #3e79f7;
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.move-up-leave.move-up-leave-active {
  -webkit-animation-name: MessageMoveOut;
  animation-name: MessageMoveOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}
.ant-message-rtl,
.ant-message-rtl span {
  direction: rtl;
}
.ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-modal {
  box-sizing: border-box;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding: 0 0 24px;
}
.ant-modal.zoom-appear,
.ant-modal.zoom-enter {
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  z-index: 1000;
}
.ant-modal-title {
  margin: 0;
  color: #1a3353;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 0.625rem;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: #72849a;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: 0 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: #455560;
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: #455560;
  background: #fff;
  border-bottom: 1px solid #edf2f9;
  border-radius: 0.625rem 0.625rem 0 0;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: 0 0;
  border-top: 1px solid #edf2f9;
  border-radius: 0 0 0.625rem 0.625rem;
}
.ant-modal-confirm .ant-modal-confirm-btns button + button,
.ant-modal-footer button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: "";
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    flex: 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: "";
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  display: block;
  clear: both;
  content: "";
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: #1a3353;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: #455560;
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #ff6b72;
}
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon {
  color: #ffc542;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #3e79f7;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #04d182;
}
.ant-modal-wrap-rtl {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-close {
  right: initial;
  left: 0;
}
.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns button + button,
.ant-modal-wrap-rtl .ant-modal-footer button + button {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  float: left;
}
.ant-modal-wrap-rtl.ant-modal-centered .ant-modal,
.ant-table-wrapper-rtl .ant-table table,
.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: right;
}
.ant-notification {
  box-sizing: border-box;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: fixed;
  z-index: 1010;
  margin: 0 24px 0 0;
}
.ant-notification-bottomLeft,
.ant-notification-topLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active {
  -webkit-animation-name: NotificationLeftFadeIn;
  animation-name: NotificationLeftFadeIn;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-hook-holder,
.ant-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  overflow: hidden;
  word-wrap: break-word;
  background: #fff;
  border-radius: 0.625rem;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-notification-bottomLeft .ant-notification-hook-holder,
.ant-notification-bottomLeft .ant-notification-notice,
.ant-notification-topLeft .ant-notification-hook-holder,
.ant-notification-topLeft .ant-notification-notice {
  margin-right: auto;
  margin-left: 0;
}
.ant-notification-hook-holder > .ant-notification-notice {
  margin-bottom: 0;
  box-shadow: none;
}
.ant-notification-notice {
  padding: 16px 24px;
  line-height: 1.5;
}
.ant-notification-notice-message {
  margin-bottom: 8px;
  color: #1a3353;
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: "";
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
  color: #04d182;
}
.anticon.ant-notification-notice-icon-info {
  color: #3e79f7;
}
.anticon.ant-notification-notice-icon-warning {
  color: #ffc542;
}
.anticon.ant-notification-notice-icon-error {
  color: #ff6b72;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: #72849a;
  outline: 0;
}
.ant-notification-notice-close:hover {
  color: #444f5c;
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect,
.ant-notification-fade-appear,
.ant-notification-fade-enter,
.ant-notification-fade-leave {
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.ant-notification .notification-fade-effect {
  -webkit-animation-duration: 0.24s;
  animation-duration: 0.24s;
}
.ant-notification-fade-appear,
.ant-notification-fade-enter,
.ant-notification-fade-leave {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.ant-notification-fade-appear,
.ant-notification-fade-enter {
  opacity: 0;
  -webkit-animation-duration: 0.24s;
  animation-duration: 0.24s;
}
.ant-notification-fade-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}
.ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  -webkit-animation-name: NotificationFadeIn;
  animation-name: NotificationFadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  -webkit-animation-name: NotificationFadeOut;
  animation-name: NotificationFadeOut;
}
.ant-notification-rtl {
  direction: rtl;
}
.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 0;
  padding-left: 24px;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description,
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-close {
  right: auto;
  left: 22px;
}
.ant-notification-rtl .ant-notification-notice-btn {
  float: left;
}
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}
.ant-page-header-ghost {
  background-color: inherit;
}
.ant-page-header.has-breadcrumb {
  padding-top: 12px;
}
.ant-page-header.has-footer {
  padding-bottom: 0;
}
.ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.ant-page-header-back-button {
  text-decoration: none;
  outline: 0;
  transition: color 0.3s;
  color: #000;
  cursor: pointer;
}
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: #699dff;
}
.ant-page-header-back-button:active {
  color: #2a59d1;
}
.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}
.ant-page-header-heading {
  display: flex;
  justify-content: space-between;
}
.ant-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}
.ant-page-header-heading-sub-title,
.ant-page-header-heading-title {
  margin-right: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading-title {
  margin-bottom: 0;
  color: #1a3353;
  font-weight: 600;
  font-size: 17px;
  line-height: 32px;
}
.ant-page-header-heading .ant-avatar {
  margin-right: 12px;
}
.ant-page-header-heading-sub-title {
  color: #72849a;
  font-size: 14px;
  line-height: 1.5;
}
.ant-page-header-heading-extra {
  margin: 4px 0;
  white-space: nowrap;
}
.ant-page-header-heading-extra > * {
  margin-left: 12px;
  white-space: unset;
}
.ant-page-header-heading-extra > :first-child {
  margin-left: 0;
}
.ant-page-header-content {
  padding-top: 12px;
}
.ant-page-header-footer,
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav,
.ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column {
  margin: 0;
}
.ant-page-header-footer .ant-tabs .ant-tabs-tab {
  padding: 8px 0;
  font-size: 16px;
}
.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: wrap;
}
.ant-page-header-rtl,
.ant-pagination-options-quick-jumper input-rtl {
  direction: rtl;
}
.ant-page-header-rtl .ant-page-header-back {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-page-header-rtl .ant-page-header-heading .ant-avatar,
.ant-page-header-rtl .ant-page-header-heading-title {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-sub-title {
  float: right;
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav,
.ant-page-header-rtl .ant-page-header-heading-tags {
  float: right;
}
.ant-page-header-rtl .ant-page-header-heading-extra {
  float: left;
}
.ant-page-header-rtl .ant-page-header-heading-extra > * {
  margin-right: 12px;
  margin-left: 0;
}
.ant-page-header-rtl .ant-page-header-heading-extra > :first-child {
  margin-right: 0;
}
.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: " ";
}
.ant-pagination-item,
.ant-pagination-total-text {
  display: inline-block;
  height: 30px;
  margin-right: 8px;
  vertical-align: middle;
}
.ant-pagination-total-text {
  line-height: 28px;
}
.ant-pagination-item {
  min-width: 30px;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  text-align: center;
  list-style: none;
  background-color: #fff;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: #455560;
  transition: none;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  transition: all 0.3s;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #3e79f7;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #3e79f7;
  border-color: #3e79f7;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #699dff;
}
.ant-pagination-jump-next,
.ant-pagination-jump-prev {
  outline: 0;
}
.ant-pagination-jump-next .ant-pagination-item-container,
.ant-pagination-jump-prev .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #3e79f7;
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: #d0d4d7;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-prev {
  margin-right: 8px;
}
.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-next,
.ant-pagination-prev {
  display: inline-block;
  min-width: 30px;
  height: 30px;
  color: #455560;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 0.625rem;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-pagination-next,
.ant-pagination-prev {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}
.ant-pagination-next button,
.ant-pagination-prev button {
  color: #455560;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-pagination-next:hover button,
.ant-pagination-prev:hover button {
  border-color: #699dff;
}
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
  outline: 0;
  transition: all 0.3s;
}
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link {
  color: #3e79f7;
  border-color: #3e79f7;
}
.ant-pagination-disabled,
.ant-pagination-disabled:focus,
.ant-pagination-disabled:hover {
  cursor: not-allowed;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: #d0d4d7;
  border-color: #edf2f9;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .ant-pagination-options,
  .ant-pagination-options ::-ms-backdrop {
    vertical-align: top;
  }
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 40px;
  margin-left: 8px;
  line-height: 40px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  min-width: 0;
  padding: 8.5px 11px;
  color: #455560;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
  transition: all 0.3s;
  width: 50px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: rgba(114, 132, 154, 0.4);
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #699dff;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input-focused,
.ant-pagination-options-quick-jumper input:focus {
  border-color: #699dff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(62, 121, 247, 0.2);
}
.ant-input-rtl .ant-pagination-options-quick-jumper input-focused,
.ant-input-rtl .ant-pagination-options-quick-jumper input:focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: #d0d4d7;
  background-color: #f7f7f8;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #edf2f9;
  border-right-width: 1px !important;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 40px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-pagination-simple .ant-pagination-next,
.ant-pagination-simple .ant-pagination-prev {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after,
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.mini .ant-pagination-simple-pager,
.ant-pagination.mini .ant-pagination-total-text {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
  outline: 0;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #3e79f7;
}
.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  color: #d0d4d7;
  background: #f7f7f8;
  border-color: #edf2f9;
  cursor: not-allowed;
}
.ant-pagination.mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: 0 0;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-next,
.ant-pagination.mini .ant-pagination-prev {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link {
  background: 0 0;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-jump-next,
.ant-pagination.mini .ant-pagination-jump-prev {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.mini .ant-pagination-options-size-changer {
  top: 0;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  padding: 6.5px 7px;
  width: 44px;
}
.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f7f7f8;
  border-color: #edf2f9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: #d0d4d7;
  background: 0 0;
  border: 0;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #dcdce0;
  border-color: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: #fff;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  color: #d0d4d7;
  background: #f7f7f8;
  border-color: #edf2f9;
  cursor: not-allowed;
}
.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link,
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: 0 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
  opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
  opacity: 1;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: #d0d4d7;
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}
.ant-pagination-rtl .ant-pagination-item,
.ant-pagination-rtl .ant-pagination-jump-next,
.ant-pagination-rtl .ant-pagination-jump-prev,
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select,
.ant-pagination-rtl .ant-pagination-prev,
.ant-pagination-rtl .ant-pagination-total-text {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-slash {
  margin: 0 5px 0 10px;
}
.ant-pagination-rtl .ant-pagination-options {
  margin-right: 16px;
  margin-left: 0;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
  margin-left: 0;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager,
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
  margin-right: 2px;
  margin-left: 0;
}
.ant-popconfirm {
  z-index: 1060;
}
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: 400;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: "";
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 10px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightBottom,
.ant-popover-placement-rightTop {
  padding-left: 10px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 10px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftBottom,
.ant-popover-placement-leftTop {
  padding-right: 10px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.625rem;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover-inner {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: #1a3353;
  font-weight: 500;
  border-bottom: 1px solid #edf2f9;
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: #455560;
}
.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: #455560;
  font-size: 14px;
}
.ant-popover-message > .anticon {
  position: absolute;
  top: 7.5px;
  color: #ffc542;
  font-size: 14px;
}
.ant-popover-message-title {
  padding-left: 22px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: 0 0;
  border-style: solid;
  border-width: 4.24264069px;
  transform: rotate(45deg);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 6.2px;
  border-color: transparent #fff #fff transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  left: 6px;
  border-color: transparent transparent #fff #fff;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  border-color: #fff transparent transparent #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  right: 6px;
  border-color: #fff #fff transparent transparent;
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-rtl {
  direction: rtl;
  text-align: right;
}
.ant-popover-rtl .ant-popover-message-title {
  padding-right: 22px;
  padding-left: 16px;
}
.ant-popover-rtl .ant-popover-buttons {
  text-align: left;
}
.ant-popover-rtl .ant-popover-buttons button {
  margin-right: 8px;
  margin-left: 0;
}
.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-steps,
.ant-select-arrow svg,
.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
}
.ant-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ant-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all 0.3s;
}
.ant-progress-steps-item-active {
  background: #3e79f7;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.ant-progress-circle-path {
  -webkit-animation: ant-progress-appear 0.3s;
  animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #3e79f7;
}
.ant-progress-bg,
.ant-progress-success-bg {
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-bg {
  position: relative;
  background-color: #3e79f7;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #04d182;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: #72849a;
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .anticon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: "";
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #ff6b72;
}
.ant-progress-status-exception .ant-progress-text {
  color: #ff6b72;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #ff6b72;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #04d182;
}
.ant-progress-status-success .ant-progress-text {
  color: #04d182;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #04d182;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #ff6b72;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #04d182;
}
.ant-progress-rtl {
  direction: rtl;
}
.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px);
}
.ant-progress-rtl .ant-progress-success-bg {
  right: 0;
  left: auto;
}
.ant-progress-rtl.ant-progress-line .ant-progress-text,
.ant-progress-rtl.ant-progress-steps .ant-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right;
}
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  font-size: 0;
  line-height: unset;
}
.ant-radio-group .ant-badge-count {
  z-index: 1;
}
.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
  border-left: none;
}
.ant-radio,
.ant-radio-wrapper {
  box-sizing: border-box;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  margin: 0 8px 0 0;
  white-space: nowrap;
  cursor: pointer;
}
.ant-radio {
  margin: 0;
  top: 0;
  line-height: 1;
  vertical-align: sub;
  outline: 0;
}
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
  border-color: #3e79f7;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(62, 121, 247, 0.08);
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #3e79f7;
  border-radius: 50%;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
  animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  content: "";
}
.ant-radio-wrapper:hover .ant-radio::after,
.ant-radio:hover::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-color: #edf2f9;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 10px;
  height: 10px;
  background-color: #3e79f7;
  border-top: 0;
  border-left: 0;
  border-radius: 10px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: " ";
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #3e79f7;
}
.ant-radio-checked .ant-radio-inner::after {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f7f7f8;
  border-color: #edf2f9 !important;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled,
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: #d0d4d7;
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 40px;
  margin: 0;
  padding: 0 15px;
  color: #455560;
  font-size: 14px;
  line-height: 38px;
  background: #fff;
  border: 1px solid #edf2f9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper a {
  color: #455560;
}
.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 48px;
  font-size: 16px;
  line-height: 46px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 36px;
  padding: 0 7px;
  line-height: 34px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: #edf2f9;
  transition: background-color 0.3s;
  content: "";
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #edf2f9;
  border-radius: 0.625rem 0 0 0.625rem;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 0.625rem 0.625rem 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 0.625rem;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #3e79f7;
}
.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px rgba(62, 121, 247, 0.08);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type="checkbox"],
.ant-radio-button-wrapper input[type="radio"] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #3e79f7;
  background: #fff;
  border-color: #3e79f7;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #3e79f7;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #3e79f7;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #699dff;
  border-color: #699dff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #699dff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #2a59d1;
  border-color: #2a59d1;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #2a59d1;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(62, 121, 247, 0.08);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #3e79f7;
  border-color: #3e79f7;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #699dff;
  border-color: #699dff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #2a59d1;
  border-color: #2a59d1;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(62, 121, 247, 0.08);
}
.ant-radio-button-wrapper-disabled {
  color: #d0d4d7;
  background-color: #f7f7f8;
  border-color: #edf2f9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: #d0d4d7;
  background-color: #f7f7f8;
  border-color: #edf2f9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #edf2f9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #d0d4d7;
  background-color: #e6e6e6;
  border-color: #edf2f9;
  box-shadow: none;
}
@supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
  .ant-radio {
    vertical-align: text-bottom;
  }
}
.ant-radio-group.ant-radio-group-rtl {
  direction: rtl;
}
.ant-radio-wrapper.ant-radio-wrapper-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
  border-right-width: 0;
  border-left-width: 1px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
  right: -1px;
  left: 0;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #edf2f9;
  border-radius: 0 0.625rem 0.625rem 0;
}
.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right-color: #699dff;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
  border-radius: 0.625rem 0 0 0.625rem;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #edf2f9;
}
.ant-rate {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #ffc542;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: 0;
}
.ant-rate-disabled .ant-rate-star {
  cursor: default;
}
.ant-rate-disabled .ant-rate-star:hover {
  transform: scale(1);
}
.ant-rate-star {
  position: relative;
  display: inline-block;
  color: inherit;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-rate-star:not(:last-child) {
  margin-right: 8px;
}
.ant-rate-star > div:focus,
.ant-rate-star > div:hover {
  transform: scale(1.1);
}
.ant-rate-star-first,
.ant-rate-star-second {
  color: #edf2f9;
  transition: all 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}
.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-full .ant-rate-star-second,
.ant-rate-star-half .ant-rate-star-first {
  color: inherit;
}
.ant-rate-text {
  display: inline-block;
  margin: 0 8px;
  font-size: 14px;
}
.ant-rate-rtl {
  direction: rtl;
}
.ant-rate-rtl .ant-rate-star:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-rate-rtl .ant-rate-star-first {
  right: 0;
  left: auto;
}
.ant-result {
  padding: 48px 32px;
}
.ant-result-success .ant-result-icon > .anticon {
  color: #04d182;
}
.ant-result-error .ant-result-icon > .anticon {
  color: #ff6b72;
}
.ant-result-info .ant-result-icon > .anticon {
  color: #3e79f7;
}
.ant-result-warning .ant-result-icon > .anticon {
  color: #ffc542;
}
.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}
.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;
}
.ant-result-icon > .anticon {
  font-size: 72px;
}
.ant-result-title {
  color: #1a3353;
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.ant-result-subtitle {
  color: #72849a;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
.ant-result-extra {
  margin: 24px 0 0;
  text-align: center;
}
.ant-result-extra > * {
  margin-right: 8px;
}
.ant-result-extra > :last-child {
  margin-right: 0;
}
.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}
.ant-result-rtl {
  direction: rtl;
}
.ant-result-rtl .ant-result-extra > * {
  margin-right: 0;
  margin-left: 8px;
}
.ant-result-rtl .ant-result-extra > :last-child {
  margin-left: 0;
}
.ant-select-single .ant-select-selector {
  display: flex;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 38px;
  transition: all 0.3s;
}
@supports (-moz-appearance: meterbar) {
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 38px;
  }
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder,
span.ant-transfer-list-search-action {
  pointer-events: none;
}
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after,
.ant-select-single .ant-select-selector::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: "\a0";
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: rgba(114, 132, 154, 0.4);
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 40px;
  padding: 0 11px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 38px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 38px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after,
.ant-select-single.ant-select-customize-input .ant-select-selector::after {
  display: none;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  position: static;
  width: 100%;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 38px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 38px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 36px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 34px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 34px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  right: 7px;
  left: 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 28px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 21px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
}
.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 2.5px 8.5px;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
}
.ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: #f7f7f8;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 5px 0;
  line-height: 23px;
  content: "\a0";
}
.ant-select-multiple.ant-select-allow-clear .ant-select-selector,
.ant-select-multiple.ant-select-show-arrow .ant-select-selector {
  padding-right: 24px;
}
.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 23px;
  margin-top: 5px;
  margin-right: 8.5px;
  margin-bottom: 5px;
  padding: 0 4px 0 8px;
  line-height: 21px;
  background: #f5f5f5;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  border-color: #edf2f9;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.ant-select-multiple .ant-select-selection-item-remove {
  font-style: normal;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: #72849a;
  font-weight: 700;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}
.ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1;
}
.ant-select-multiple .ant-select-selection-item-remove::before {
  display: none;
}
.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: -0.2em;
}
.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: #455560;
}
.ant-select-multiple .ant-select-selection-search {
  position: relative;
  margin-left: 1.25px;
}
.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  line-height: 1.5;
  transition: all 0.3s;
}
.ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}
.ant-select-multiple .ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
}
.ant-select-multiple .ant-select-selection-search:first-child > .ant-select-selection-search-input {
  margin-left: 6.5px !important;
}
.ant-select-multiple .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 31px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 33.5px;
  line-height: 33.5px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 31px;
  line-height: 29px;
}
.ant-select-multiple.ant-select-sm .ant-select-selector::after {
  line-height: 19px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 19px;
  line-height: 17px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 21.5px;
  line-height: 21.5px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 19px;
  line-height: 17px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search:first-child .ant-select-selection-search-input {
  margin-left: 3px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 32px;
}
.ant-select-disabled .ant-select-selection-item-remove {
  display: none;
}
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #699dff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(62, 121, 247, 0.2);
}
.ant-input-rtl .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-rtl .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #d0d4d7;
  background: #f7f7f8;
  cursor: not-allowed;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: 0 0;
  border: 0;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  -webkit-appearance: none;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #00477e !important;
  border-right-width: 1px !important;
}
.ant-select-selection-item {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-item,
  .ant-select-selection-item ::-ms-backdrop {
    flex: auto;
  }
}
.ant-select-selection-placeholder {
  flex: 1;
  overflow: hidden;
  color: rgba(114, 132, 154, 0.4);
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-placeholder,
  .ant-select-selection-placeholder ::-ms-backdrop {
    flex: auto;
  }
}
.ant-select-arrow {
  display: inline-block;
  font-style: normal;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 53%;
  right: 11px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: #d0d4d7;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon,
.ant-select-clear::before {
  display: block;
}
.ant-select-arrow .anticon {
  vertical-align: top;
  transition: transform 0.3s;
}
.ant-select-arrow .anticon > svg {
  vertical-align: top;
}
.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: auto;
}
.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
}
.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: #d0d4d7;
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-clear:hover {
  color: #72849a;
}
.ant-select:hover .ant-select-clear {
  opacity: 1;
}
.ant-select-dropdown {
  margin: 0;
  color: #455560;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 0.625rem;
  outline: 0;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
}
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-empty {
  color: #d0d4d7;
}
.ant-select-item,
.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 8px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: #d0d4d7;
}
.ant-select-item {
  color: #455560;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-select-item-group {
  color: #72849a;
  font-size: 12px;
  cursor: default;
}
.ant-select-item-option {
  display: flex;
}
.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-item-option-state {
  flex: none;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #3e79f7;
  font-weight: 600;
  background-color: transparent;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #3e79f7;
}
.ant-select-item-option-disabled {
  color: #d0d4d7;
  cursor: not-allowed;
}
.ant-select-item-option-grouped {
  padding-left: 24px;
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-borderless .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-select-dropdown-rtl,
.ant-select-rtl {
  direction: rtl;
}
.ant-select-rtl .ant-select-arrow,
.ant-select-rtl .ant-select-clear {
  right: initial;
  left: 11px;
}
.ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px;
}
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector {
  padding-right: 8.5px;
  padding-left: 24px;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  margin-right: 0;
  margin-left: 8.5px;
  padding: 0 8px 0 4px;
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-search {
  margin-right: 1.25px;
  margin-left: 8.5px;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  right: 0;
  left: auto;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-search:first-child > .ant-select-selection-search-input {
  margin-right: 5px !important;
  margin-left: 0 !important;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  right: 11px;
  left: auto;
}
.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 7px;
}
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 11px;
  left: 25px;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
}
.ant-select-rtl.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 0;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px;
}
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle,
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle,
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  margin-top: 16px;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 4px;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph,
.ant-table.ant-table-middle .ant-table-thead th.ant-table-column-has-sorters,
.ant-table.ant-table-small .ant-table-thead th.ant-table-column-has-sorters {
  padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 4px;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title {
  border-radius: 100px;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton.ant-skeleton-active .ant-skeleton-button,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-image,
.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton-element {
  display: inline-block;
  width: auto;
}
.ant-skeleton-element .ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 0.625rem;
  width: 80px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  width: 40px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 40px;
}
.ant-skeleton-element .ant-skeleton-button-lg {
  width: 96px;
  height: 48px;
  line-height: 48px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  width: 48px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 48px;
}
.ant-skeleton-element .ant-skeleton-button-sm {
  width: 72px;
  height: 36px;
  line-height: 36px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  width: 36px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 36px;
}
.ant-skeleton-element .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle,
.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle,
.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle,
.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle,
.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}
.ant-skeleton-element .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-input-lg {
  width: 100%;
  height: 48px;
  line-height: 48px;
}
.ant-skeleton-element .ant-skeleton-input-sm {
  width: 100%;
  height: 36px;
  line-height: 36px;
}
.ant-skeleton-element .ant-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.ant-skeleton-element .ant-skeleton-image-path {
  fill: #bfbfbf;
}
.ant-skeleton-element .ant-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.ant-skeleton-rtl {
  direction: rtl;
}
.ant-skeleton-rtl .ant-skeleton-header {
  padding-right: 0;
  padding-left: 16px;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title {
  -webkit-animation-name: ant-skeleton-loading-rtl;
  animation-name: ant-skeleton-loading-rtl;
}
.ant-slider {
  box-sizing: border-box;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  height: 12px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-top: -6px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
}
.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
  margin-right: -5px;
  margin-left: 0;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
  right: 12px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
  right: 4px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
  right: 2px;
  left: auto;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail,
.ant-slider-track {
  position: absolute;
  height: 4px;
  border-radius: 0.625rem;
  transition: background-color 0.3s;
}
.ant-slider-rail {
  width: 100%;
  background-color: #f5f5f5;
}
.ant-slider-track {
  background-color: rgba(62, 121, 247, 0.8);
}
.ant-slider-handle {
  position: absolute;
  width: 18px;
  height: 18px;
  margin-top: -7px;
  background-color: #fff;
  border: solid 2px rgba(62, 121, 247, 0.8);
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
  border-color: #6594f9;
  box-shadow: 0 0 0 5px rgba(62, 121, 247, 0.12);
}
.ant-slider-handle:focus {
  border-color: #6594f9;
  outline: 0;
  box-shadow: 0 0 0 5px rgba(62, 121, 247, 0.12);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #3e79f7;
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #3e79f7;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open),
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon {
  border-color: #3e79f7;
}
.ant-slider-mark {
  position: absolute;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: #72849a;
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-slider-mark-text-active {
  color: #455560;
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: 0 0;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #fff;
  border: 2px solid #edf2f9;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot:first-child {
  margin-left: -4px;
}
.ant-slider-dot:last-child {
  margin-left: -4px;
}
.ant-slider-dot-active {
  border-color: #9fbcfb;
}
.ant-slider-disabled .ant-slider-track {
  background-color: #d0d4d7 !important;
}
.ant-slider-disabled,
.ant-slider-disabled .ant-slider-handle {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-dot,
.ant-slider-disabled .ant-slider-handle {
  background-color: #fff;
  border-color: #d0d4d7 !important;
  box-shadow: none;
}
.ant-slider-disabled .ant-slider-dot,
.ant-slider-disabled .ant-slider-mark-text {
  cursor: not-allowed !important;
}
.ant-slider-rtl {
  direction: rtl;
}
.ant-slider-rtl .ant-slider-mark {
  right: 0;
  left: auto;
}
.ant-slider-rtl .ant-slider-dot {
  margin-right: -4px;
  margin-left: 0;
}
.ant-slider-rtl .ant-slider-dot:first-child {
  margin-right: -4px;
  margin-left: 0;
}
.ant-slider-rtl .ant-slider-dot:last-child {
  margin-right: -4px;
  margin-left: 0;
}
.ant-space {
  display: inline-flex;
}
.ant-space-vertical {
  flex-direction: column;
}
.ant-space-align-center {
  align-items: center;
}
.ant-space-align-start {
  align-items: flex-start;
}
.ant-space-align-end {
  align-items: flex-end;
}
.ant-space-align-baseline {
  align-items: baseline;
}
.ant-space-item:empty {
  display: none;
}
.ant-space-rtl {
  direction: rtl;
}
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  display: none;
  color: #3e79f7;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: "";
  pointer-events: none;
}
.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: #72849a;
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #3e79f7;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  -webkit-animation: antSpinMove 1s infinite linear alternate;
  animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  transform: rotate(45deg);
  -webkit-animation: antRotate 1.2s infinite linear;
  animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
.ant-spin-rtl {
  direction: rtl;
}
.ant-spin-rtl .ant-spin-dot-spin {
  transform: rotate(-45deg);
  -webkit-animation-name: antRotateRtl;
  animation-name: antRotateRtl;
}
.ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: #72849a;
  font-size: 14px;
}
.ant-statistic-content {
  color: #1a3353;
  font-size: 24px;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}
.ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
}
.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}
.ant-statistic-content-prefix {
  margin-right: 4px;
}
.ant-statistic-content-suffix {
  margin-left: 4px;
}
.ant-statistic-rtl {
  direction: rtl;
}
.ant-statistic-rtl .ant-statistic-content-prefix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-statistic-rtl .ant-statistic-content-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: initial;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  vertical-align: top;
}
.ant-steps-item-container {
  outline: 0;
}
.ant-steps-item:last-child {
  flex: none;
}
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail {
  display: none;
}
.ant-steps-item-content,
.ant-steps-item-icon {
  display: inline-block;
  vertical-align: top;
}
.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  line-height: 32px;
  text-align: center;
  border: 1px solid #d0d4d7;
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -1px;
  color: #3e79f7;
  line-height: 1;
}
.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #edf2f9;
  border-radius: 1px;
  transition: background 0.3s;
  content: "";
}
.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: #455560;
  font-size: 16px;
  line-height: 32px;
}
.ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #edf2f9;
  content: "";
}
.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  font-weight: 400;
}
.ant-steps-item-description,
.ant-steps-item-subtitle {
  color: #72849a;
  font-size: 14px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
}
.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: #d0d4d7;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #d0d4d7;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #72849a;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #edf2f9;
}
.ant-steps-item-finish .ant-steps-item-icon,
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #3e79f7;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #3e79f7;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #3e79f7;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #1a3353;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #455560;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #3e79f7;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #3e79f7;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #455560;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #3e79f7;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #72849a;
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #ff6b72;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ff6b72;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon,
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description,
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #ff6b72;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #edf2f9;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #ff6b72;
}
.ant-steps-item-disabled {
  cursor: not-allowed;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] {
  cursor: pointer;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] .ant-steps-item-icon .ant-steps-icon,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] .ant-steps-item-title {
  transition: color 0.3s;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon .ant-steps-icon,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-title {
  color: #3e79f7;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 16px;
  white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
  height: auto;
  background: 0 0;
  border: 0;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
  top: 0;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #3e79f7;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
  background: 0 0;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
.ant-steps-small .ant-steps-item-description {
  color: #72849a;
  font-size: 14px;
}
.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: 0 0;
  border: 0;
  border-radius: 0;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}
.ant-steps-vertical {
  display: flex;
  flex-direction: column;
}
.ant-steps-vertical > .ant-steps-item {
  display: block;
  flex: 1 0 auto;
  padding-left: 0;
  overflow: visible;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
  line-height: 32px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}
.ant-steps-label-vertical .ant-steps-item-title::after,
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}
.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
}
.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
  padding-left: 0;
}
.ant-steps-label-vertical .ant-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.5;
}
.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px;
}
.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: 0 0;
  border: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: "";
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
  line-height: 10px;
  background: 0 0;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 8px;
  margin-left: 0;
  background: 0 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 2px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
  width: inherit;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
  left: -2px;
}
.ant-steps-navigation {
  padding-top: 12px;
}
.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}
.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center;
}
.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  transition: opacity 0.3s;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role="button"] {
  cursor: pointer;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role="button"]:hover {
  opacity: 0.85;
}
.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1;
}
.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  content: "";
}
.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 2px;
  background-color: #3e79f7;
  transition: width 0.3s, left 0.3s;
  transition-timing-function: ease-out;
  content: "";
}
.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  left: 0;
  width: 100%;
}
@media (max-width: 480px) {
  .ant-steps-navigation > .ant-steps-item {
    margin-right: 0 !important;
  }
  .ant-steps-navigation > .ant-steps-item::before {
    display: none;
  }
  .ant-steps-navigation > .ant-steps-item.ant-steps-item-active::before {
    top: 0;
    right: 0;
    left: unset;
    display: block;
    width: 3px;
    height: calc(100% - 24px);
  }
  .ant-steps-navigation > .ant-steps-item::after {
    position: relative;
    top: -2px;
    left: 50%;
    display: block;
    width: 8px;
    height: 8px;
    margin-bottom: 8px;
    text-align: center;
    transform: rotate(135deg);
  }
  .ant-steps-navigation > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    visibility: hidden;
  }
}
.ant-steps-rtl {
  direction: rtl;
}
.ant-steps.ant-steps-rtl .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-steps-rtl .ant-steps-item-tail {
  right: 0;
  left: auto;
}
.ant-steps-rtl .ant-steps-item-title {
  padding-right: 0;
  padding-left: 16px;
}
.ant-steps-rtl .ant-steps-item-title::after {
  right: 100%;
  left: auto;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 16px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  right: 0.5px;
  left: auto;
}
.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-right: -12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
  margin-right: -16px;
  margin-left: 0;
  text-align: right;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item::after {
  right: 100%;
  left: auto;
  margin-right: -2px;
  margin-left: 0;
  transform: rotate(225deg);
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 12px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
  padding-right: 0;
  padding-left: 12px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 16px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  right: 12px;
  left: auto;
}
.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 70px 0 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  margin-right: 12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-right: 67px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  right: -26px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: -9px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 0;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  right: -2px;
  left: auto;
}
.ant-steps-with-progress .ant-steps-item {
  padding-top: 4px;
}
.ant-steps-with-progress .ant-steps-item .ant-steps-item-tail {
  top: 4px !important;
}
.ant-steps-with-progress .ant-steps-item-icon,
.ant-switch {
  position: relative;
}
.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}
.ant-switch {
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: #d0d4d7;
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(208, 212, 215, 0.1);
}
.ant-switch-checked:focus {
  box-shadow: 0 0 0 2px rgba(62, 121, 247, 0.2);
}
.ant-switch:focus:hover {
  box-shadow: none;
}
.ant-switch-checked {
  background-color: #3e79f7;
}
.ant-switch-disabled,
.ant-switch-loading {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-disabled *,
.ant-switch-loading * {
  box-shadow: none;
  cursor: not-allowed;
}
.ant-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #fff;
  font-size: 12px;
  transition: margin 0.2s;
}
.ant-switch-checked .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-handle,
.ant-switch-handle::before {
  position: absolute;
  transition: all 0.2s ease-in-out;
}
.ant-switch-handle {
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
}
.ant-switch-handle::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  content: "";
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px);
}
.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before,
.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-loading-icon {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.65);
  vertical-align: top;
}
.ant-switch-checked .ant-switch-loading-icon {
  color: #3e79f7;
}
.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}
.ant-switch-small .ant-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}
.ant-switch-small .ant-switch-handle {
  width: 12px;
  height: 12px;
}
.ant-switch-small .ant-switch-loading-icon {
  top: 1.5px;
  font-size: 9px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin: 0 18px 0 5px;
}
.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 12px - 2px);
}
.ant-switch-rtl {
  direction: rtl;
}
.ant-switch-rtl .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-rtl .ant-switch-handle {
  right: 2px;
  left: auto;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
  margin: 0 7px 0 25px;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 18px - 2px);
}
.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 12px - 2px);
}
.ant-table.ant-table-middle,
.ant-table.ant-table-small {
  font-size: 14px;
}
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle tfoot > tr > td,
.ant-table.ant-table-middle tfoot > tr > th {
  padding: 12px 8px;
}
.ant-table.ant-table-middle .ant-table-expanded-row-fixed,
.ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column {
  margin: -12px -8px;
}
.ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column-title {
  padding: 12px 2.3em 12px 8px;
}
.ant-table.ant-table-middle .ant-table-thead .ant-table-column-sorters {
  padding: 12px 8px;
}
.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -12px -8px -12px 25px;
}
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small tfoot > tr > td,
.ant-table.ant-table-small tfoot > tr > th {
  padding: 8px;
}
.ant-table.ant-table-small .ant-table-expanded-row-fixed,
.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column {
  margin: -8px;
}
.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column-title {
  padding: 8px 2.3em 8px 8px;
}
.ant-table.ant-table-small .ant-table-thead .ant-table-column-sorters {
  padding: 8px;
}
.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -8px -8px -8px 25px;
}
.ant-table-small .ant-table-selection-column {
  width: 46px;
  min-width: 46px;
}
.ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid #edf2f9;
  border-bottom: 0;
}
.ant-table.ant-table-bordered > .ant-table-container {
  border: 1px solid #edf2f9;
  border-right: 0;
  border-bottom: 0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid #edf2f9;
  content: "";
}
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -12px -9px;
}
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -8px -9px;
}
.ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #edf2f9;
  border-top: 0;
}
.ant-table-cell .ant-table-container:first-child {
  border-top: 0;
}
.ant-table-cell-scrollbar {
  box-shadow: 0 1px 0 1px transparent;
}
.ant-table-wrapper {
  clear: both;
  max-width: 100%;
}
.ant-table-wrapper::before {
  display: table;
  content: "";
}
.ant-table-wrapper::after {
  display: table;
  display: block;
  clear: both;
  content: "";
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  font-size: 14px;
  background: #fff;
  border-radius: 0.625rem;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 0.625rem 0.625rem 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table tfoot > tr > td,
.ant-table tfoot > tr > th,
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  position: relative;
  padding: 16px;
  overflow-wrap: break-word;
}
.ant-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
  overflow: visible;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table-title {
  padding: 16px;
}
.ant-table-footer {
  padding: 16px;
  color: #1a3353;
  background: #fafafa;
}
.ant-table-thead > tr > th {
  color: #1a3353;
  text-align: left;
  background: 0 0;
  border-bottom: 1px solid #edf2f9;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan]:not([colspan="1"]),
.ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan="1"]) {
  text-align: center;
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #edf2f9;
  transition: background 0.3s;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
  margin: -16px -16px -16px 33px;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
  border-radius: 0;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #f0f7ff;
  border-color: rgba(0, 0, 0, 0.03);
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #e6f2ff;
}
.ant-table tfoot > tr > td,
.ant-table tfoot > tr > th {
  border-bottom: 1px solid #edf2f9;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 0;
}
.ant-table-pagination {
  display: flex;
}
.ant-table-pagination-left {
  justify-content: flex-start;
}
.ant-table-pagination-center {
  justify-content: center;
}
.ant-table-pagination-right {
  justify-content: flex-end;
}
.ant-table-thead th.ant-table-column-has-sorters {
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
  background: 0 0;
}
.ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5;
}
.ant-table-column-sorters-with-tooltip {
  display: inline-block;
  width: 100%;
}
.ant-table-column-sorters {
  display: inline-flex;
  align-items: center;
  padding: 16px;
}
.ant-table-column-sorter {
  margin-top: 0.15em;
  margin-bottom: -0.15em;
  margin-left: 8px;
  color: #00000040;
}
.ant-table-column-sorter-full {
  margin-top: -0.2em;
  margin-bottom: 0;
}
.ant-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.ant-table-column-sorter-down,
.ant-table-column-sorter-up {
  font-size: 11px;
}
.ant-table-column-sorter-down.active,
.ant-table-column-sorter-up.active {
  color: #3e79f7;
}
.ant-table-column-sorter-up + .ant-table-column-sorter-down {
  margin-top: -0.3em;
}
.ant-table-filter-column {
  display: flex;
  align-items: center;
  margin: -16px;
}
.ant-table-filter-column-title {
  flex: auto;
  padding: 16px 2.3em 16px 16px;
}
.ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 2.3em 0 0;
}
.ant-table-filter-trigger-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: none;
  align-items: stretch;
  align-self: stretch;
  cursor: pointer;
  transition: background-color 0.3s;
}
.ant-table-filter-trigger-container-open,
.ant-table-filter-trigger-container:hover,
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover,
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: 0 0;
}
.ant-table-filter-trigger {
  display: block;
  width: 2.3em;
  color: #00000040 !important;
  font-size: 12px;
  transition: color 0.3s;
}
.ant-table-filter-trigger .anticon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ant-table-filter-trigger-container-open .ant-table-filter-trigger,
.ant-table-filter-trigger:hover {
  color: #72849a;
}
.ant-table-filter-trigger.active {
  color: #3e79f7;
}
.ant-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  min-width: 120px;
  background-color: #fff;
  border-radius: 0.625rem;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}
.ant-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
.ant-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px 7px 3px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid #edf2f9;
}
.ant-table .ant-table-selection-col {
  width: 32px;
}
table tr td.ant-table-selection-column,
table tr th.ant-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}
.ant-table-selection {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}
.ant-table-selection-extra {
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-margin-start: 100%;
  margin-inline-start: 100%;
  -webkit-padding-start: 4px;
  padding-inline-start: 4px;
}
.ant-table-selection-extra .anticon {
  color: #bfbfbf;
  font-size: 10px;
}
.ant-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}
.ant-table-expand-icon-col {
  width: 48px;
}
.ant-table-row-expand-icon-cell,
.ant-table-tbody > tr.ant-table-placeholder {
  text-align: center;
}
.ant-table-row-indent {
  float: left;
  height: 1px;
}
.ant-table-row-expand-icon {
  text-decoration: none;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  float: left;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
  outline: 0;
  transition: all 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #699dff;
}
.ant-table-row-expand-icon:active {
  color: #2a59d1;
}
.ant-table-row-expand-icon:active,
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  border-color: currentColor;
}
.ant-table-row-expand-icon::after,
.ant-table-row-expand-icon::before {
  position: absolute;
  background: currentColor;
  transition: transform 0.3s ease-out;
  content: "";
}
.ant-table-row-expand-icon::before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px;
}
.ant-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  transform: rotate(90deg);
}
.ant-table-row-expand-icon-collapsed::before {
  transform: rotate(-180deg);
}
.ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.ant-table-row-expand-icon-spaced {
  background: 0 0;
  border: 0;
  visibility: hidden;
}
.ant-table-row-expand-icon-spaced::after,
.ant-table-row-expand-icon-spaced::before {
  display: none;
  content: none;
}
.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2px;
  margin-right: 8px;
}
tr.ant-table-expanded-row:hover > td,
tr.ant-table-expanded-row > td {
  background: #fbfbfb;
}
.ant-table .ant-table-expanded-row-fixed {
  position: relative;
  margin: -16px;
  padding: 16px;
}
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #fff;
}
.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 2;
  background: #fff;
}
.ant-table-cell-fix-left-first::after,
.ant-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: "";
  pointer-events: none;
}
.ant-table-cell-fix-right-first::after,
.ant-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: "";
  pointer-events: none;
}
.ant-table .ant-table-container::after,
.ant-table .ant-table-container::before {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 30px;
  transition: box-shadow 0.3s;
  content: "";
  pointer-events: none;
}
.ant-table .ant-table-container::before {
  left: 0;
}
.ant-table .ant-table-container::after {
  right: 0;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container,
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container {
  position: relative;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-sticky-header,
.ant-table-sticky-scroll {
  position: -webkit-sticky;
  position: sticky;
  z-index: calc(2 + 1);
}
.ant-table-sticky-scroll {
  bottom: 0;
  display: flex;
  align-items: center;
  background: #fff;
  border-top: 1px solid #edf2f9;
  opacity: 0.6;
}
.ant-table-sticky-scroll:hover {
  transform-origin: center bottom;
}
.ant-table-sticky-scroll-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.ant-table-sticky-scroll-bar-active,
.ant-table-sticky-scroll-bar:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
@media all and (-ms-high-contrast: none) {
  .ant-table-ping-left .ant-table-cell-fix-left-last::after,
  .ant-table-ping-right .ant-table-cell-fix-right-first::after {
    box-shadow: none !important;
  }
}
.ant-table-title {
  border-radius: 0.625rem 0.625rem 0 0;
}
.ant-table-title + .ant-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}
.ant-table-container {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 0.625rem;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 0.625rem;
}
.ant-table-footer {
  border-radius: 0 0 0.625rem 0.625rem;
}
.ant-table-rtl,
.ant-table-wrapper-rtl,
.ant-tabs-dropdown-rtl,
.ant-tabs-rtl {
  direction: rtl;
}
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
  margin: -16px 33px -16px -16px;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: flex-end;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
  justify-content: flex-start;
}
.ant-table-wrapper-rtl .ant-table-column-sorter {
  margin-right: 8px;
  margin-left: 0;
}
.ant-table-wrapper-rtl .ant-table-filter-column-title {
  padding: 16px 16px 16px 2.3em;
}
.ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 0 0 2.3em;
}
.ant-table-wrapper-rtl .ant-table-filter-trigger-container {
  right: auto;
  left: 0;
}
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
.ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-right: 8px;
  padding-left: 0;
}
.ant-table-wrapper-rtl .ant-table-selection {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon,
.ant-table-wrapper-rtl .ant-table-row-indent {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon::after {
  transform: rotate(-90deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::before {
  transform: rotate(180deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
  font-size: 14px;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 16px 0;
  font-size: 16px;
}
.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 16px;
}
.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 7px 16px 6px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-left: 0;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0;
  margin-left: 12px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
  margin-right: 8px;
  margin-left: -4px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
  margin-right: 0;
  margin-left: 2px;
}
.ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
  text-align: right;
}
.ant-tabs-bottom,
.ant-tabs-top {
  flex-direction: column;
}
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav,
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav {
  margin: 0 0 16px;
}
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before,
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #edf2f9;
  content: "";
}
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px;
}
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: width 0.3s, left 0.3s, right 0.3s;
}
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  top: 0;
  bottom: 0;
  width: 30px;
}
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  left: 0;
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav::before {
  bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-content-holder,
.ant-tabs-bottom > div > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-left > .ant-tabs-nav,
.ant-tabs-left > div > .ant-tabs-nav,
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 16px;
  padding: 8px 24px;
  text-align: center;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab:last-of-type,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab:last-of-type,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab:last-of-type,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-bottom: 0;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 400;
  text-shadow: 0 0 0.25px #2a59d1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  right: 0;
  left: 0;
  height: 30px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  top: 0;
  box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  bottom: 0;
  box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 2px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: height 0.3s, top 0.3s;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
  right: 0;
}
.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  margin-left: -1px;
  border-left: 1px solid #edf2f9;
}
.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 24px;
}
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
}
.ant-tabs-right > .ant-tabs-content-holder,
.ant-tabs-right > div > .ant-tabs-content-holder {
  order: 0;
  margin-right: -1px;
  border-right: 1px solid #edf2f9;
}
.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-right: 24px;
}
.ant-tabs-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-tabs-dropdown-hidden {
  display: none;
}
.ant-tabs-dropdown-menu {
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.625rem;
  outline: 0;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tabs-dropdown-menu-item {
  min-width: 120px;
  margin: 0;
  padding: 8px 12px;
  overflow: hidden;
  color: #455560;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-dropdown-menu-item:hover {
  background: #f5f5f5;
}
.ant-tabs-dropdown-menu-item-disabled,
.ant-tabs-dropdown-menu-item-disabled:hover {
  color: #d0d4d7;
  background: 0 0;
  cursor: not-allowed;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8.5px 16px;
  background: #fafafa;
  border: 1px solid #edf2f9;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #3e79f7;
  background: #fff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility: hidden;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
  margin-right: 2px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #fff;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 0.625rem 0.625rem;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: #fff;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
  margin-bottom: 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0.625rem 0 0 0.625rem;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: #fff;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0.625rem 0.625rem 0;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-left-color: #fff;
}
.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  display: flex;
  overflow: hidden;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  content: "";
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: transform 0.3s;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  display: flex;
  align-self: stretch;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  position: relative;
  padding: 8.5px 16px;
  background: 0 0;
  border: 0;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  transform: translateY(100%);
  content: "";
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
  min-width: 40px;
  padding: 0 8px;
  background: #fafafa;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem 0.625rem 0 0;
  outline: 0;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: #699dff;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
  color: #2a59d1;
}
.ant-tabs-extra-content {
  flex: none;
}
.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*="ant-tabs-nav-wrap-ping"]),
.ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*="ant-tabs-nav-wrap-ping"]) {
  justify-content: center;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: #3e79f7;
  pointer-events: none;
}
.ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  background: 0 0;
  border: 0;
  outline: 0;
  cursor: pointer;
}
.ant-tabs-tab:last-of-type {
  margin-right: 0;
  margin-left: 0;
}
.ant-tabs-tab-btn:active,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:active,
.ant-tabs-tab-remove:focus {
  color: #2a59d1;
}
.ant-tabs-tab-btn,
.ant-tabs-tab-remove {
  outline: 0;
  transition: all 0.3s;
}
.ant-tabs-tab-remove {
  flex: none;
  margin-right: -4px;
  margin-left: 8px;
  color: #72849a;
  font-size: 12px;
  background: 0 0;
  border: 0;
  cursor: pointer;
}
.ant-tabs-tab-remove:hover {
  color: #1a3353;
}
.ant-tabs-tab:hover {
  color: #699dff;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #3e79f7;
  font-weight: 500;
}
.ant-tabs-tab.ant-tabs-tab-disabled {
  color: #d0d4d7;
  cursor: not-allowed;
}
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus {
  color: #d0d4d7;
}
.ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs-tab .anticon {
  margin-right: 12px;
}
.ant-tabs-content {
  display: flex;
  width: 100%;
}
.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}
.ant-tabs-content-animated {
  transition: margin 0.3s;
}
.ant-tabs-tabpane {
  flex: none;
  width: 100%;
  outline: 0;
}
.ant-tag {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  height: auto;
  font-size: 12px;
  line-height: 25px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #edf2f9;
  opacity: 1;
  transition: all 0.3s;
}
.ant-tag:hover {
  opacity: 0.85;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: #455560;
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag-close-icon {
  margin-left: 3px;
  color: #72849a;
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tag-close-icon:hover {
  color: #1a3353;
}
.ant-tag-has-color {
  border-color: transparent;
  color: #fff;
}
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover,
.ant-tag-has-color a,
.ant-tag-has-color a:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #3e79f7;
}
.ant-tag-checkable-checked,
.ant-tag-checkable:active {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #3e79f7;
}
.ant-tag-checkable:active {
  background-color: #2a59d1;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #eb2f96;
  background: #fff0f6;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #eb2f96;
  background: #fff0f6;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #de4436;
  background: #fff4f0;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #de4436;
  border-color: #de4436;
}
.ant-tag-volcano {
  color: #ff6b72;
  background: #fff1f0;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #ff6b72;
  border-color: #ff6b72;
}
.ant-tag-orange {
  color: #fa8c16;
  background: #fff7e6;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #fadb14;
  background: #feffe6;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #ffc542;
  background: #fffdf0;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #ffc542;
  border-color: #ffc542;
}
.ant-tag-cyan {
  color: #04d182;
  background: #e6fff1;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #04d182;
  border-color: #04d182;
}
.ant-tag-lime {
  color: #a0d911;
  background: #fcffe6;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #21b573;
  background: #e6f5ec;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #21b573;
  border-color: #21b573;
}
.ant-tag-blue {
  color: #3e79f7;
  background: #f0f7ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #3e79f7;
  border-color: #3e79f7;
}
.ant-tag-geekblue {
  color: #17bcff;
  background: #e6fcff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #17bcff;
  border-color: #17bcff;
}
.ant-tag-purple {
  color: #a461d8;
  background: #fbf0ff;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #a461d8;
  border-color: #a461d8;
}
.ant-tag-success {
  color: #21b573;
  background: #e6f5ec;
  border-color: #91dbb2;
}
.ant-tag-processing {
  color: #3e79f7;
  background: #f0f7ff;
  border-color: #bad7ff;
}
.ant-tag-error {
  color: #de4436;
  background: #fff4f0;
  border-color: #ffc5b8;
}
.ant-tag-warning {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag > .anticon + span,
.ant-tag > span + .anticon {
  margin-left: 7px;
}
.ant-tag.ant-tag-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
  text-align: right;
}
.ant-tag-rtl .ant-tag-close-icon {
  margin-right: 3px;
  margin-left: 0;
}
.ant-tag-rtl.ant-tag > .anticon + span,
.ant-tag-rtl.ant-tag > span + .anticon {
  margin-right: 7px;
  margin-left: 0;
}
.ant-timeline,
.ant-timeline-item {
  margin: 0;
  font-size: 14px;
  list-style: none;
}
.ant-timeline {
  box-sizing: border-box;
  color: #455560;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: "tnum";
  padding: 0;
}
.ant-timeline-item {
  position: relative;
  padding-bottom: 20px;
}
.ant-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% - 10px);
  border-left: 2px solid #edf2f9;
}
.ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
  background-color: transparent;
}
.ant-timeline-item-last > .ant-timeline-item-tail,
.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}
.ant-timeline-item-head-blue {
  color: #3e79f7;
  border-color: #3e79f7;
}
.ant-timeline-item-head-red {
  color: #ff6b72;
  border-color: #ff6b72;
}
.ant-timeline-item-head-green {
  color: #04d182;
  border-color: #04d182;
}
.ant-timeline-item-head-gray {
  color: #d0d4d7;
  border-color: #d0d4d7;
}
.ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  transform: translate(-50%, -50%);
}
.ant-timeline-item-content {
  position: relative;
  top: -6px;
  margin: 0 0 0 26px;
  word-break: break-word;
}
.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail {
  left: 50%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head {
  margin-left: -4px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  margin-left: 1px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(50% - 4px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(50% - 12px);
  margin: 0;
  text-align: right;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  margin: 0;
  text-align: right;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(50% - 12px);
  margin: 0;
  text-align: right;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail {
  left: calc(100% - 4px - 2px);
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(100% - 18px);
}
.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  height: calc(100% - 14px);
  border-left: 2px dotted #edf2f9;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  top: 15px;
  display: block;
  height: calc(100% - 15px);
  border-left: 2px dotted #edf2f9;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  position: absolute;
  top: -6px;
  width: calc(50% - 12px);
  text-align: right;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  left: calc(50% + 14px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline-rtl {
  direction: rtl;
}
.ant-timeline-rtl .ant-timeline-item-tail {
  right: 4px;
  left: auto;
  border-right: 2px solid #edf2f9;
  border-left: none;
}
.ant-timeline-rtl .ant-timeline-item-head-custom {
  right: 5px;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-timeline-rtl .ant-timeline-item-content {
  margin: 0 18px 0 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-tail {
  right: 50%;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head {
  margin-right: -4px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  margin-right: 1px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
  right: calc(50% - 4px);
  left: auto;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-label,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail {
  right: 0;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: 100%;
  margin-right: 18px;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  border-right: 2px dotted #edf2f9;
  border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  right: calc(50% + 14px);
  text-align: right;
}
.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  z-index: 1070;
  display: block;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightBottom,
.ant-tooltip-placement-rightTop {
  padding-left: 8px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftBottom,
.ant-tooltip-placement-leftTop {
  padding-right: 8px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 0.625rem;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: 0 0;
  pointer-events: none;
}
.ant-tooltip-arrow-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.75);
  content: "";
  pointer-events: auto;
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -5.07106781px;
}
.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  left: -5.07106781px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  right: -5.07106781px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -5.07106781px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-magenta .ant-tooltip-arrow-content,
.ant-tooltip-magenta .ant-tooltip-inner,
.ant-tooltip-pink .ant-tooltip-arrow-content,
.ant-tooltip-pink .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-red .ant-tooltip-arrow-content,
.ant-tooltip-red .ant-tooltip-inner {
  background-color: #de4436;
}
.ant-tooltip-volcano .ant-tooltip-arrow-content,
.ant-tooltip-volcano .ant-tooltip-inner {
  background-color: #ff6b72;
}
.ant-tooltip-orange .ant-tooltip-arrow-content,
.ant-tooltip-orange .ant-tooltip-inner {
  background-color: #fa8c16;
}
.ant-tooltip-yellow .ant-tooltip-arrow-content,
.ant-tooltip-yellow .ant-tooltip-inner {
  background-color: #fadb14;
}
.ant-tooltip-gold .ant-tooltip-arrow-content,
.ant-tooltip-gold .ant-tooltip-inner {
  background-color: #ffc542;
}
.ant-tooltip-cyan .ant-tooltip-arrow-content,
.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #04d182;
}
.ant-tooltip-lime .ant-tooltip-arrow-content,
.ant-tooltip-lime .ant-tooltip-inner {
  background-color: #a0d911;
}
.ant-tooltip-green .ant-tooltip-arrow-content,
.ant-tooltip-green .ant-tooltip-inner {
  background-color: #21b573;
}
.ant-tooltip-blue .ant-tooltip-arrow-content,
.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #3e79f7;
}
.ant-tooltip-geekblue .ant-tooltip-arrow-content,
.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #17bcff;
}
.ant-tooltip-purple .ant-tooltip-arrow-content,
.ant-tooltip-purple .ant-tooltip-inner {
  background-color: #a461d8;
}
.ant-tooltip-rtl {
  direction: rtl;
}
.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right;
}
.ant-transfer-customize-list .ant-transfer-list {
  flex: 1 1 50%;
  width: auto;
  height: auto;
  min-height: 275px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}
.ant-transfer-customize-list .ant-input[disabled] {
  background-color: transparent;
}
.ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: flex;
  align-items: stretch;
}
.ant-transfer-disabled .ant-transfer-list {
  background: #f7f7f8;
}
.ant-transfer-list {
  display: flex;
  flex-direction: column;
  height: 275px;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
}
.ant-transfer-list-with-pagination {
  width: 250px;
  height: auto;
}
.ant-transfer-list-search {
  padding-right: 24px;
  padding-left: 8px;
}
.ant-transfer-list-search-action {
  position: absolute;
  top: 12px;
  right: 12px;
  bottom: 12px;
  width: 28px;
  color: #d0d4d7;
  line-height: 40px;
  text-align: center;
}
.ant-transfer-list-search-action .anticon {
  color: #d0d4d7;
  transition: all 0.3s;
}
.ant-transfer-list-search-action .anticon:hover {
  color: #72849a;
}
.ant-transfer-list-header {
  display: flex;
  flex: none;
  align-items: center;
  height: 40px;
  padding: 8px 12px 9px;
  color: #455560;
  background: #fff;
  border-bottom: 1px solid #edf2f9;
  border-radius: 0.625rem 0.625rem 0 0;
}
.ant-transfer-list-header > :not(:last-child) {
  margin-right: 4px;
}
.ant-transfer-list-header > * {
  flex: none;
}
.ant-transfer-list-header-title {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}
.ant-transfer-list-header-dropdown {
  font-size: 10px;
  transform: translateY(10%);
  cursor: pointer;
}
.ant-transfer-list-header-dropdown[disabled] {
  cursor: not-allowed;
}
.ant-transfer-list-body {
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
}
.ant-transfer-list-body-search-wrapper {
  position: relative;
  flex: none;
  padding: 12px;
}
.ant-transfer-list-content {
  flex: auto;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.ant-transfer-list-content-item {
  display: flex;
  align-items: center;
  min-height: 44px;
  padding: 6px 12px;
  overflow: hidden;
  line-height: 32px;
  transition: all 0.3s;
}
.ant-transfer-list-content-item > :not(:last-child) {
  margin-right: 8px;
}
.ant-transfer-list-content-item > * {
  flex: none;
}
.ant-transfer-list-content-item-text {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-transfer-list-content-item-remove {
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  color: #edf2f9;
}
.ant-transfer-list-content-item-remove:focus,
.ant-transfer-list-content-item-remove:hover,
.ant-typography a:focus,
.ant-typography a:hover,
a.ant-typography:focus,
a.ant-typography:hover {
  color: #699dff;
}
.ant-transfer-list-content-item-remove:active,
.ant-typography a:active,
a.ant-typography:active {
  color: #2a59d1;
}
.ant-transfer-list-content-item-remove::after {
  position: absolute;
  top: -6px;
  right: -50%;
  bottom: -6px;
  left: -50%;
  content: "";
}
.ant-transfer-list-content-item-remove:hover {
  color: #699dff;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: rgba(62, 121, 247, 0.1);
  cursor: pointer;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
  background-color: #e6f2ff;
}
.ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background: 0 0;
  cursor: default;
}
.ant-transfer-list-content-item-checked {
  background-color: #f0f7ff;
}
.ant-transfer-list-content-item-disabled,
.ant-tree-checkbox-disabled + span {
  color: #d0d4d7;
  cursor: not-allowed;
}
.ant-transfer-list-pagination {
  padding: 8px 0;
  text-align: right;
  border-top: 1px solid #edf2f9;
}
.ant-transfer-list-body-not-found {
  flex: none;
  width: 100%;
  margin: auto 0;
  color: #d0d4d7;
  text-align: center;
}
.ant-transfer-list-footer {
  border-top: 1px solid #edf2f9;
}
.ant-transfer-operation {
  display: flex;
  flex: none;
  flex-direction: column;
  align-self: center;
  margin: 0 8px;
  overflow: hidden;
  vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
  display: block;
}
.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
.ant-transfer .ant-empty-image {
  max-height: -2px;
}
.ant-transfer-rtl {
  direction: rtl;
}
.ant-transfer-rtl .ant-transfer-list-search {
  padding-right: 8px;
  padding-left: 24px;
}
.ant-transfer-rtl .ant-transfer-list-search-action {
  right: auto;
  left: 12px;
}
.ant-transfer-rtl .ant-transfer-list-header > :not(:last-child) {
  margin-right: 0;
  margin-left: 4px;
}
.ant-transfer-rtl .ant-transfer-list-header {
  right: 0;
  left: auto;
}
.ant-transfer-rtl .ant-transfer-list-header-title,
.ant-transfer-rtl .ant-transfer-list-pagination {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-content-item > :not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-transfer-rtl .ant-transfer-list-footer {
  right: 0;
  left: auto;
}
.ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.ant-tree.ant-tree-directory .ant-tree-treenode {
  position: relative;
}
.ant-tree.ant-tree-directory .ant-tree-treenode::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  transition: background-color 0.3s;
  content: "";
  pointer-events: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: #f5f5f5;
}
.ant-tree.ant-tree-directory .ant-tree-treenode > * {
  z-index: 1;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  transition: color 0.3s;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
  border-radius: 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background: 0 0;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before {
  background: #3e79f7;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
  color: #fff;
  background: 0 0;
}
.ant-tree-checkbox {
  box-sizing: border-box;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner {
  border-color: #3e79f7;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #3e79f7;
  border-radius: 0.625rem;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: "";
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after,
.ant-tree-checkbox:hover::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #edf2f9;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after,
.ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: " ";
}
.ant-tree-checkbox-inner::after {
  top: 50%;
  left: 22%;
  width: 7.14285714px;
  height: 11.42857143px;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #3e79f7;
  border-color: #3e79f7;
}
.ant-tree-checkbox-disabled,
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: #d0d4d7;
  -webkit-animation-name: none;
  animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f7f7f8;
  border-color: #edf2f9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f7f7f8;
  border-collapse: separate;
  -webkit-animation-name: none;
  animation-name: none;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-tree-checkbox-group,
.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
}
.ant-tree-checkbox-wrapper {
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  line-height: 1.5;
}
.ant-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 10px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #edf2f9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  background-color: #3e79f7;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: " ";
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: #d0d4d7;
  border-color: #d0d4d7;
}
.ant-tree-checkbox-rtl {
  direction: rtl;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 10px;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 10px;
}
.ant-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  background: #fff;
  border-radius: 0.625rem;
  transition: background-color 0.3s;
}
.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: #f0f7ff;
}
.ant-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  flex: auto;
}
.ant-tree .ant-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px;
  outline: 0;
}
.ant-select-tree-checkbox-disabled + span,
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: #d0d4d7;
  cursor: not-allowed;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
  background: 0 0;
}
.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-tree-switcher .ant-select-tree-switcher-icon,
.ant-tree-switcher .ant-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-tree-switcher .ant-select-tree-switcher-icon svg,
.ant-tree-switcher .ant-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-tree-switcher-loading-icon {
  color: #3e79f7;
}
.ant-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  bottom: -4px;
  margin-left: -1px;
  border-left: 1px solid #fafafb;
  content: " ";
}
.ant-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  margin-left: -1px;
  border-bottom: 1px solid #fafafb;
  content: " ";
}
.ant-tree-checkbox {
  top: initial;
  margin: 2px 8px 0 0;
}
.ant-tree .ant-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: 0 0;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: rgba(62, 121, 247, 0.1);
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
  display: none;
}
.ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-tree-node-content-wrapper[draggable="true"] {
  line-height: 24px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #3e79f7;
  border-radius: 1px;
  pointer-events: none;
}
.ant-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #3e79f7;
  border-radius: 50%;
  content: "";
}
.ant-tree .ant-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #3e79f7;
}
.ant-tree-show-line .ant-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-tree-show-line .ant-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #edf2f9;
  content: "";
}
.ant-tree-show-line .ant-tree-indent-unit-end::before {
  display: none;
}
.ant-tree-show-line .ant-tree-switcher {
  background: #fff;
}
.ant-tree-show-line .ant-tree-switcher-line-icon {
  vertical-align: -0.225em;
}
.ant-tree .ant-tree-treenode-rtl,
.ant-tree-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator::after {
  right: -6px;
  left: unset;
}
.ant-tree-rtl .ant-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
  right: auto;
  left: -13px;
  border-right: none;
  border-left: 1px solid #edf2f9;
}
.ant-tree-rtl.ant-tree-checkbox,
.ant-tree-select-dropdown-rtl .ant-select-tree-checkbox {
  margin: 2px 0 0 8px;
}
.ant-select-tree-checkbox {
  box-sizing: border-box;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner {
  border-color: #3e79f7;
}
.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #3e79f7;
  border-radius: 0.625rem;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: "";
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after,
.ant-select-tree-checkbox:hover::after {
  visibility: visible;
}
.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 7.14285714px;
  height: 11.42857143px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: " ";
}
.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: " ";
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #3e79f7;
  border-color: #3e79f7;
}
.ant-select-tree-checkbox-disabled,
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: #d0d4d7;
  -webkit-animation-name: none;
  animation-name: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f7f7f8;
  border-color: #edf2f9 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #f7f7f8;
  border-collapse: separate;
  -webkit-animation-name: none;
  animation-name: none;
}
.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-select-tree-checkbox-group,
.ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
}
.ant-select-tree-checkbox-wrapper {
  line-height: unset;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-select-tree-checkbox-group {
  line-height: 1.5;
}
.ant-select-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 10px;
}
.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #edf2f9;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  background-color: #3e79f7;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: " ";
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: #d0d4d7;
  border-color: #d0d4d7;
}
.ant-select-tree-checkbox-rtl {
  direction: rtl;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 10px;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 10px;
}
.ant-tree-select-dropdown {
  padding: 8px 4px 0;
}
.ant-tree-select-dropdown-rtl {
  direction: rtl;
}
.ant-tree-select-dropdown .ant-select-tree {
  border-radius: 0;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode {
  padding-bottom: 8px;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-node-content-wrapper,
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
  flex: auto;
}
.ant-select-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  background: #fff;
  border-radius: 0.625rem;
  transition: background-color 0.3s;
}
.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
  background: #f0f7ff;
}
.ant-select-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-select-tree .ant-select-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px;
  outline: 0;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
  color: #d0d4d7;
  cursor: not-allowed;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
  background: 0 0;
}
.ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-select-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-select-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-select-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-select-tree-switcher .ant-select-tree-switcher-icon,
.ant-select-tree-switcher .ant-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-select-tree-switcher .ant-select-tree-switcher-icon svg,
.ant-select-tree-switcher .ant-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-select-tree-switcher-noop {
  cursor: default;
}
.ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-select-tree-switcher-loading-icon {
  color: #3e79f7;
}
.ant-select-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-select-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  bottom: -4px;
  margin-left: -1px;
  border-left: 1px solid #fafafb;
  content: " ";
}
.ant-select-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  margin-left: -1px;
  border-bottom: 1px solid #fafafb;
  content: " ";
}
.ant-select-tree-checkbox {
  top: initial;
  margin: 2px 8px 0 0;
}
.ant-select-tree .ant-select-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: 0 0;
  border-radius: 0.625rem;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: rgba(62, 121, 247, 0.1);
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle:empty {
  display: none;
}
.ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-select-tree-node-content-wrapper[draggable="true"] {
  line-height: 24px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-select-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #3e79f7;
  border-radius: 1px;
  pointer-events: none;
}
.ant-select-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #3e79f7;
  border-radius: 50%;
  content: "";
}
.ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #3e79f7;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #edf2f9;
  content: "";
}
.ant-select-tree-show-line .ant-select-tree-indent-unit-end::before,
.ant-upload-list-picture-card.ant-upload-list::after {
  display: none;
}
.ant-select-tree-show-line .ant-select-tree-switcher {
  background: #fff;
}
.ant-select-tree-show-line .ant-select-tree-switcher-line-icon {
  vertical-align: -0.225em;
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher-loading-icon {
  transform: scaleY(-1);
}
.ant-typography {
  color: #455560;
  overflow-wrap: break-word;
}
.ant-typography.ant-typography-secondary {
  color: #72849a;
}
.ant-typography.ant-typography-success {
  color: #04d182;
}
a.ant-typography.ant-typography-danger:active,
a.ant-typography.ant-typography-danger:focus,
a.ant-typography.ant-typography-danger:hover {
  color: #ff9496;
}
.ant-typography.ant-typography-disabled {
  color: #d0d4d7;
  cursor: not-allowed;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-typography p,
div.ant-typography {
  margin-bottom: 1em;
}
.ant-typography h1,
h1.ant-typography {
  margin-bottom: 0.5em;
  color: #1a3353;
  font-size: 30px;
  line-height: 1.23;
}
.ant-typography h2,
.ant-typography h3,
h2.ant-typography,
h3.ant-typography {
  margin-bottom: 0.5em;
  color: #1a3353;
  font-size: 22px;
  line-height: 1.35;
}
.ant-typography h3,
h3.ant-typography {
  font-size: 21px;
}
.ant-typography h4,
h4.ant-typography {
  margin-bottom: 0.5em;
  color: #1a3353;
  font-size: 17px;
  line-height: 1.4;
}
.ant-typography h5,
h5.ant-typography {
  margin-bottom: 0.5em;
  color: #1a3353;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
}
.ant-typography div + h1,
.ant-typography div + h2,
.ant-typography div + h3,
.ant-typography div + h4,
.ant-typography div + h5,
.ant-typography h1 + h1,
.ant-typography h1 + h2,
.ant-typography h1 + h3,
.ant-typography h1 + h4,
.ant-typography h1 + h5,
.ant-typography h2 + h1,
.ant-typography h2 + h2,
.ant-typography h2 + h3,
.ant-typography h2 + h4,
.ant-typography h2 + h5,
.ant-typography h3 + h1,
.ant-typography h3 + h2,
.ant-typography h3 + h3,
.ant-typography h3 + h4,
.ant-typography h3 + h5,
.ant-typography h4 + h1,
.ant-typography h4 + h2,
.ant-typography h4 + h3,
.ant-typography h4 + h4,
.ant-typography h4 + h5,
.ant-typography h5 + h1,
.ant-typography h5 + h2,
.ant-typography h5 + h3,
.ant-typography h5 + h4,
.ant-typography h5 + h5,
.ant-typography li + h1,
.ant-typography li + h2,
.ant-typography li + h3,
.ant-typography li + h4,
.ant-typography li + h5,
.ant-typography p + h1,
.ant-typography p + h2,
.ant-typography p + h3,
.ant-typography p + h4,
.ant-typography p + h5,
.ant-typography ul + h1,
.ant-typography ul + h2,
.ant-typography ul + h3,
.ant-typography ul + h4,
.ant-typography ul + h5,
.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography,
.ant-typography + h5.ant-typography {
  margin-top: 1.2em;
}
a.ant-typography-ellipsis,
span.ant-typography-ellipsis {
  display: inline-block;
}
.ant-typography a,
.ant-typography-copy,
.ant-typography-edit,
.ant-typography-expand,
a.ant-typography {
  color: #3e79f7;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
}
.ant-typography a:active,
.ant-typography a:hover,
a.ant-typography:active,
a.ant-typography:hover {
  text-decoration: none;
}
.ant-typography a.ant-typography-disabled,
.ant-typography a[disabled],
a.ant-typography.ant-typography-disabled,
a.ant-typography[disabled] {
  color: #d0d4d7;
  cursor: not-allowed;
}
.ant-typography a.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:hover,
.ant-typography a[disabled]:active,
.ant-typography a[disabled]:hover,
a.ant-typography.ant-typography-disabled:active,
a.ant-typography.ant-typography-disabled:hover,
a.ant-typography[disabled]:active,
a.ant-typography[disabled]:hover {
  color: #d0d4d7;
}
.ant-typography a.ant-typography-disabled:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
a.ant-typography[disabled]:active {
  pointer-events: none;
}
.ant-typography code,
.ant-typography kbd {
  margin: 0 0.2em;
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography code {
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(150, 150, 150, 0.1);
}
.ant-typography kbd {
  padding: 0.15em 0.4em 0.1em;
  font-size: 90%;
  background: rgba(150, 150, 150, 0.06);
  border-bottom-width: 2px;
}
.ant-typography mark {
  padding: 0;
  background-color: #fff2bd;
}
.ant-typography ins,
.ant-typography u {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}
.ant-typography del,
.ant-typography s {
  text-decoration: line-through;
}
.ant-typography-copy,
.ant-typography-edit,
.ant-typography-expand {
  margin-left: 4px;
}
.ant-typography-copy:focus,
.ant-typography-copy:hover,
.ant-typography-edit:focus,
.ant-typography-edit:hover,
.ant-typography-expand:focus,
.ant-typography-expand:hover {
  color: #699dff;
}
.ant-typography-copy:active,
.ant-typography-edit:active,
.ant-typography-expand:active {
  color: #2a59d1;
}
.ant-typography-copy-success,
.ant-typography-copy-success:focus,
.ant-typography-copy-success:hover {
  color: #04d182;
}
.ant-typography-edit-content {
  position: relative;
}
div.ant-typography-edit-content {
  left: -12px;
  margin-top: -9.5px;
  margin-bottom: calc(1em - 8.5px - 1px);
}
.ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: #72849a;
  pointer-events: none;
}
.ant-typography-edit-content textarea {
  -moz-transition: none;
}
.ant-typography ol,
.ant-typography ul {
  margin: 0 0 1em;
  padding: 0;
}
.ant-typography ol li,
.ant-typography ul li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
.ant-typography ul {
  list-style-type: circle;
}
.ant-typography ul ul {
  list-style-type: disc;
}
.ant-typography ol {
  list-style-type: decimal;
}
.ant-typography blockquote,
.ant-typography pre {
  margin: 1em 0;
}
.ant-typography pre {
  padding: 0.4em 0.6em;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography pre code {
  display: inline;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background: 0 0;
  border: 0;
}
.ant-typography blockquote {
  padding: 0 0 0 0.6em;
  border-left: 4px solid rgba(100, 100, 100, 0.2);
  opacity: 0.85;
}
.ant-typography-ellipsis-single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
a.ant-typography-ellipsis-single-line,
span.ant-typography-ellipsis-single-line {
  vertical-align: bottom;
}
.ant-typography-ellipsis-multiple-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.ant-typography-rtl {
  direction: rtl;
}
.ant-typography-rtl .ant-typography-copy,
.ant-typography-rtl .ant-typography-edit,
.ant-typography-rtl .ant-typography-expand {
  margin-right: 4px;
  margin-left: 0;
}
.ant-typography-rtl .ant-typography-expand {
  float: left;
}
div.ant-typography-edit-content.ant-typography-rtl {
  right: -12px;
  left: auto;
}
.ant-typography-rtl .ant-typography-edit-content-confirm {
  right: auto;
  left: 10px;
}
.ant-typography-rtl.ant-typography ol li,
.ant-typography-rtl.ant-typography ul li {
  margin: 0 20px 0 0;
  padding: 0 4px 0 0;
}
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: 0;
}
.ant-upload input[type="file"] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
  text-align: center;
  vertical-align: top;
  border-radius: 0.625rem;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #3e79f7;
}
.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #edf2f9;
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #2a59d1;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: #d0d4d7;
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover,
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: #72849a;
}
.ant-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%;
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: "";
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  display: block;
  clear: both;
  content: "";
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  line-height: 1.5;
}
.ant-upload-list::before {
  display: table;
  content: "";
}
.ant-upload-list::after {
  display: table;
  display: block;
  clear: both;
  content: "";
}
.ant-upload-list-item {
  position: relative;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}
.ant-upload-list-item-card-actions-btn {
  opacity: 0;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 20px;
  line-height: 1;
}
.ant-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0;
}
.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
  color: #72849a;
}
.ant-upload-list-item-info {
  height: 100%;
  padding: 0 4px;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-item-info .ant-upload-text-icon .anticon,
.ant-upload-list-item-info .anticon-loading .anticon {
  position: absolute;
  top: 5px;
  color: #72849a;
  font-size: 14px;
}
.ant-upload-list-item .anticon-close {
  position: absolute;
  top: 6px;
  right: 4px;
  color: #72849a;
  font-size: 10px;
  line-height: 0;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-item .anticon-close:hover {
  color: #455560;
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f5f5f5;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn,
.ant-upload-list-item:hover .anticon-close {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon {
  color: #ff6b72;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item {
  height: 66px;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  padding: 8px;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: 0 0;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ff6b72;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  line-height: 54px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  line-height: 54px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-icon .anticon,
.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-icon .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#e6f7ff"],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#e6f7ff"] {
  fill: #fff1f0;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#1890ff"],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#1890ff"] {
  fill: #ff6b72;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name {
  display: inline-block;
  margin: 0 0 0 8px;
  line-height: 44px;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px;
}
.ant-upload-list-picture .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  margin-top: 0;
}
.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin: 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: " ";
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: #1a3353;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover,
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5;
  text-align: center;
}
.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 14px);
  padding-left: 0;
}
.ant-upload-list-picture-container,
.ant-upload-list-text-container {
  transition: opacity 0.3s, height 0.3s;
}
.ant-upload-list-picture-container::before,
.ant-upload-list-text-container::before {
  display: table;
  width: 0;
  height: 0;
  content: "";
}
.ant-upload-list-picture-container .ant-upload-span,
.ant-upload-list-text-container .ant-upload-span {
  display: block;
  flex: auto;
}
.ant-upload-list-picture .ant-upload-span,
.ant-upload-list-text .ant-upload-span {
  display: flex;
  align-items: center;
}
.ant-upload-list-picture .ant-upload-span > *,
.ant-upload-list-text .ant-upload-span > * {
  flex: none;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-text .ant-upload-list-item-name {
  flex: auto;
  padding: 0 8px;
}
.ant-upload-list-picture .ant-upload-list-item-card-actions,
.ant-upload-list-text .ant-upload-list-item-card-actions,
.ant-upload-list-text .ant-upload-text-icon .anticon {
  position: static;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter {
  -webkit-animation-name: uploadAnimateInlineIn;
  animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-name: uploadAnimateInlineOut;
  animation-name: uploadAnimateInlineOut;
}
.ant-upload-list-rtl,
.ant-upload-rtl {
  direction: rtl;
}
.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
  margin-right: auto;
  margin-left: 8px;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 22px;
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 22px;
  padding-left: 28px;
}
.ant-upload-list-rtl .ant-upload-list-item-name {
  padding-right: 22px;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions {
  right: auto;
  left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon,
.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-info {
  padding: 0 4px 0 12px;
}
.ant-upload-list-rtl .ant-upload-list-item .anticon-close {
  right: auto;
  left: 4px;
}
.ant-upload-list-rtl .ant-upload-list-item-progress {
  padding-right: 26px;
  padding-left: 0;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  right: 8px;
  left: auto;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-right: 48px;
  padding-left: 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 48px;
  padding-left: 18px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 48px;
  padding-left: 36px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 0;
  padding-left: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .anticon-close,
.ant-upload-list-rtl.ant-upload-list-picture-card .anticon-close {
  right: auto;
  left: 8px;
}
.ant-upload-list-rtl .ant-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fBBc9.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxP.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fBBc9.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc9.ttf) format("truetype");
}
@table-selected-row-bg: rgba(#3e79f7: 0.1);
.ant-btn-dashed {
  border-width: 0.2rem;
  line-height: 1;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link):focus,
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link):hover {
  background-color: #ededed;
  border-color: #ededed;
  color: #455560;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link).ant-btn-clicked:after {
  border-color: #edf2f9;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link).disabled,
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link)[disabled] {
  color: #d0d4d7;
  background-color: #f7f7f8;
  border-color: #edf2f9;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link).disabled.active,
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link).disabled:active,
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link).disabled:focus,
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link).disabled:hover,
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link)[disabled].active,
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link)[disabled]:active,
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link)[disabled]:focus,
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link)[disabled]:hover {
  color: #d0d4d7;
  background-color: #f7f7f8;
  border-color: #edf2f9;
}
.ant-btn.ant-btn-link {
  color: #3e79f7;
}
.ant-btn.ant-btn-link:focus,
.ant-btn.ant-btn-link:hover {
  background-color: transparent;
  color: #3e79f7;
}
.ant-btn.ant-btn-link:hover {
  background-color: rgba(62, 121, 247, 0.1);
}
.ant-btn.ant-btn-link:active {
  background-color: rgba(62, 121, 247, 0.2);
}
.ant-btn.ant-btn-link.disabled,
.ant-btn.ant-btn-link[disabled] {
  color: #d0d4d7;
}
.ant-btn.ant-btn-link.disabled.active,
.ant-btn.ant-btn-link.disabled:active,
.ant-btn.ant-btn-link.disabled:focus,
.ant-btn.ant-btn-link.disabled:hover,
.ant-btn.ant-btn-link[disabled].active,
.ant-btn.ant-btn-link[disabled]:active,
.ant-btn.ant-btn-link[disabled]:focus,
.ant-btn.ant-btn-link[disabled]:hover {
  color: #d0d4d7;
  background-color: transparent;
}
.ant-btn-dangerous {
  color: #ff6b72;
  border-color: #ff6b72;
}
.ant-btn-dangerous:focus,
.ant-btn-dangerous:hover {
  background-color: transparent;
  color: #ff858a;
  border-color: #ff858a;
}
.ant-btn-dangerous:active {
  background-color: transparent;
  color: #ff525a;
  border-color: #ff525a;
}
.ant-btn-dangerous.ant-btn-primary {
  background-color: #ff6b72;
  border-color: #ff6b72;
  color: #fff;
}
.ant-btn-dangerous.ant-btn-primary:focus,
.ant-btn-dangerous.ant-btn-primary:hover {
  color: #fff;
  background-color: #ff858a;
  border-color: #ff858a;
}
.ant-btn-dangerous.ant-btn-primary:active {
  color: #ff525a;
  border-color: #ff525a;
}
.ant-btn-dangerous.ant-btn-link {
  color: #ff6b72;
}
.ant-btn-dangerous.ant-btn-link:focus,
.ant-btn-dangerous.ant-btn-link:hover {
  background-color: transparent;
  color: #ff6b72;
}
.ant-btn-dangerous.ant-btn-link:hover {
  background-color: rgba(255, 107, 114, 0.1);
}
.ant-btn-dangerous.ant-btn-link:active {
  background-color: rgba(255, 107, 114, 0.2);
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  line-height: 1.5;
  color: #1a3353;
}
.h1,
h1 {
  font-size: 30px;
}
.h2,
h2 {
  font-size: 22px;
}
.h3,
h3 {
  font-size: 21px;
}
.h4,
h4 {
  font-size: 17px;
}
.h5,
h5 {
  font-size: 14px;
}
.h6,
h6 {
  font-size: 10px;
}
p {
  line-height: 1.8;
}
code {
  margin: 0 1px;
  background: #f7f7f8;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 0.9em;
  border: 1px solid #edf2f9;
}
.hl-code code {
  margin: 0;
  background: 0 0;
  padding: 0;
  border-radius: 3px;
  font-size: 1em;
  border: 0;
}
.ant-typography h1,
.ant-typography h2,
.ant-typography h3,
.ant-typography h4,
h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography {
  font-weight: 500;
}
.ant-breadcrumb a,
.ant-typography p,
p,
p.ant-typography {
  color: #72849a;
}
.ant-typography strong {
  font-weight: 700;
}
.ant-typography.ant-typography-warning {
  color: #ffc542;
}
.ant-typography.ant-typography-danger,
code {
  color: #ff6b72;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.5;
}
.display-2,
.display-3,
.display-4 {
  font-size: 5rem;
  font-weight: 300;
  line-height: 1.5;
}
.display-3,
.display-4 {
  font-size: 4rem;
}
.display-4 {
  font-size: 3rem;
  line-height: 1;
}
.ant-affix {
  z-index: 1030;
  top: 70px !important;
}
.ant-breadcrumb {
  line-height: 1.5;
  color: #455560;
}
.ant-breadcrumb > span:last-child {
  color: #1a3353;
}
.ant-dropdown-menu {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  min-width: 160px;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 8px 20px;
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  padding: 8px 20px;
  margin: -8px -20px;
}
.ant-menu {
  font-weight: 500;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 0;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
  border-bottom: 0;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover {
  border-bottom: 0;
}
.ant-menu-sub {
  font-weight: 400;
}
.ant-menu-item-group-title {
  padding: 12px 24px;
  color: rgba(26, 51, 83, 0.6);
  font-size: 12px;
  line-height: 1.5;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 15px;
}
.ant-pagination.mini .ant-pagination-item {
  line-height: 24px;
}
.ant-pagination-item,
.ant-pagination-total-text .ant-pagination-item {
  border-radius: 50%;
  border-color: transparent;
  line-height: 30px;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover,
.ant-pagination-total-text .ant-pagination-item:focus,
.ant-pagination-total-text .ant-pagination-item:hover {
  border-color: transparent;
}
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  border-radius: 50%;
}
.ant-pagination-item-active a,
.code-box-carousel-demo .ant-carousel .slick-slide h3 {
  color: #fff;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #fff;
}
.ant-form-item-label label,
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #72849a;
}
.ant-steps-item-wait .ant-steps-item-icon {
  border-color: #edf2f9;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content .ant-steps-item-description,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content .ant-steps-item-title {
  color: rgba(114, 132, 154, 0.7);
}
.ant-checkbox-inner:after {
  top: 8px;
  left: 4px;
}
.ant-checkbox-checked:after,
.ant-checkbox-inner {
  border-radius: 0.25rem;
}
.ant-picker-cell .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border-radius: 50rem;
}
.ant-picker-cell-in-view.ant-picker-cell-today {
  color: #3e79f7;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 50rem;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  height: 30px;
}
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 3px;
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after {
  left: 3px;
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
}
.ant-picker-range .ant-picker-active-bar {
  background-color: transparent;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after {
  right: 3px;
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after {
  left: 3px;
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
}
.ant-picker-month-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner {
  width: 80px;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: rgba(62, 121, 247, 0.1);
  color: #3e79f7;
  font-weight: 500;
}
.ant-form-item {
  margin-bottom: 24px;
}
.ant-form-item-label > label {
  color: #1a3353;
}
.ant-radio-inner:after {
  top: 3px;
  left: 3px;
}
.ant-slider {
  margin: 10px 6px 30px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-left: -7px;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 10px;
}
.ant-slider-mark {
  top: 17px;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgba(62, 121, 247, 0.1);
  color: #3e79f7;
}
.ant-transfer-list {
  width: 240px;
}
.ant-tree .ant-tree-node-content-wrapper {
  border-radius: 0.25rem;
}
.ant-upload.ant-upload-select-picture-card {
  width: 110px;
  height: 110px;
  border: 2px dashed #edf2f9;
  border-radius: 10px;
  background-color: transparent;
  margin-right: 10px;
  margin-bottom: 10px;
}
.ant-upload.ant-upload-drag {
  border: 2px dashed #edf2f9;
  border-radius: 0.625rem;
  padding: 0 16px;
  background: 0 0;
  min-height: 220px;
  display: flex;
  align-items: center;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #3e79f7;
}
.ant-comment-content-author-name > :hover,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #3e79f7;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  color: #1a3353;
  font-size: 18px;
  margin-bottom: 15px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: #72849a;
}
.ant-upload-list-item {
  height: 30px;
}
.ant-upload-list-item-info {
  border-radius: 0.25rem;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye {
  color: rgba(255, 255, 255, 0.85);
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .ant-btn:not(.ant-btn-primary):focus,
.ant-upload-list-picture-card .ant-upload-list-item-actions .ant-btn:not(.ant-btn-primary):hover {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
}
.ant-upload-list-item-card-actions .ant-btn:not(.ant-btn-primary):focus,
.ant-upload-list-item-card-actions .ant-btn:not(.ant-btn-primary):hover {
  background-color: transparent;
  border-color: transparent;
}
.avatar-status-name {
  font-weight: 500;
  color: #1a3353;
}
.ant-avatar-blue {
  background: rgba(62, 121, 247, 0.1);
  color: #3e79f7;
}
.ant-avatar-purple {
  background: rgba(164, 97, 216, 0.1);
  color: #a461d8;
}
.ant-avatar-cyan {
  background: rgba(4, 209, 130, 0.1);
  color: #04d182;
}
.ant-avatar-green {
  background: rgba(33, 181, 115, 0.1);
  color: #21b573;
}
.ant-avatar-magenta,
.ant-avatar-pink {
  background: rgba(235, 47, 150, 0.1);
  color: #eb2f96;
}
.ant-avatar-red {
  background: rgba(222, 68, 54, 0.1);
  color: #de4436;
}
.ant-avatar-orange {
  background: rgba(250, 140, 22, 0.1);
  color: #fa8c16;
}
.ant-avatar-yellow {
  background: rgba(250, 219, 20, 0.1);
  color: #fadb14;
}
.ant-avatar-volcano {
  background: rgba(255, 107, 114, 0.1);
  color: #ff6b72;
}
.ant-avatar-geekblue {
  background: rgba(23, 188, 255, 0.1);
  color: #17bcff;
}
.ant-avatar-lime {
  background: rgba(160, 217, 17, 0.1);
  color: #a0d911;
}
.ant-avatar-gold {
  background: rgba(255, 197, 66, 0.1);
  color: #ffc542;
}
.ant-avatar-primary {
  background: rgba(62, 121, 247, 0.1);
  color: #3e79f7;
}
.ant-avatar-secondary {
  background: rgba(164, 97, 216, 0.1);
  color: #a461d8;
}
.ant-avatar-success {
  background: rgba(4, 209, 130, 0.1);
  color: #04d182;
}
.ant-avatar-info {
  background: rgba(62, 121, 247, 0.1);
  color: #3e79f7;
}
.ant-avatar-warning {
  background: rgba(255, 197, 66, 0.1);
  color: #ffc542;
}
.ant-avatar-danger {
  background: rgba(255, 107, 114, 0.1);
  color: #ff6b72;
}
.ant-avatar-gray {
  background: rgba(69, 85, 96, 0.1);
  color: #455560;
}
.ant-avatar-gray-light {
  background: rgba(114, 132, 154, 0.1);
  color: #72849a;
}
.ant-avatar-gray-lighter {
  background: rgba(237, 237, 237, 0.1);
  color: #ededed;
}
.ant-avatar-gray-lightest {
  background: rgba(247, 247, 248, 0.1);
  color: #f7f7f8;
}
.ant-avatar-dark {
  background: rgba(26, 51, 83, 0.1);
  color: #1a3353;
}
.ant-badge p {
  color: inherit;
}
.ant-comment-content-author > a,
.ant-comment-content-author > span {
  font-size: 17px;
}
.ant-comment-content-author-name,
.ant-comment-content-author-name > * {
  color: #1a3353;
  font-weight: 500;
}
.ant-comment-content-author-time > * {
  font-size: 12px;
}
.ant-comment-content-detail p {
  color: #72849a;
}
.ant-comment-actions > li > span {
  font-size: 14px;
  padding-right: 15px;
}
.ant-comment-actions > li .anticon {
  margin-right: 5px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 16px 40px;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 1rem;
}
.ant-collapse-borderless {
  background-color: #fff;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-header {
  font-size: 17px;
  font-weight: 500;
}
.ant-collapse-borderless.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 21px;
}
.ant-card {
  margin: 0 0 20px;
}
.ant-card-head {
  padding: 0 20px;
  margin-bottom: -1px;
  min-height: 35px;
  border-bottom: 0;
  font-size: 17px;
}
.ant-card-head .ant-tabs-bar {
  border-bottom: 1px solid #edf2f9;
}
.ant-card-head .ant-tabs {
  margin-bottom: -21px;
}
.ant-card-head-title {
  font-weight: 700;
  padding-bottom: 0;
  padding-top: 16px;
}
.ant-card-extra {
  padding-top: 20px;
  padding-bottom: 0;
}
.ant-card-bordered,
.api-container table {
  border: 1px solid #edf2f9;
}
.ant-card-meta-title,
.ant-table-thead > tr > th {
  font-weight: 700;
}
.ant-card-type-inner .ant-card-head,
.ant-table-fixed-header .ant-table-thead > tr > th {
  /* background: #f7f7f8; */
}
.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 10px 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-content tbody {
  border-left: 1px solid #edf2f9;
  border-bottom: 1px solid #edf2f9;
}
.ant-picker-calendar.ant-picker-calendar-mini .ant-picker-panel {
  border-top: 1px solid #edf2f9;
}
.ant-picker-calendar.ant-picker-calendar-mini .ant-picker-panel .ant-picker-content tbody {
  border-left: 0;
  border-bottom: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  margin: 0;
  padding: 4px 8px 0;
  border-top: 1px solid #edf2f9;
  border-right: 1px solid #edf2f9;
}
@media only screen and (max-width: 768px) {
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    padding-left: 4px;
    padding-right: 4px;
  }
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  display: inline-block;
  line-height: 2;
}
@media only screen and (max-width: 768px) {
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
    line-height: 1.5;
  }
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  height: 86px;
}
@media only screen and (min-width: 992px) and (max-width: 1600px) {
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    height: 70px;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content .calendar-event .ant-badge {
    display: flex;
    align-items: baseline;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content .calendar-event .ant-badge-status-dot {
    min-width: 7px;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content .calendar-event .ant-badge-status-text {
    font-size: 12px;
  }
}
@media only screen and (max-width: 768px) {
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    height: 30px;
  }
}
@media only screen and (max-width: 992px) {
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    height: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-cell-inner {
    line-height: 1;
    font-size: 12px;
  }
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #3e79f7;
  font-weight: 500;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: rgba(62, 121, 247, 0.1);
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  background-color: #3e79f7;
  color: #fff !important;
  font-weight: 400 !important;
  border-radius: 0.3rem;
  padding: 0 0.408rem;
}
@media only screen and (max-width: 768px) {
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    padding: 0 0.2rem;
  }
}
.ant-picker-calendar-header {
  padding: 0.9375rem 0;
}
.ant-list-vertical .ant-list-item-meta-title,
.code-box-highlight pre {
  margin-bottom: 0;
}
.ant-list-item-meta-description {
  font-size: 13px;
}
.ant-list-item.list-clickable {
  cursor: pointer;
  transition: background-color 0.15s ease;
}
.ant-list-item.list-clickable:hover {
  background-color: #f5f5f5;
}
.ant-timeline-item-tail {
  border-left-style: dashed;
  border-color: #edf2f9;
}
.ant-tree-checkbox-inner:after {
  top: 8px;
  left: 4px;
}
.ant-tag,
.ant-tree-checkbox-checked:after,
.ant-tree-checkbox-inner {
  border-radius: 0.25rem;
}
.ant-tag {
  background: #fff;
  padding: 0 10px;
  margin: 0 10px 0 0;
}
.ant-tag-blue {
  background: rgba(62, 121, 247, 0.1);
  border-color: transparent;
  font-weight: 500;
}
.ant-tag-purple {
  background: rgba(164, 97, 216, 0.1);
}
.ant-tag-cyan,
.ant-tag-green,
.ant-tag-purple {
  border-color: transparent;
  font-weight: 500;
}
.ant-tag-cyan {
  background: rgba(4, 209, 130, 0.1);
}
.ant-tag-green {
  background: rgba(33, 181, 115, 0.1);
}
.ant-tag-magenta,
.ant-tag-pink {
  background: rgba(235, 47, 150, 0.1);
  border-color: transparent;
  font-weight: 500;
}
.ant-tag-red {
  background: rgba(222, 68, 54, 0.1);
  border-color: transparent;
  font-weight: 500;
}
.ant-tag-orange {
  background: rgba(250, 140, 22, 0.1);
}
.ant-tag-orange,
.ant-tag-volcano,
.ant-tag-yellow {
  border-color: transparent;
  font-weight: 500;
}
.ant-tag-yellow {
  background: rgba(250, 219, 20, 0.1);
}
.ant-tag-volcano {
  background: rgba(255, 107, 114, 0.1);
}
.ant-tag-geekblue {
  background: rgba(23, 188, 255, 0.1);
}
.ant-tag-geekblue,
.ant-tag-gold,
.ant-tag-lime {
  border-color: transparent;
  font-weight: 500;
}
.ant-tag-lime {
  background: rgba(160, 217, 17, 0.1);
}
.ant-tag-gold {
  background: rgba(255, 197, 66, 0.1);
}
.ant-tabs-tab {
  padding: 12px 20px;
  margin: 0 20px 0 0;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0.25rem 0.25rem 0 0;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #f2f2f2;
}
.ant-message-notice-content {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.ant-alert-with-description .ant-alert-message,
.ant-notification-notice-message {
  font-weight: 500;
}
.ant-popover-inner {
  box-shadow: 0 2px 8px rgba(26, 51, 83, 0.15);
}
.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-anchor-link {
  padding: 8px 0 8px 15px;
  line-height: 1.5;
}
.api-container {
  background-color: #fff;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
  padding: 0 20px 20px;
  overflow-x: auto;
}
.api-container .api-title,
.api-container .api-title.api,
.api-container .api-title.when-to-use {
  margin-top: 24px;
}
.api-container .api-title.api.h2 {
  border-bottom: 1px solid #edf2f9;
  padding-bottom: 8px;
  margin-bottom: 24px;
}
.api-container ul {
  padding-left: 0;
}
.api-container ul > li {
  margin-left: 20px;
  padding-left: 4px;
  list-style-type: circle;
}
.api-container > hr,
.ql-snow .ql-toolbar input.ql-image[type="file"],
.ql-snow.ql-toolbar input.ql-image[type="file"] {
  display: none;
}
.api-container img,
.ql-snow .ql-editor img {
  max-width: 100%;
}
.api-container .api-code-highligher {
  margin-bottom: 16px;
}
.api-container .api-code-highligher code,
.code-box-highlight code {
  margin: 0;
  background: 0 0;
  padding: 0;
  border-radius: 0;
  font-size: 14px;
  border: 0;
  color: inherit;
}
.api-container table {
  min-width: 720px;
  width: 100%;
  margin-bottom: 2.5em;
  margin-top: 0.8rem;
  font-size: 13px;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  line-height: 1.5;
  border-collapse: collapse;
  border-spacing: 0;
}
.api-container table th {
  color: #5c6b77;
  font-weight: 500;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.02);
}
.api-container table td:first-child {
  width: 20%;
  color: #595959;
  font-weight: 600;
}
.api-container table td,
.api-container table th {
  padding: 12px;
  border-color: #edf2f9;
  border-width: 1px 0;
  border-style: solid;
}
.api-container table tbody tr {
  transition: all 0.3s ease;
}
.api-container table tbody tr:hover {
  background: rgba(60, 90, 100, 0.04);
}
.code-box {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0 0 16px;
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
  background-color: #fff;
}
.code-box-demo {
  padding: 42px 24px 50px;
  border-bottom: 1px solid #edf2f9;
}
.code-box-demo iframe {
  border: 0;
  width: 100%;
}
.code-box-demo .config-provider .site-config-provider-calendar-wrapper {
  width: 319px;
  border: 1px solid #edf2f9;
  border-radius: 2px;
}
.code-box-demo .config-provider .locale-components {
  border-top: 1px solid #edf2f9;
  padding-top: 16px;
}
.code-box-demo .config-provider .example {
  margin: 16px 0;
}
.code-box-demo .config-provider .example > * {
  margin-right: 8px;
}
.code-box-demo .config-provider .change-locale {
  margin-bottom: 16px;
}
.code-box-demo .config-provider .button-demo .ant-btn,
.code-box-demo .config-provider .button-demo .ant-btn-group {
  margin-right: 8px;
  margin-bottom: 12px;
}
.code-box-demo .config-provider .button-demo .ant-btn-group > .ant-btn,
.code-box-demo .config-provider .button-demo .ant-btn-group > span > .ant-btn {
  margin-right: 0;
  margin-left: 0;
}
.code-box-demo .config-provider .head-example {
  display: inline-block;
  width: 42px;
  height: 42px;
  vertical-align: middle;
  background: #eee;
  border-radius: 4px;
}
.code-box-demo .config-provider .ant-badge:not(.ant-badge-not-a-wrapper) {
  margin-right: 20px;
}
.code-box-demo .config-provider .ant-badge-rtl:not(.ant-badge-not-a-wrapper) {
  margin-right: 0;
  margin-left: 20px;
}
.code-box-meta.markdown {
  position: relative;
  width: 100%;
  font-size: 14px;
  border-radius: 0 0 0.625rem 0.625rem;
}
.code-box-title {
  position: absolute;
  top: -14px;
  margin-left: 16px;
  padding: 1px 8px;
  color: #455560;
  background: #fff;
  border-radius: 0.625rem 0.625rem 0 0;
}
.code-box-description {
  padding: 16px;
}
.code-box-highlight {
  margin-top: 16px;
}
.code-box-actions {
  margin-top: 12px;
  padding-top: 12px;
  text-align: right;
  border-top: 1px dashed #edf2f9;
  font-size: 18px;
}
.code-box-icon {
  cursor: pointer;
}
.grid-demo .code-box-demo .ant-row-flex > div:not(.gutter-row):nth-child(2n + 1),
.grid-demo .code-box-demo .ant-row > div:not(.gutter-row):nth-child(2n + 1) {
  background: rgba(0, 160, 233, 0.7);
}
.grid-demo .code-box-demo .ant-row-flex > div:not(.gutter-row),
.grid-demo .code-box-demo .ant-row > div:not(.gutter-row) {
  padding: 16px 0;
  background: #00a0e9;
}
.grid-demo .gutter-box {
  background: #00a0e9;
  padding: 5px 0;
}
.grid-demo .ant-row-flex > div,
.grid-demo .code-box-demo .ant-row-flex p,
.grid-demo .code-box-demo .ant-row-flex > div,
.grid-demo .code-box-demo .ant-row > div {
  min-height: 30px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 5px 0;
  color: #fff;
  text-align: center;
  border-radius: 0;
}
.grid-demo .ant-row-flex,
.grid-demo .code-box-demo .ant-row-flex {
  background: #f5f5f5;
}
.grid-demo .code-box-demo .height-100 {
  height: 100px;
  line-height: 100px;
}
.grid-demo .code-box-demo .height-120 {
  height: 120px;
  line-height: 120px;
}
.grid-demo .grid-demo-playground [class~="ant-col"] {
  background: 0 0;
  border: 0;
}
.grid-demo .grid-demo-playground [class~="ant-col"] > div {
  height: 100px;
  line-height: 100px;
  font-size: 13px;
}
.grid-demo .grid-demo-playground pre {
  background: #f9f9f9;
  border-radius: 6px;
  font-size: 13px;
  margin-bottom: 0;
  padding: 8px 16px;
}
.grid-demo .grid-demo-playground .code-box-demo .ant-row-flex > div:not(.gutter-row),
.grid-demo .grid-demo-playground .code-box-demo .ant-row-flex > div:not(.gutter-row):nth-child(2n + 1),
.grid-demo .grid-demo-playground .code-box-demo .ant-row > div:not(.gutter-row),
.grid-demo .grid-demo-playground .code-box-demo .ant-row > div:not(.gutter-row):nth-child(2n + 1) {
  background-color: transparent;
}
.grid-demo .grid-demo-playground .code-box-demo .ant-row-flex > div:not(.gutter-row):nth-child(2n + 1) > div,
.grid-demo .grid-demo-playground .code-box-demo .ant-row-flex > div:not(.gutter-row) > div,
.grid-demo .grid-demo-playground .code-box-demo .ant-row > div:not(.gutter-row):nth-child(2n + 1) > div,
.grid-demo .grid-demo-playground .code-box-demo .ant-row > div:not(.gutter-row) > div {
  background: #00a0e9;
}
.code-box-demo-btn .ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.code-box-demo-btn .ant-btn-group > .ant-btn,
.code-box-demo-btn .ant-btn-group > span > .ant-btn {
  margin-right: 0;
}
.code-box-demo-btn .ant-btn-group {
  margin-right: 8px;
}
.code-box-layout-demo .demo-basic .code-box-demo {
  text-align: center;
}
.code-box-layout-demo .demo-basic .ant-layout-footer,
.code-box-layout-demo .demo-basic .ant-layout-header {
  background: #7dbcea;
  color: #fff;
}
.code-box-layout-demo .demo-basic .ant-layout-footer {
  line-height: 1.5;
}
.code-box-layout-demo .demo-basic .ant-layout-sider {
  background: #3ba0e9;
  color: #fff;
  line-height: 120px;
}
.code-box-layout-demo .demo-basic .ant-layout-content {
  background: #108ee9;
  color: #fff;
  min-height: 120px;
  line-height: 120px;
}
.code-box-layout-demo .demo-basic .code-box-demo > div > .ant-layout {
  margin-bottom: 48px;
}
.code-box-layout-demo .demo-basic .code-box-demo > div > .ant-layout:last-child {
  margin: 0;
}
.code-box-layout-demo .demo-custom-trigger .trigger,
.code-box-layout-demo .demo-responsive .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.code-box-layout-demo .demo-custom-trigger .trigger :hover,
.code-box-layout-demo .demo-responsive .trigger :hover {
  color: #3e79f7;
}
.code-box-layout-demo .demo-custom-trigger .logo,
.code-box-layout-demo .demo-responsive .logo,
.code-box-layout-demo .logo {
  height: 32px;
  float: none;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  width: auto;
}
.code-box-layout-demo .logo {
  width: 120px;
  height: 31px;
  margin: 16px 28px 16px 0;
  float: left;
}
.site-layout .site-layout-background,
.site-layout-sub-header-background {
  background: #fff;
}
.code-box-affix-demo .scrollable-container {
  height: 100px;
  overflow-y: scroll;
}
.code-box-affix-demo .background {
  padding-top: 60px;
  height: 300px;
  background-image: url(https://zos.alipayobjects.com/rmsportal/RmjwQiJorKyobvI.jpg);
}
.code-box-breadcrumd-demo .demo {
  margin: 16px;
}
.code-box-breadcrumd-demo .demo-nav {
  height: 30px;
  line-height: 30px;
  margin-bottom: 16px;
  background: #f8f8f8;
}
.code-box-breadcrumd-demo .demo-nav a {
  line-height: 30px;
  padding: 0 8px;
}
.code-box-breadcrumd-demo .app-list {
  margin-top: 16px;
}
.code-box-pagination-demo .ant-pagination:not(:last-child) {
  margin-bottom: 24px;
}
.code-box-pageheader-demo tr:last-child td {
  padding-bottom: 0;
}
.code-box-pageheader-demo .ant-statistic-content {
  font-size: 20px;
  line-height: 28px;
}
.code-box-form-demo .advanced-search .ant-advanced-search-form .ant-form-item,
.code-box-pageheader-demo .content {
  display: flex;
}
@media (max-width: 576px) {
  .code-box-pageheader-demo .content {
    display: block;
  }
  .code-box-pageheader-demo .main {
    width: 100%;
    margin-bottom: 12px;
  }
  .code-box-pageheader-demo .extra {
    width: 100%;
    margin-left: 0;
    text-align: left;
  }
}
.code-box-datepicker-demo .ant-picker {
  margin: 0 8px 12px 0;
}
.code-box-form-demo .basic .ant-form:not(.ant-form-inline):not(.ant-form-vertical),
.code-box-form-demo .complex-form-control .ant-form:not(.ant-form-inline):not(.ant-form-vertical),
.code-box-form-demo .control-between-form .ant-form:not(.ant-form-inline):not(.ant-form-vertical),
.code-box-form-demo .dynamic-rule .ant-form:not(.ant-form-inline):not(.ant-form-vertical),
.code-box-form-demo .form-method .ant-form:not(.ant-form-inline):not(.ant-form-vertical),
.code-box-form-demo .handle-form-data-manually .ant-form:not(.ant-form-inline):not(.ant-form-vertical),
.code-box-form-demo .layout .ant-form:not(.ant-form-inline):not(.ant-form-vertical),
.code-box-form-demo .nest .ant-form:not(.ant-form-inline):not(.ant-form-vertical),
.code-box-form-demo .other-form .ant-form:not(.ant-form-inline):not(.ant-form-vertical),
.code-box-form-demo .registration .ant-form:not(.ant-form-inline):not(.ant-form-vertical),
.code-box-form-demo .time-related-controls .ant-form:not(.ant-form-inline):not(.ant-form-vertical),
.code-box-form-demo .validate-static .ant-form:not(.ant-form-inline):not(.ant-form-vertical) {
  max-width: 600px;
}
.code-box-form-demo .normal-login .login-form {
  max-width: 300px;
}
.code-box-form-demo .normal-login .login-form-forgot {
  float: right;
}
.code-box-form-demo .normal-login .login-form-button {
  width: 100%;
}
.code-box-form-demo .advanced-search .ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #edf2f9;
  border-radius: 6px;
}
.code-box-form-demo .advanced-search .ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
.code-box-form-demo .advanced-search .ant-form {
  max-width: none;
}
.code-box-form-demo .advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.code-box-form-demo .dynamic-form-item .ant-form {
  max-width: 600px;
}
.code-box-form-demo .dynamic-form-item .dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.code-box-form-demo .dynamic-form-item .dynamic-delete-button:hover {
  color: #777;
}
.code-box-form-demo .dynamic-form-item .dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.code-box-avatar-demo .head-example {
  width: 42px;
  height: 42px;
  border-radius: 4px;
  background: #eee;
  display: inline-block;
  vertical-align: middle;
}
.code-box-avatar-demo .ant-badge:not(.ant-badge-not-a-wrapper) {
  margin-right: 20px;
}
.code-box-avatar-demo .ant-badge-not-a-wrapper:not(.ant-badge-status) {
  margin-right: 8px;
}
.code-box-carousel-demo .ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  background: #364d79;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.code-box-calendar-demo .calendar-notice .events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.code-box-calendar-demo .calendar-notice .events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.code-box-calendar-demo .calendar-notice .notes-month {
  text-align: center;
  font-size: 28px;
}
.code-box-calendar-demo .calendar-notice .notes-month section {
  font-size: 28px;
}
.code-box-list-demo .demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 300px;
}
.code-box-list-demo .demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
.code-box-list-demo .demo-loading {
  position: absolute;
  bottom: -40px;
  left: 50%;
}
.code-box-list-demo .demo-loadmore-list {
  min-height: 350px;
}
.code-box-popconfirm-demo .ant-btn,
.code-box-popover-demo .ant-btn,
.code-box-tooltip-demo .ant-btn {
  margin-right: 8px;
  margin-bottom: 8px;
}
.code-box-tab-cardtop-demo .card-container > .ant-tabs-card > .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}
.code-box-tab-cardtop-demo .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}
.code-box-tab-cardtop-demo .card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}
.code-box-tab-cardtop-demo .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: 0 0;
}
.code-box-tab-cardtop-demo .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
.code-box-table-demo tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}
.code-box-table-demo tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}
.code-box-table-demo .react-resizable {
  position: relative;
  background-clip: padding-box;
}
.code-box-table-demo .react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}
.code-box-alert-demo .ant-alert {
  margin-bottom: 15px;
}
.code-box-demo-input .example-input > span {
  width: 200px;
  margin: 0 8px 8px 0;
}
.antd-demo-dynamic-option img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.certain-category-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}
.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: 700;
}
.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}
.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}
.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}
.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}
.certain-search-item-count {
  position: absolute;
  color: #999;
  right: 16px;
}
.certain-category-search.ant-select-focused .certain-category-icon {
  color: #108ee9;
}
.certain-category-icon {
  color: #6e6e6e;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 16px;
}
.color-picker {
  position: relative;
}
.color-picker .color-picker-dropdown {
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
  padding: 5px;
  display: inline-block;
}
.color-picker .color-picker-dropdown .color {
  height: 15px;
  width: 60px;
  border: 1px solid #edf2f9;
  cursor: pointer;
}
.color-picker .color-picker-backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: transparent;
  left: 0;
  top: 0;
  z-index: 1081;
}
.color-picker .sketch-picker {
  position: absolute;
  z-index: 1082;
  left: 0;
  top: 30px;
}
.ellipsis-dropdown {
  cursor: pointer;
  font-size: 21px;
  transform: rotate(90deg);
  display: inline-block;
  line-height: 1;
  padding: 0.3125rem;
  border-radius: 50rem;
  transition: background-color 0.3s ease;
}
.ellipsis-dropdown:focus,
.ellipsis-dropdown:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
ul.anticons-list {
  margin: 10px 0;
  overflow: hidden;
  list-style: none;
  padding-left: 0;
}
ul.anticons-list li {
  position: relative;
  float: left;
  width: 16.66%;
  height: 100px;
  margin: 3px 0;
  padding: 10px 0 0;
  overflow: hidden;
  text-align: center;
  list-style: none;
  background-color: inherit;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
@media only screen and (max-width: 768px) {
  ul.anticons-list li {
    width: 33%;
  }
}
ul.anticons-list li .anticon {
  margin: 12px 0 8px;
  font-size: 36px;
  transition: transform 0.3s ease-in-out;
  will-change: transform;
}
ul.anticons-list li .anticon-class {
  display: block;
  white-space: nowrap;
  text-align: center;
  transform: scale(0.83);
}
ul.anticons-list li .anticon-class .ant-badge {
  transition: color 0.3s ease-in-out;
}
ul.anticons-list li:hover {
  color: #fff;
  background-color: #3e79f7;
}
ul.anticons-list li:hover .anticon {
  transform: scale(1.4);
}
ul.anticons-list li:hover .ant-badge {
  color: #fff;
}
ul.anticons-list li.TwoTone:hover {
  background-color: #8ecafe;
}
ul.anticons-list li.copied:hover {
  color: rgba(255, 255, 255, 0.2);
}
ul.anticons-list li::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  line-height: 110px;
  text-align: center;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  content: "Copied!";
}
ul.anticons-list li.copied::after {
  top: -10px;
  opacity: 1;
}
.copied-code {
  padding: 2px 4px;
  font-size: 12px;
  background: #f5f5f5;
  border-radius: 2px;
}
.apexcharts-tooltip {
  box-shadow: #aeaeae 0 0 10px !important;
}
.apexcharts-tooltip.apexcharts-theme-light {
  border: 0 !important;
  background: #fff !important;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: 0 0 !important;
  border-bottom: 0 !important;
}
.apexcharts-tooltip-title {
  margin-bottom: 0 !important;
}
.apexcharts-gridline {
  stroke: #edf2f9;
}
.apexcharts-text tspan {
  fill: #455560;
}
.apexcharts-menu-item {
  text-align: left;
}
.apexcharts-xaxistooltip {
  opacity: 0;
  padding: 9px 10px;
  pointer-events: none;
  color: #373d3f;
  font-size: 13px;
  text-align: center;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  background: #eceff1;
  border: 1px solid #90a4ae;
  transition: 0.15s ease all;
}
.apexcharts-legend-text {
  color: #455560 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji" !important;
}
.ql-container {
  box-sizing: border-box;
  font-size: 13px;
  height: 100%;
  margin: 0;
  position: relative;
}
.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
}
.ql-container.ql-disabled .ql-editor ul[data-checked] > li::before,
.ql-editor ul[data-checked="false"],
.ql-editor ul[data-checked="true"] {
  pointer-events: none;
}
.ql-clipboard {
  left: -100000px;
  height: 1px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
}
.ql-clipboard p {
  margin: 0;
  padding: 0;
}
.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: 0;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.ql-editor > * {
  cursor: text;
}
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6,
.ql-editor ol,
.ql-editor p,
.ql-editor pre,
.ql-editor ul {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol,
.ql-editor ul {
  padding-left: 1.5em;
}
.ql-editor ol > li,
.ql-editor ul > li {
  list-style-type: none;
}
.ql-editor ul > li::before {
  content: "\2022";
}
.ql-editor ul[data-checked="false"] > li *,
.ql-editor ul[data-checked="true"] > li * {
  pointer-events: all;
}
.ql-editor ul[data-checked="false"] > li::before,
.ql-editor ul[data-checked="true"] > li::before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}
.ql-editor ul[data-checked="true"] > li::before {
  content: "\2611";
}
.ql-editor ul[data-checked="false"] > li::before {
  content: "\2610";
}
.ql-editor li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}
.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
.ql-editor li.ql-direction-rtl::before {
  margin-left: 0.3em;
  margin-right: -1.5em;
}
.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}
.ql-editor ol li.ql-direction-rtl,
.ql-editor ul li.ql-direction-rtl {
  padding-right: 1.5em;
}
.ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}
.ql-editor ol li:before {
  content: counter(list-0, decimal) ". ";
}
.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}
.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}
.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}
.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}
.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
  counter-reset: list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}
.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
  counter-reset: list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}
.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
  counter-reset: list-8 list-9;
}
.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}
.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
  counter-reset: list-9;
}
.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}
.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}
.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) ". ";
}
.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}
.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}
.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}
.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}
.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}
.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}
.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}
.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}
.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}
.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}
.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}
.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}
.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}
.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}
.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}
.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}
.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}
.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}
.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}
.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}
.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}
.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}
.ql-editor .ql-video {
  display: block;
  max-width: 100%;
}
.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}
.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}
.ql-editor .ql-bg-black {
  background-color: #000;
}
.ql-editor .ql-bg-red {
  background-color: #e60000;
}
.ql-editor .ql-bg-orange {
  background-color: #f90;
}
.ql-editor .ql-bg-yellow {
  background-color: #ff0;
}
.ql-editor .ql-bg-green {
  background-color: #008a00;
}
.ql-editor .ql-bg-blue {
  background-color: #3e79f7;
}
.ql-editor .ql-bg-purple {
  background-color: #93f;
}
.ql-editor .ql-color-white {
  color: #fff;
}
.ql-editor .ql-color-red {
  color: #e60000;
}
.ql-editor .ql-color-orange {
  color: #f90;
}
.ql-editor .ql-color-yellow {
  color: #ff0;
}
.ql-editor .ql-color-green {
  color: #008a00;
}
.ql-editor .ql-color-blue {
  color: #3e79f7;
}
.ql-editor .ql-color-purple {
  color: #93f;
}
.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}
.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}
.ql-editor .ql-size-small {
  font-size: 0.75em;
}
.ql-editor .ql-size-large,
.ql-snow .ql-editor h2 {
  font-size: 1.5em;
}
.ql-editor .ql-size-huge {
  font-size: 2.5em;
}
.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}
.ql-editor .ql-align-center {
  text-align: center;
}
.ql-editor .ql-align-justify {
  text-align: justify;
}
.ql-editor .ql-align-right {
  text-align: right;
}
.ql-editor.ql-blank::before {
  color: rgba(114, 132, 154, 0.4);
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}
.ql-snow .ql-formats:after,
.ql-snow .ql-toolbar:after,
.ql-snow.ql-toolbar:after {
  clear: both;
  content: "";
  display: table;
}
.ql-snow .ql-toolbar button,
.ql-snow.ql-toolbar button {
  background: 0 0;
  border: 0;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 26px;
  padding: 5px 7px;
  width: 30px;
  border-radius: 4px;
}
.ql-snow .ql-toolbar button svg,
.ql-snow.ql-toolbar button svg {
  float: left;
  height: 100%;
}
.ql-snow .ql-toolbar button:active:hover,
.ql-snow.ql-toolbar button:active:hover {
  outline: 0;
}
.ql-snow .ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar button:focus,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button:hover {
  color: #3e79f7;
  background-color: rgba(62, 121, 247, 0.15);
}
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow a,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar button.ql-active {
  color: #3e79f7;
}
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill {
  fill: #3e79f7;
}
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter {
  stroke: #3e79f7;
}
@media (pointer: coarse) {
  .ql-snow .ql-toolbar button:hover:not(.ql-active),
  .ql-snow.ql-toolbar button:hover:not(.ql-active) {
    color: #455560;
  }
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill,
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
    fill: #455560;
  }
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter,
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke,
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
    stroke: #455560;
  }
}
.ql-snow,
.ql-snow * {
  box-sizing: border-box;
}
.ql-snow .ql-hidden,
.ql-snow .ql-tooltip.ql-editing a.ql-preview,
.ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}
.ql-snow .ql-out-bottom,
.ql-snow .ql-out-top {
  visibility: hidden;
}
.ql-snow .ql-tooltip {
  position: absolute;
  transform: translateY(10px);
}
.ql-snow .ql-tooltip a {
  cursor: pointer;
  text-decoration: none;
}
.ql-snow .ql-tooltip.ql-flip {
  transform: translateY(-10px);
}
.ql-snow .ql-formats {
  display: inline-block;
  vertical-align: middle;
}
.ql-snow .ql-stroke {
  fill: none;
  stroke: #455560;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}
.ql-snow .ql-stroke-miter {
  fill: none;
  stroke: #455560;
  stroke-miterlimit: 10;
  stroke-width: 2;
}
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #455560;
}
.ql-snow .ql-empty {
  fill: none;
}
.ql-snow .ql-even {
  fill-rule: evenodd;
}
.ql-snow .ql-stroke.ql-thin,
.ql-snow .ql-thin {
  stroke-width: 1;
}
.ql-snow .ql-transparent {
  opacity: 0.4;
}
.ql-snow .ql-direction svg:last-child {
  display: none;
}
.ql-snow .ql-direction.ql-active svg:last-child {
  display: inline;
}
.ql-snow .ql-direction.ql-active svg:first-child {
  display: none;
}
.ql-snow .ql-editor h1 {
  font-size: 2em;
}
.ql-snow .ql-editor h3 {
  font-size: 1.17em;
}
.ql-snow .ql-editor h4 {
  font-size: 1em;
}
.ql-snow .ql-editor h5 {
  font-size: 0.83em;
}
.ql-snow .ql-editor h6 {
  font-size: 0.67em;
}
.ql-snow .ql-editor a {
  text-decoration: underline;
}
.ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}
.ql-snow .ql-editor code,
.ql-snow .ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px;
}
.ql-snow .ql-editor pre {
  white-space: pre-wrap;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 10px;
}
.ql-snow .ql-editor code {
  font-size: 85%;
  padding: 2px 4px;
}
.ql-snow .ql-editor pre.ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
}
.ql-snow .ql-picker {
  color: #455560;
  display: inline-block;
  float: left;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  position: relative;
  vertical-align: middle;
}
.ql-snow .ql-picker-label {
  cursor: pointer;
  display: inline-block;
  height: 100%;
  padding-left: 8px;
  padding-right: 2px;
  position: relative;
  width: 100%;
}
.ql-snow .ql-picker-label::before {
  display: inline-block;
  line-height: 22px;
}
.ql-snow .ql-picker-options {
  background-color: #fff;
  display: none;
  min-width: 100%;
  padding: 4px 8px;
  position: absolute;
  white-space: nowrap;
}
.ql-snow .ql-picker-options .ql-picker-item {
  cursor: pointer;
  display: block;
  padding: 5px 10px;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #ccc;
  z-index: 2;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #ccc;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #ccc;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  display: block;
  margin-top: -1px;
  top: 100%;
  z-index: 1;
}
.ql-snow .ql-color-picker,
.ql-snow .ql-icon-picker {
  width: 28px;
}
.ql-snow .ql-color-picker .ql-picker-label,
.ql-snow .ql-icon-picker .ql-picker-label {
  padding: 2px 4px;
}
.ql-snow .ql-color-picker .ql-picker-label svg,
.ql-snow .ql-icon-picker .ql-picker-label svg {
  right: 4px;
}
.ql-snow .ql-icon-picker .ql-picker-options {
  padding: 4px 0;
}
.ql-snow .ql-icon-picker .ql-picker-item {
  height: 24px;
  width: 24px;
  padding: 2px 4px;
}
.ql-snow .ql-color-picker .ql-picker-options {
  padding: 3px 5px;
  width: 152px;
}
.ql-snow .ql-color-picker .ql-picker-item {
  border: 1px solid transparent;
  float: left;
  height: 16px;
  margin: 2px;
  padding: 0;
  width: 16px;
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  position: absolute;
  margin-top: -9px;
  right: 0;
  top: 50%;
  width: 18px;
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=""])::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=""])::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=""])::before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=""])::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=""])::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=""])::before {
  content: attr(data-label);
}
.ql-snow .ql-picker.ql-header {
  width: 98px;
}
.ql-snow .ql-picker.ql-header .ql-picker-item::before,
.ql-snow .ql-picker.ql-header .ql-picker-label::before {
  content: "Normal";
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before {
  content: "Heading 1";
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before {
  content: "Heading 2";
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before {
  content: "Heading 3";
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before {
  content: "Heading 4";
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before {
  content: "Heading 5";
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before {
  content: "Heading 6";
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  font-size: 2em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  font-size: 1.5em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  font-size: 1.17em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  font-size: 1em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  font-size: 0.83em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  font-size: 0.67em;
}
.ql-snow .ql-picker.ql-font {
  width: 108px;
}
.ql-snow .ql-picker.ql-font .ql-picker-item::before,
.ql-snow .ql-picker.ql-font .ql-picker-label::before {
  content: "Sans Serif";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before {
  content: "Serif";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before {
  content: "Monospace";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  font-family: Georgia, Times New Roman, serif;
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  font-family: Monaco, Courier New, monospace;
}
.ql-snow .ql-picker.ql-size {
  width: 98px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item::before,
.ql-snow .ql-picker.ql-size .ql-picker-label::before {
  content: "Normal";
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before {
  content: "Small";
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before {
  content: "Large";
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before {
  content: "Huge";
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  font-size: 10px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  font-size: 18px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  font-size: 32px;
}
.ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background-color: #fff;
}
.ql-snow .ql-color-picker.ql-color .ql-picker-item {
  background-color: #000;
}
.ql-toolbar.ql-snow {
  border: 1px solid #edf2f9;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
}
.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid transparent;
}
.ql-toolbar.ql-snow .ql-picker-options {
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #edf2f9;
}
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected,
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
  border-color: #000;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0;
}
.ql-snow .ql-tooltip {
  background-color: #fff;
  box-shadow: 0 0 5px #ddd;
  color: #455560;
  padding: 5px 12px;
  white-space: nowrap;
}
.ql-snow .ql-tooltip::before {
  content: "Visit URL:";
  line-height: 26px;
  margin-right: 8px;
}
.ql-snow .ql-tooltip input[type="text"] {
  display: none;
  border: 1px solid #edf2f9;
  font-size: 13px;
  height: 26px;
  margin: 0;
  padding: 3px 5px;
  width: 170px;
}
.ql-snow .ql-tooltip a.ql-preview {
  display: inline-block;
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}
.ql-snow .ql-tooltip a.ql-action::after {
  border-right: 1px solid #edf2f9;
  content: "Edit";
  margin-left: 16px;
  padding-right: 8px;
}
.ql-snow .ql-tooltip a.ql-remove::before {
  content: "Remove";
  margin-left: 8px;
}
.ql-snow .ql-tooltip a {
  line-height: 26px;
}
.ql-snow .ql-tooltip.ql-editing input[type="text"] {
  display: inline-block;
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0;
  content: "Save";
  padding-right: 0;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "Enter link:";
}
.ql-snow .ql-tooltip[data-mode="formula"]::before {
  content: "Enter formula:";
}
.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "Enter video:";
}
.ql-container.ql-snow,
.ql-snow .ql-tooltip {
  border: 1px solid #edf2f9;
}
.app-content {
  padding: 25px;
  margin-top: 70px;
  min-height: calc(100vh - 130px);
  position: relative;
}
.app-content .app-breadcrumb {
  margin-bottom: 15px;
}
@media print {
  .app-content {
    padding: 0;
    margin-top: 0;
  }
}
.app-content .content-full {
  min-height: 75vh;
}
.app-content .content-full-no-page-header {
  min-height: 80vh;
}
.app-content.layout-top-nav {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
@media only screen and (min-width: 992px) {
  .app-content.layout-top-nav {
    margin-top: 125px;
    min-height: calc(100vh - 130px - 55px);
  }
}
@media only screen and (max-width: 768px) {
  .app-content {
    padding: 15px;
  }
}
.inner-app-layout {
  background-color: #fff;
  display: flex;
  border-radius: 0.625rem;
}
.inner-app-layout > .side-content {
  display: flex;
}
.inner-app-layout > .side-content.with-border {
  border-right: 1px solid #edf2f9;
}
.inner-app-layout > .side-content.with-border .ant-menu-inline {
  border-right-color: transparent;
}
.inner-app-layout > .main-content {
  min-height: calc(100vh - 130px - 25px * 2 - 2px);
  width: 100%;
}
.inner-app-layout > .main-content.has-page-header {
  min-height: 75vh;
}
.inner-app-layout > .main-content.gutter {
  padding: 24px;
}
.inner-app-layout > .main-content.no-gutter {
  padding: 0;
}
@media print {
  .app-layout {
    padding-left: 0 !important;
  }
}
.auth-container {
  height: 100vh;
}
.nav-close .anticon {
  padding: 0.625rem;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.nav-close .anticon:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.footer {
  height: 60px;
  display: flex;
  margin: 0 25px;
  align-items: center;
  border-top: 1px solid #edf2f9;
  justify-content: center;
}
@media only screen and (max-width: 768px) {
  .footer {
    justify-content: center;
    flex-direction: column;
  }
}
.app-header {
  position: fixed;
  left: 0;
  z-index: 1000;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.15);
}
.app-header,
.app-header .app-header-wrapper {
  display: flex;
  width: 100%;
}
.app-header .app-header-wrapper.layout-top-nav {
  max-width: 1400px;
  margin: auto;
}
.app-header .nav-icon {
  font-size: 1.25rem;
}
.app-header.ant-layout-header {
  height: 70px;
  line-height: 70px;
  padding: 0;
  background-color: #fff;
}
.app-header .nav {
  display: flex;
  justify-content: space-between;
  position: relative;
  transition: all 0.2s ease;
}
.app-header .nav-right {
  margin-left: auto;
  padding: 0 1rem;
  display: flex;
}
.app-header .nav-left {
  display: flex;
}
.app-header .nav-left .ant-menu .ant-menu-item,
.app-header .nav-right .ant-menu .ant-menu-item {
  padding: 0 1rem;
  margin: 0;
  line-height: 70px;
}
.app-header ul.ant-menu {
  line-height: 70px;
  border-bottom: 0;
  background: 0 0;
}
.app-header.light .ant-badge,
.app-header.light .ant-menu {
  color: rgba(255, 255, 255, 0.85);
}
.app-header.light .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.app-header.light .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.app-header.light .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.app-header.light .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.app-header.light .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.app-header.light .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.app-header.light .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
  border-bottom: 0;
  color: #fff;
}
.app-header.light .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active .ant-badge,
.app-header.light .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open .ant-badge,
.app-header.light .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover .ant-badge,
.app-header.light .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active .ant-badge,
.app-header.light .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open .ant-badge,
.app-header.light .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected .ant-badge,
.app-header.light .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover .ant-badge,
.app-header.light .ant-menu:not(.ant-menu-dark) a:hover {
  color: #fff;
}
.app-header.light .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  color: rgba(255, 255, 255, 0.85);
  border-bottom: 0;
}
.app-header.light .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected .ant-badge,
.app-header.light .ant-menu:not(.ant-menu-dark) a {
  color: rgba(255, 255, 255, 0.85);
}
.app-header.light .ant-badge .ant-badge-count {
  box-shadow: none;
}
@media print {
  .app-header {
    display: none;
  }
}
.nav-dropdown {
  background-color: #fff;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 0.625rem;
}
.logo {
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background-color: transparent;
  transition: all 0.2s ease;
}
.nav-notification {
  width: 19.5rem;
}
.nav-notification .nav-notification-header {
  padding: 0.875rem;
  border-bottom: 1px solid #edf2f9;
}
.nav-notification .nav-notification-body {
  max-height: 21.875rem;
  overflow-y: auto;
}
.nav-notification .nav-notification-footer {
  padding: 0.65rem;
  text-align: center;
  border-top: 1px solid #edf2f9;
}
.nav-notification .empty-notification {
  padding: 4rem 0;
  text-align: center;
}
.nav-profile {
  width: 14.5rem;
}
.nav-profile .nav-profile-header {
  padding: 0.7rem 1rem;
  border-bottom: 1px solid #edf2f9;
}
.nav-profile .nav-profile-body {
  padding: 0.625rem 0;
}
.nav-profile .nav-profile-body .ant-menu-vertical > .ant-menu-item {
  height: 2.1875rem;
  line-height: 2.1875rem;
}
.nav-search {
  height: 70px;
  padding: 0 1.5rem;
  position: absolute;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  top: -70px;
  transition: all 0.3s ease;
}
.nav-search.nav-search-active {
  top: 0;
}
.nav-search.light .ant-select-selection-search-input {
  color: #fff;
}
.nav-search.light,
.nav-search.light .ant-select-selection-placeholder {
  color: rgba(255, 255, 255, 0.85);
}
.nav-search .nav-search-input.is-mobile {
  max-width: 400px;
  min-width: auto;
}
.nav-search .nav-search-input.is-mobile .ant-input,
.nav-search .nav-search-input.is-mobile .ant-select-selector {
  border: 0;
  box-shadow: none !important;
  font-size: 1.125rem;
  background-color: transparent;
}
.nav-search-dropdown {
  border-radius: 0.625rem;
}
.nav-search-dropdown .ant-select-item {
  min-height: 3rem;
  padding: 0;
}
.nav-search-dropdown .ant-select-item a {
  color: #455560;
}
.nav-search-dropdown .search-list-item {
  display: flex;
  align-items: center;
  padding: 0.4375rem 0.75rem;
}
.nav-search-dropdown .search-list-item .icon {
  font-size: 1.5rem;
  margin-right: 1rem;
}
.nav-search-input:not(.is-mobile) {
  min-width: 300px;
  width: 100%;
}
.nav-search-input:not(.is-mobile) .ant-input-affix-wrapper {
  background-color: #fafafb;
}
.nav-search-input:not(.is-mobile) .ant-input-affix-wrapper .ant-input {
  background-color: transparent;
}
.nav-search-input:not(.is-mobile) .ant-input-affix-wrapper .ant-input-prefix {
  color: #72849a;
}
.nav-search-input:not(.is-mobile).light .ant-input-affix-wrapper {
  background-color: rgba(255, 255, 255, 0.25);
  border: 0;
}
.nav-search-input:not(.is-mobile).light .ant-input-prefix {
  color: rgba(255, 255, 255, 0.85);
}
.nav-search-input:not(.is-mobile).light .ant-input {
  color: #fff;
}
.nav-search-input:not(.is-mobile).light .ant-input:placeholder {
  color: rgba(255, 255, 255, 0.75);
}
.nav-search-input:not(.is-mobile).light.ant-select-focused .ant-input-affix-wrapper {
  background-color: #fff;
}
.nav-search-input:not(.is-mobile).light.ant-select-focused .ant-input-prefix {
  color: #72849a;
}
.nav-search-input:not(.is-mobile).light.ant-select-focused .ant-input {
  color: #455560;
}
.nav-search-input:not(.is-mobile).light.ant-select-focused .ant-input:placeholder {
  color: rgba(114, 132, 154, 0.4);
}
.app-page-header {
  align-items: center;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 768px) {
  .app-page-header {
    display: flex;
  }
}
@media print {
  .app-page-header {
    display: none;
  }
}
.page-header-alt {
  background-color: #fff;
  padding: 25px;
  margin-top: -25px;
  margin-left: -25px;
  margin-right: -25px;
}
@media only screen and (max-width: 768px) {
  .page-header-alt {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.page-header-alt.overlap {
  margin-bottom: -4.6875rem;
}
.page-header-alt.full-screen {
  position: relative;
  width: 100vw;
}
.side-nav {
  height: calc(100vh - 70px);
  position: fixed;
  top: 70px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.15);
  z-index: 999;
  background-color: #fff;
}
@media print {
  .side-nav {
    display: none;
  }
}
.side-nav.side-nav-dark {
  background-color: #283142;
}
.side-nav .hide-group-title .ant-menu-item-group-title {
  display: none;
}
.mobile-nav-menu {
  height: calc(100vh - 70px - 10px);
}
.top-nav {
  height: 55px;
  background-color: #001529;
  position: fixed;
  top: 70px;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.top-nav .top-nav-wrapper {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
.top-nav .top-nav-wrapper .ant-menu-horizontal {
  line-height: 55px;
  border-bottom: 0;
}
.top-nav.light .ant-menu {
  color: rgba(255, 255, 255, 0.8);
}
.top-nav.light .ant-menu .ant-menu-item-active,
.top-nav.light .ant-menu .ant-menu-item:hover,
.top-nav.light .ant-menu .ant-menu-submenu-active,
.top-nav.light .ant-menu .ant-menu-submenu-title:hover,
.top-nav.light .ant-menu .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #fff;
}
.top-nav.light .ant-menu.ant-menu-horizontal > .ant-menu-item-active,
.top-nav.light .ant-menu.ant-menu-horizontal > .ant-menu-item-open,
.top-nav.light .ant-menu.ant-menu-horizontal > .ant-menu-item-selected,
.top-nav.light .ant-menu.ant-menu-horizontal > .ant-menu-submenu-active,
.top-nav.light .ant-menu.ant-menu-horizontal > .ant-menu-submenu-open,
.top-nav.light .ant-menu.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #fff;
}
.top-nav.dark .ant-menu {
  color: rgba(69, 85, 96, 0.8);
}
.top-nav.dark .ant-menu .ant-menu-item-active,
.top-nav.dark .ant-menu .ant-menu-item:hover,
.top-nav.dark .ant-menu .ant-menu-submenu-active,
.top-nav.dark .ant-menu .ant-menu-submenu-title:hover,
.top-nav.dark .ant-menu .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #455560;
}
.top-nav.dark .ant-menu.ant-menu-horizontal > .ant-menu-item-active,
.top-nav.dark .ant-menu.ant-menu-horizontal > .ant-menu-item-open,
.top-nav.dark .ant-menu.ant-menu-horizontal > .ant-menu-item-selected,
.top-nav.dark .ant-menu.ant-menu-horizontal > .ant-menu-submenu-active,
.top-nav.dark .ant-menu.ant-menu-horizontal > .ant-menu-submenu-open,
.top-nav.dark .ant-menu.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #455560;
}
.top-nav-menu .ant-menu-inline .ant-menu-item,
.top-nav-menu .ant-menu-inline .ant-menu-submenu-title,
.top-nav-menu .ant-menu-vertical .ant-menu-item,
.top-nav-menu .ant-menu-vertical .ant-menu-submenu-title,
.top-nav-menu .ant-menu-vertical-left .ant-menu-item,
.top-nav-menu .ant-menu-vertical-left .ant-menu-submenu-title,
.top-nav-menu .ant-menu-vertical-right .ant-menu-item,
.top-nav-menu .ant-menu-vertical-right .ant-menu-submenu-title {
  line-height: 2rem;
  height: 2rem;
}
.chat {
  border: 1px solid #edf2f9;
  border-radius: 0.625rem;
}
.chat .chat-content {
  height: 100%;
}
.chat .chat-content .chat-content-header {
  height: 60px;
  border-bottom: 1px solid #edf2f9;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat .chat-content .chat-content-body {
  height: calc(100vh - 60px - 76px - 25px - 70px - 60px - 30px);
  padding: 15px;
  overflow-y: auto;
}
.chat .chat-content .chat-content-body .msg {
  display: flex;
  margin-bottom: 15px;
}
.chat .chat-content .chat-content-body .msg .bubble {
  max-width: 75%;
  position: relative;
}
.chat .chat-content .chat-content-body .msg .bubble .bubble-wrapper {
  padding: 10px 15px;
  border-radius: 0.625rem;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 10px;
}
.chat .chat-content .chat-content-body .msg.datetime {
  padding: 15px 0;
  margin-bottom: 0;
  font-size: 11px;
  font-weight: 500;
  justify-content: center;
}
.chat .chat-content .chat-content-body .msg.datetime .ant-divider-inner-text {
  font-size: 12px;
}
.chat .chat-content .chat-content-body .msg .file .anticon {
  margin-right: 10px;
  font-size: 17px;
}
.chat .chat-content .chat-content-body .msg.msg-recipient .bubble .bubble-wrapper {
  background: #ededed;
  color: #1a3353;
}
.chat .chat-content .chat-content-body .msg.msg-recipient .bubble .bubble-wrapper img {
  max-width: 250px;
  cursor: pointer;
}
.chat .chat-content .chat-content-body .msg.msg-sent {
  justify-content: flex-end;
}
.chat .chat-content .chat-content-body .msg.msg-sent .bubble .bubble-wrapper {
  background-color: #3e79f7;
  color: #fff;
}
.chat .chat-content .chat-content-footer {
  height: 76px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.chat .chat-content-empty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat .chat-content-empty img {
  max-width: 640px;
  width: 100%;
}
.chat-menu {
  width: 100%;
}
.chat-menu .chat-menu-toolbar {
  height: 60px;
  border-bottom: 1px solid #edf2f9;
  padding: 0;
  display: flex;
  align-items: center;
}
.chat-menu .chat-menu-toolbar .ant-input-affix-wrapper {
  border: 0;
}
.chat-menu .chat-menu-toolbar .ant-input-affix-wrapper .ant-input-prefix {
  display: flex;
  align-items: center;
  color: rgba(114, 132, 154, 0.4);
}
.chat-menu .chat-menu-toolbar .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
  box-shadow: none;
}
.chat-menu .chat-menu-toolbar .ant-input-affix-wrapper .ant-input {
  font-size: 21px;
}
.chat-menu .chat-menu-toolbar .ant-input-affix-wrapper .ant-input .ant-input-focused,
.chat-menu .chat-menu-toolbar .ant-input-affix-wrapper .ant-input:focus {
  box-shadow: none;
}
.chat-menu .chat-menu-list {
  height: calc(100vh - 60px - 76px - 25px - 70px - 30px);
  overflow: auto;
  position: relative;
}
.chat-menu .chat-menu-list .chat-menu-list-item {
  transition: all 0.3s ease-in-out;
  padding: 13px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.chat-menu .chat-menu-list .chat-menu-list-item .chat-menu-list-item-time {
  font-size: 12px;
  color: rgba(114, 132, 154, 0.7);
  font-weight: 500;
}
.chat-menu .chat-menu-list .chat-menu-list-item:not(.last) {
  border-bottom: 1px solid #edf2f9;
}
.chat-menu .chat-menu-list .chat-menu-list-item.selected,
.chat-menu .chat-menu-list .chat-menu-list-item:hover {
  background-color: #f5f5f5;
}
.chat-menu .chat-menu-list .chat-menu-list-item .avatar-status .avatar-status-subtitle {
  width: 166px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mail .mail-list .ant-table-row {
  cursor: pointer;
}
.mail .mail-list .ant-table-selection {
  padding: 0.3rem 0;
}
.mail .mail-list .mail-list-star {
  padding-right: 15px;
  padding-left: 0;
  cursor: pointer;
}
.mail .mail-list .mail-list-star.checked {
  color: #ffc542;
}
.mail .mail-list .mail-list-star.uncheck {
  color: rgba(114, 132, 154, 0.7);
}
.mail .mail-detail .mail-detail-action-icon.star.checked,
.mail .mail-detail .mail-detail-action-icon.star:hover,
.mail .mail-list .mail-list-star:hover {
  color: #ffc542;
}
.mail .mail-list .mail-list-sender {
  width: 220px;
  padding: 15px 0;
}
.mail .mail-list .mail-list-content {
  max-width: 0;
}
.mail .mail-list .mail-list-content .mail-list-content-msg {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mail .mail-list .mail-list-date {
  width: 100px;
  text-align: right;
}
.mail .mail-list .mail-list-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mail .mail-list .mail-list-action-icon {
  font-size: 21px;
  color: #455560;
  cursor: pointer;
  margin-left: 1.2rem;
}
.mail .mail-detail .mail-detail-action {
  display: flex;
  align-items: center;
}
.mail .mail-detail .mail-detail-action-icon {
  margin-left: 0.75rem;
  font-size: 21px;
  cursor: pointer;
}
.mail .mail-detail .mail-detail-action-icon:hover {
  color: #3e79f7;
}
.mail .mail-detail .mail-detail-content {
  margin-top: 1.25rem;
}
.mail .mail-detail .mail-detail-attactment {
  display: flex;
  margin-top: 30px;
}
.mail .mail-detail .mail-detail-attactment .mail-detail-attactment-item {
  cursor: pointer;
  border-radius: 0.3rem;
  border: 1px solid #edf2f9;
  padding: 5px 10px;
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.mail .mail-detail .mail-detail-attactment .mail-detail-attactment-item .anticon {
  font-size: 2rem;
}
.mail .mail-compose .ql-container {
  min-height: 9.5rem;
  border-top: 1px solid #edf2f9 !important;
  border-bottom: 0;
}
.mail .mail-compose .ql-editor {
  min-height: 9.5rem;
}
.mail .mail-compose .editor-wrapper {
  position: relative;
}
.mail .mail-compose .ql-toolbar {
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateY(100%);
  z-index: 1;
}
.calendar .calendar-event {
  list-style: none;
  margin: 0;
  padding: 0;
}
.calendar .calendar-event li {
  margin-bottom: 5px;
}
@media only screen and (max-width: 992px) {
  .calendar .calendar-event li {
    display: inline-block;
  }
  .calendar .calendar-event li .ant-badge {
    margin-right: 5px;
  }
  .calendar .calendar-event li .ant-badge-status-text {
    display: none;
  }
}
.calendar .calendar-list {
  margin-bottom: 2rem;
}
.calendar .calendar-list .calendar-list-item {
  padding: 5px 10px;
  border-radius: 0.625rem;
  margin-bottom: 15px;
  display: flex;
  margin-left: 20px;
  background-color: #f7f7f8;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #edf2f9;
}
@media only screen and (min-width: 768px) {
  .calendar .calendar-list .calendar-list-item {
    max-width: 75%;
  }
}
.calendar .calendar-list .calendar-list-item .calendar-list-item-delete {
  opacity: 1;
  cursor: pointer;
  font-size: 17px;
  transition: all 0.1s ease-in-out;
}
.calendar .calendar-list .calendar-list-item .calendar-list-item-delete:hover {
  color: #ff6b72;
}
.scrumboard {
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  height: calc(100vh - 130px - 25px - 25px);
}
.scrumboard .scrumboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 16px;
}
.scrumboard .scrumboard-body {
  display: flex;
  flex: 1 1;
  overflow-x: auto;
}
.scrumboard .scrumboard-body > div {
  display: flex;
  flex: 1 1;
}
.board-column,
.board-column.add .board-title {
  border: 1px solid #edf2f9;
  background-color: #f0f2f5;
  border-radius: 0.625rem;
}
.board-column {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  margin: 0 8px 15px;
}
.board-column.add {
  border: 0;
  background-color: transparent;
}
.board-column.add .board-title {
  justify-content: center;
  cursor: pointer;
}
.board-column.add .board-title:hover {
  background-color: #eaedf1;
}
.board-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #edf2f9;
  transition: background-color 0.2s ease;
  padding: 0.625rem 0.9375rem;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}
.board-wrapper {
  overflow-y: auto;
  flex: 1 1 auto;
}
.board-card {
  margin-bottom: 0;
}
.board-card .ant-card-body {
  padding: 0.625rem;
}
.board-add {
  background-color: #fff;
  justify-content: center;
  height: 2.8rem;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-top: 1px solid #edf2f9;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.15s ease;
}
.board-add:hover {
  background-color: #fcfcfc;
}
.board-label {
  display: inline-block;
  width: 2rem;
  height: 0.1875rem;
  margin-right: 0.5rem;
}
.board-label.blue {
  background: #3e79f7;
}
.board-label.purple {
  background: #a461d8;
}
.board-label.cyan {
  background: #04d182;
}
.board-label.green {
  background: #21b573;
}
.board-label.magenta,
.board-label.pink {
  background: #eb2f96;
}
.board-label.red {
  background: #de4436;
}
.board-label.orange {
  background: #fa8c16;
}
.board-label.yellow {
  background: #fadb14;
}
.board-label.volcano {
  background: #ff6b72;
}
.board-label.geekblue {
  background: #17bcff;
}
.board-label.lime {
  background: #a0d911;
}
.board-label.gold {
  background: #ffc542;
}
.board-container {
  min-width: 100vw;
  display: inline-flex;
}
.board-dropzone {
  padding: 0 0.9375rem;
  padding-top: 0.9375rem;
  height: 100%;
}
.board-card-modal.input {
  font-size: 16px;
  font-weight: 500;
  border-color: transparent;
  max-width: 95%;
  padding: 5px 7.5px;
  margin-left: -7.5px;
  box-shadow: none;
  color: #1a3353;
}
.board-card-modal.input:focus {
  border-color: #3e79f7;
}
.board-card-modal.text-area {
  border-color: transparent;
  margin-left: -8.5px;
}
.board-card-modal.date-picker.ant-picker .ant-picker-focused,
.board-card-modal.date-picker.ant-picker.ant-picker-focused,
.board-card-modal.date-picker.ant-picker:hover,
.board-card-modal.text-area:hover {
  border-color: #3e79f7;
}
.board-card-modal.text-area:focus {
  border-color: #3e79f7;
}
.board-card-modal.date-picker,
.board-card-modal.select {
  margin-left: -7.5px;
}
.board-card-modal.select .ant-select-selector {
  border-color: transparent;
  min-height: 2.45rem;
}
.board-card-modal.date-picker.ant-picker {
  border-color: transparent;
  margin-left: -11px;
}
.board-card-modal.date-picker.ant-picker .anticon-calendar {
  display: none;
}
.blockform-col .ant-form-item-label {
  padding-bottom: 0;
}
.blockform-col.col-3 {
  width: 33%;
  min-width: 235px;
  display: inline-block;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #3e79f7 !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0d57f5 !important;
}
.bg-secondary {
  background-color: #a461d8 !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #8c38ce !important;
}
.bg-success {
  background-color: #04d182 !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #039f63 !important;
}
.bg-info {
  background-color: #3e79f7 !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #0d57f5 !important;
}
.bg-warning {
  background-color: #ffc542 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #ffb50f !important;
}
.bg-danger {
  background-color: #ff6b72 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #ff3841 !important;
}
.bg-gray {
  background-color: #455560 !important;
}
a.bg-gray:focus,
a.bg-gray:hover,
button.bg-gray:focus,
button.bg-gray:hover {
  background-color: #303b42 !important;
}
.bg-gray-light {
  background-color: #72849a !important;
}
a.bg-gray-light:focus,
a.bg-gray-light:hover,
button.bg-gray-light:focus,
button.bg-gray-light:hover {
  background-color: #5b6b7e !important;
}
.bg-gray-lighter {
  background-color: #ededed !important;
}
a.bg-gray-lighter:focus,
a.bg-gray-lighter:hover,
button.bg-gray-lighter:focus,
button.bg-gray-lighter:hover {
  background-color: #d4d4d4 !important;
}
.bg-gray-lightest {
  background-color: #f7f7f8 !important;
}
a.bg-gray-lightest:focus,
a.bg-gray-lightest:hover,
button.bg-gray-lightest:focus,
button.bg-gray-lightest:hover {
  background-color: #dcdce0 !important;
}
.bg-dark {
  background-color: #1a3353 !important;
}
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #0e1b2c !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #edf2f9 !important;
}
.border-top {
  border-top: 1px solid #edf2f9 !important;
}
.border-right {
  border-right: 1px solid #edf2f9 !important;
}
.border-bottom {
  border-bottom: 1px solid #edf2f9 !important;
}
.border-left {
  border-left: 1px solid #edf2f9 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded-sm {
  border-radius: 0.25rem !important;
}
.rounded {
  border-radius: 0.625rem !important;
}
.rounded-right,
.rounded-top {
  border-top-right-radius: 0.625rem !important;
}
.rounded-top {
  border-top-left-radius: 0.625rem !important;
}
.rounded-right {
  border-bottom-right-radius: 0.625rem !important;
}
.rounded-bottom,
.rounded-left {
  border-bottom-left-radius: 0.625rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.625rem !important;
}
.rounded-left {
  border-top-left-radius: 0.625rem !important;
}
.rounded-lg {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  clear: both;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-help {
  cursor: help;
}
.cursor-copy {
  cursor: copy;
}
.cursor-grab {
  cursor: -webkit-grab;
  cursor: grab;
}
.cursor-text {
  cursor: text;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1400px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.clearfix::after,
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1400px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 9px;
  padding-left: 9px;
}
@media (min-width: 576px) {
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9 {
    padding-right: 9px;
    padding-left: 9px;
  }
}
@media (min-width: 768px) {
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9 {
    padding-right: 9px;
    padding-left: 9px;
  }
}
@media (min-width: 992px) {
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9 {
    padding-right: 9px;
    padding-left: 9px;
  }
}
@media (min-width: 1400px) {
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9 {
    padding-right: 9px;
    padding-left: 9px;
  }
}
.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 9px;
  padding-left: 9px;
}
@media (min-width: 576px) {
  .container {
    padding-right: 9px;
    padding-left: 9px;
  }
}
@media (min-width: 768px) {
  .container {
    padding-right: 9px;
    padding-left: 9px;
  }
}
@media (min-width: 992px) {
  .container {
    padding-right: 9px;
    padding-left: 9px;
  }
}
@media (min-width: 1400px) {
  .container {
    padding-right: 9px;
    padding-left: 9px;
  }
}
@media (min-width: 576px) {
  .container {
    width: 540px;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .container {
    width: 1380px;
    max-width: 100%;
  }
}
.container-fluid {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 9px;
  padding-left: 9px;
}
@media (min-width: 576px) {
  .container-fluid {
    padding-right: 9px;
    padding-left: 9px;
  }
}
@media (min-width: 768px) {
  .container-fluid {
    padding-right: 9px;
    padding-left: 9px;
  }
}
@media (min-width: 992px) {
  .container-fluid {
    padding-right: 9px;
    padding-left: 9px;
  }
}
@media (min-width: 1400px) {
  .container-fluid {
    padding-right: 9px;
    padding-left: 9px;
  }
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.opacity-1 {
  opacity: 1;
}
.opacity-0 {
  opacity: 0;
}
.opacity-0-1 {
  opacity: 0.1;
}
.opacity-0-2 {
  opacity: 0.2;
}
.opacity-0-3 {
  opacity: 0.3;
}
.opacity-0-4 {
  opacity: 0.4;
}
.opacity-0-5 {
  opacity: 0.5;
}
.opacity-0-6,
.opacity-0-7 {
  opacity: 0.7;
}
.opacity-0-8 {
  opacity: 0.8;
}
.opacity-0-9 {
  opacity: 0.9;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-bottom,
.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-top {
  top: 0;
}
.fixed-bottom {
  bottom: 0;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1400px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-semibold {
  font-weight: 500 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  /* color: #3e79f7 !important; */
}
a.text-primary:focus,
a.text-primary:hover {
  color: #094ddf !important;
}
.text-secondary {
  color: #a461d8 !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
  color: #7f2fbe !important;
}
.text-success {
  color: #04d182 !important;
}
a.text-success:focus,
a.text-success:hover {
  color: #038653 !important;
}
.text-info {
  color: #3e79f7 !important;
}
a.text-info:focus,
a.text-info:hover {
  color: #094ddf !important;
}
.text-warning {
  color: #ffc542 !important;
}
a.text-warning:focus,
a.text-warning:hover {
  color: #f4a900 !important;
}
.text-danger {
  color: #ff6b72 !important;
}
a.text-danger:focus,
a.text-danger:hover {
  color: #ff1f29 !important;
}
.text-gray {
  color: #455560 !important;
}
a.text-gray:focus,
a.text-gray:hover {
  color: #252e33 !important;
}
.text-gray-light {
  color: #72849a !important;
}
a.text-gray-light:focus,
a.text-gray-light:hover {
  color: #505e70 !important;
}
.text-gray-lighter {
  color: #ededed !important;
}
a.text-gray-lighter:focus,
a.text-gray-lighter:hover {
  color: #c7c7c7 !important;
}
.text-gray-lightest {
  color: #f7f7f8 !important;
}
a.text-gray-lightest:focus,
a.text-gray-lightest:hover {
  color: #ceced4 !important;
}
.text-dark {
  color: #1a3353 !important;
}
a.text-dark:focus,
a.text-dark:hover {
  color: #080f19 !important;
}
.text-body {
  color: #455560 !important;
}
.text-muted {
  color: rgba(114, 132, 154, 0.7) !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.font-size-xxl {
  font-size: 30px !important;
}
.font-size-xl {
  font-size: 22px !important;
}
.font-size-lg {
  font-size: 21px !important;
}
.font-size-md {
  font-size: 17px !important;
}
.font-size-base {
  font-size: 14px !important;
}
.font-size-sm {
  font-size: 12px !important;
}
.font-size-xs {
  font-size: 10px !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
