:root {
  --primary-color: #00477e;
  --header-color: #00477e;
}

/* Scroll bar style */

::-webkit-scrollbar {
  width: 3px;
  border-radius: 50px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Common CSS Classes*/

.mt_0,
.mb_0 {
  margin-top: 0px;
}
.mt_1,
.mb_1 {
  margin-top: 4px;
}
.mt_2,
.mb_2 {
  margin-top: 8px;
}
.mt_3,
.mb_3 {
  margin-top: 16px;
}
.mt_4,
.mb_4 {
  margin-top: 24px;
}
.mt_5,
.mb_5 {
  margin-top: 48px;
}

.fs_14 {
  font-size: 14px;
}

.lh_1-3 {
  line-height: 1.3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

/* ANTD CSS Overwrite*/

.ant-btn-primary {
  background-color: rgba(29, 64, 129, 0.05) !important;
  border-color: rgba(29, 64, 129, 0.05) !important;
}

.login_container {
  /* background-image: url("/src/assets/images/img-17.jpg"); */
  background: #1f3e68;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.site-layout-background {
  background-color: #ededee !important;
  min-height: calc(100vh - 130px);
}

.ant-layout-footer {
  height: 60px;
  display: flex;
  margin: 0 0;
  align-items: center;
  background-color: #ededee !important;
  border-top: 1px solid #edf2f9;
  justify-content: center;
}

/* NavProfile CSS */

.navProfile {
  display: flex;
  flex-direction: column;
}
.nav_wrapper {
  background: transparent !important;
  border-bottom: none !important;
}

@media (min-width: 576px) {
  .profile_none {
    display: none;
  }
}
@media (max-width: 576px) {
  .profile_none2 {
    display: none !important;
  }
}

/* Header CSS */

.ant-layout-header {
  height: 70px;
  padding: 0 0px;
  color: #455560;
  line-height: 70px;
  background: var(--header-color);
}
@media (max-width: 991px) {
  .nav_images img {
    display: none;
  }
}

/* Communication Header */

.communication_header {
  background-color: #c2ccd8;
  /* height: 55px; */
  padding: 10px 0px;
}

.dashboard_header {
  background-color: #c2ccd8;
  /* height: 55px; */
  padding: 10px 0px;
}
.line_wrapper:before {
  content: "";
  position: absolute;
  border: 1px solid red;
  left: -12px;
  height: 33px;
}

.date_picker {
  width: 100%;
  background-color: transparent;
}

.date_wrapper .date_wrapper1 {
  justify-content: end;
}

.ant_select {
  min-width: 150px;
  /* max-width: 100%; */
}

.ant-layout-header {
  position: sticky;
  top: 0;
  z-index: 999;
}

.sticky_header {
  position: sticky;
  top: 70px;
  z-index: 1;
}

.ant-layout-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
}

.date_row {
  background-color: #ededee;
}

.sub_header {
  padding: 10px 0;
}

.dashboard_card {
  height: 400px;
}

.modal_body h5 {
  margin-bottom: 20px !important;
}

.ant-picker-input > input-disabled,
.ant-picker-input > input[disabled] {
  color: #51575c !important;
}

/* GlobalNavigation CSS */

.global_navigation {
  position: fixed !important;
  bottom: 30px !important;
  right: 30px !important;
  border-radius: 20px !important;
}

.overlay {
  height: 100%;
  width: 0;
  transform: scaleY(0);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: #00000073;
  overflow-x: hidden;
  transition: 0.5s;
}

.modal_container {
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.overlay .closebtn {
  position: absolute;
  color: #ddd;
  top: 45px;
  right: 45px;
  font-size: 40px;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

@media (max-width: 992px) {
  .date_wrapper .date_wrapper1 {
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .date_wrapper .date_wrapper1 {
    justify-content: start;
    display: block;
  }
  .ant_select {
    min-width: 100%;
  }
}
